import { useApis } from 'contexts/ApisContext';
import { IPlaylist, IVideo } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';

const useVideoEditor = () => {
  const siteId = useSelector(getSiteId);
  const primaryToken = useSelector(getPrimaryToken) || '';
  const { videoEditor } = useApis();

  const upsertVideo = React.useCallback((video: IVideo) => {
    return videoEditor.upsertVideo({
      primaryToken,
      siteId,
      video,
    });
  }, [videoEditor, primaryToken, siteId]);

  const upsertPlaylist = React.useCallback((playlist: IPlaylist) => {
    return videoEditor.upsertPlaylist({
      primaryToken,
      siteId,
      playlist,
    });
  }, [videoEditor, primaryToken, siteId]);

  return {
    upsertVideo,
    upsertPlaylist,
  };
};

export default useVideoEditor;
