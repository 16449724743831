import { SITE_ID } from 'config';
import useRealtimeDocument from './use-realtime-document';
import { useSelector } from 'react-redux';
import { IOverlayGeneric, getLastOverlay, getOverlayBeingPreviewed, getOverlays } from 'services/overlays/selectors';
import { useEffect, useState } from 'react';

const OVERLAY_STATUS_TIMER_MS = 2000;

const userOverlay = () => {
  const [overlay] = useRealtimeDocument<IOverlayGeneric>('Overlay', SITE_ID);
  const previewingOverlay = useSelector(getOverlayBeingPreviewed);
  const lastOverlay = useSelector(getLastOverlay);

  const overlayList = useSelector(getOverlays);
  const chooseTargetOverlay = () => previewingOverlay || lastOverlay;

  const [targetOverlay, setTargetOverlay] = useState(chooseTargetOverlay());

  useEffect(() => {
    setTargetOverlay(chooseTargetOverlay());
  }, [previewingOverlay, overlayList, lastOverlay]);

  const [activeOverlay, setActiveOverlay] = useState<IOverlayGeneric | null>(null);
  const [overlayStatusClass, setOverlayStatusClass] = useState('enter');

  if (overlay && !activeOverlay) {
    setActiveOverlay(overlay);
    setOverlayStatusClass('enter');
  }

  useEffect(() => {
    if (overlay && activeOverlay) {
      setTimeout(() => {
        setOverlayStatusClass('');
      }, OVERLAY_STATUS_TIMER_MS);
    }

    if (!overlay && activeOverlay) {
      setOverlayStatusClass('exit');
      setTimeout(() => {
        setActiveOverlay(null);
        setOverlayStatusClass('enter');
      }, OVERLAY_STATUS_TIMER_MS);
    }
  }, [overlay, activeOverlay]);

  return { activeOverlay, overlayStatusClass, targetOverlay };
};

export default userOverlay;
