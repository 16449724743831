import React from 'react';

const useIsOverflowing = <T extends HTMLElement>(ref: React.RefObject<T>) => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const { current } = ref;
    const value = current.scrollHeight > current.clientHeight;
    if (value !== isOverflowing) {
      setIsOverflowing(value);
    }
  }, [ref]);

  return isOverflowing;
};


export default useIsOverflowing;
