import { useDispatch } from 'react-redux';
import { decreaseCartItem, increaseCartItem, removeCartItem, updateCartItemVariant } from 'services/ecommerce';

const useEcommerceCartProduct = (productId: string, variantId: string) => {
  const dispatch = useDispatch();

  const handleQuantityDecrease = () => {
    dispatch(decreaseCartItem({ productId, variantId }));
  };

  const handleQuantityIncrease = () => {
    dispatch(increaseCartItem({ productId, variantId }));
  };

  const handleProductDeletion = () => {
    dispatch(removeCartItem({ productId, variantId }));
  };

  const handleVariantUpdate = (oldVariantId: string) => {
    dispatch(updateCartItemVariant({
      productId,
      variantId: oldVariantId,
      updatedVariantId: variantId,
    }));
  };

  return { handleQuantityDecrease, handleQuantityIncrease, handleProductDeletion, handleVariantUpdate };
};

export default useEcommerceCartProduct;
