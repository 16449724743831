import { useDispatch } from 'react-redux';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics';

interface IPurchasePlanClickedData {
  buttonTitle: string;
  location: IPurchasePlanCTALocation;
}

export enum IPurchasePlanCTALocation {
  BILLING_TAB = 'billing tab',
  TOOLBAR = 'toolbar',
}

export type Callback = (data: IPurchasePlanClickedData) => void;

export const useTrackPurchasePlanClicked = (): Callback => {
  const dispatch = useDispatch();

  return (data: IPurchasePlanClickedData) => {
    const { location, buttonTitle } = data;
    const properties: IPurchasePlanClickedData = {
      buttonTitle,
      location,
    };

    dispatch(trackEvent({
      event: AdminActionEvents.PURCHASE_PLAN_CLICKED,
      properties: {
        ...properties,
      },
    }));
  };
};

export default useTrackPurchasePlanClicked;
