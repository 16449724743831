import useRealtimeDocument from 'hooks/use-realtime-document';
import React, { useMemo } from 'react';
import hash from 'json-stable-stringify';
import get from 'lodash/get';
import { IPlaylist, IVideo } from 'models';
import useVideo, { RealtimeVideoResult } from './use-video';

interface IPlaylistWithThumbnail extends IPlaylist {
  thumbnail: string | null;
}

export type RealtimePlaylistResult = {
  firstVideo: IVideo;
  loaded: true;
  playlist: IPlaylistWithThumbnail | null;
} | {
  firstVideo: null;
  loaded: false;
  playlist: null;
};

export default function usePlaylist(
  id: string | null,
): RealtimePlaylistResult {
  const [playlistDoc, loaded] = useRealtimeDocument<IPlaylist>('playlist', id);
  const firstId = useMemo(() => get(playlistDoc, 'members[0].id'), [hash(playlistDoc), loaded]);
  const { video, loaded: firstVideoLoaded } = useVideo(firstId) as RealtimeVideoResult;
  // add first video thumbnail as playlist thumbnail for now
  const playlist = useMemo(() => {
    if (!(video && video.thumbnail)) {
      return playlistDoc;
    }
    const firstThumbnail = video.thumbnail;
    return firstThumbnail ? {
      ...playlistDoc,
      thumbnail: firstThumbnail,
    } : playlistDoc;
  }, [id, hash(playlistDoc), hash(video), loaded, firstVideoLoaded]);

  // @ts-ignore
  const output: RealtimePlaylistResult = React.useMemo(
    () => ((loaded && id) ?
      { loaded: true, playlist, firstVideo: video } :
      { loaded: false, playlist: null }
    ),
    [id, hash(playlist), loaded],
  );

  return output;
}
