import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import invariant from 'invariant';

import { FIREBASE_API_KEYS_MAP_JSON, USE_FIREBASE_EMULATOR } from 'config';

export let db: firebase.app.App;
export default db;

export let shardName: string | null = null;

export const init = (shard: string) => {
  if (db) {
    throw new Error('Firebase already initialized');
  }

  shardName = shard;
  db = firebase.initializeApp({
    apiKey: FIREBASE_API_KEYS_MAP_JSON[shardName],
    databaseURL: `https://${shardName}.firebaseio.com`,
  });

  if (USE_FIREBASE_EMULATOR === 'true') {
    db.database().useEmulator('localhost', 9000);
  }
};

export const fetchValue = async <T>(path: string): Promise<T | null> => {
  invariant(
    shardName,
    'Can\'t fetch Firebase value before shard is chosen!',
  );
  const url = `https://${shardName}.firebaseio.com/${path}.json`;
  const { data } = await axios.get<T>(url);
  return data;
};

export const AUTH_OPTIONS = firebase.auth.Auth.Persistence.NONE;

export const getRef = (path: string): firebase.database.Reference => db.database().ref(path);

export const logIn = async (customToken: string): Promise<firebase.auth.UserCredential> => {
  await db.auth().setPersistence(AUTH_OPTIONS);
  return db.auth().signInWithCustomToken(customToken);
};

export const SERVER_TIME = firebase.database.ServerValue.TIMESTAMP;
