import { useDispatch } from 'react-redux';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics/actions';

export interface IAccessSubscriptionCreatedData {
  additionalPrices: string[];
  billingCycle: 'monthly' | 'annually';
  defaultCurrency: string;
  defaultPrice: string;
  sku: string;
  subscriptionName: string;
}

export type fnCallback = (data: IAccessSubscriptionCreatedData) => void;

export const useTrackSubscriptionCreated = (): fnCallback => {
  const dispatch = useDispatch();

  return (params: IAccessSubscriptionCreatedData) => {
    const { additionalPrices, defaultCurrency, defaultPrice, sku, billingCycle, subscriptionName } = params;

    const properties: IAccessSubscriptionCreatedData = {
      additionalPrices,
      billingCycle,
      defaultCurrency,
      defaultPrice,
      sku,
      subscriptionName,
    };

    dispatch(
      trackEvent({
        event: AdminActionEvents.ACCESS_SUBSCRIPTION_CREATED,
        properties,
      }),
    );
  };
};

export default useTrackSubscriptionCreated;
