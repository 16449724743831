import { Children, isValidElement, cloneElement, Attributes, ReactNode } from 'react';

export default function useChildRenderer(children: React.ReactNode) {
  const childRenderer = Children.count(children) === 1 ? Children.only(children) : null;

  const render = (props: Partial<any> & Attributes, ...childChildren: ReactNode[]) => {
    return isValidElement(childRenderer) ? cloneElement(childRenderer, props, childChildren) : null;
  };

  return render;
}
