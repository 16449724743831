import useRealtimeDocument from 'hooks/use-realtime-document';
import useSelector from 'hooks/use-selector';
import React from 'react';
import { getUserId } from 'services/auth';

interface IPollResponse {
  _id: string;
  answerId: string;
  userId: string;
}

export type UsePollResponseResult = {
  loaded: true;
  response: IPollResponse | null;
} | {
  loaded: false;
  response: null;
};

export default function usePollResponse(
  pollId: string | null,
): UsePollResponseResult {
  const userId = useSelector(getUserId);

  const viewKey = (userId && pollId) ?
    `${userId}:${pollId}` :
    null;

  const [response, loaded] = useRealtimeDocument<IPollResponse>('poll_response_view', viewKey);

  const output: UsePollResponseResult = React.useMemo(
    () => {
      return loaded ?
        { loaded: true, response } :
        { loaded: false, response: null };
    },
    [response, loaded],
  );

  return output;
}
