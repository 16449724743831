import React, { forwardRef, ReactNode, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { interpolateTranslation } from 'services/localization/interpolate';
import { WithExtraProps } from 'utils';
import { TInterpolator, TTranslator } from './types';

export const useAdminTranslation = () => {
  const { t, i18n } = useTranslation();

  const i = useCallback<TInterpolator>(
    (key, params) => {
      const template = t(`admin.${key}`);
      return interpolateTranslation(template, params);
    },
    [t],
  );

  return {
    t: useCallback<TTranslator>(
      (key, params) => (t as any)(`admin.${key}`, params),
      [t],
    ),
    i,
    currentLocale: i18n.language,
  };
};

/**
 * HOC to provide access to `t` via props
 */
export function withT<T>(component: T) {
  return forwardRef((props, ref) => {
    const { t } = useAdminTranslation();
    const Component = component as any;

    return (
      <Component
        ref={ref}
        t={t}
        {...props}
      />
    );
  }) as WithExtraProps<T, { t: never; }>;
}

/**
 * HOF that adds `t` as the first param of `fn`. This is useful for adding
 * type validation and intellisense to legacy non-TS functions
 */
export const injectT = <T extends (...args: any[]) => any>(fnFactory: (t: TTranslator) => T) => {
  const cache = {} as { fn?: T; t?: TTranslator };

  return (t: TTranslator, ...args: Parameters<T>): ReturnType<T> => {
    if (cache.t !== t) {
      cache.t = t;
      cache.fn = fnFactory(t);
    }

    return cache.fn?.(...args);
  };
};

/**
 * @description
 * Mocks the `t` function for use in stories
 */
// @ts-ignore
export const mockT: TTranslator = (key: string) => key;
