import { useState, useMemo } from 'react';
import clamp from 'lodash/clamp';
import { chunk } from 'lodash';

export interface ChunkPaginationReturnType<T> {
  canGoBack: boolean;
  canGoForward: boolean;
  goToNextPage: () => void;
  goToPage: (page: number) => void;
  goToPageUnchecked: (page: number) => void;
  goToPrevPage: () => void;
  nextPage: T[] | null;
  page: T[];
  pageCount: number;
  pageIndex: number;
  pages: T[][];
  prevPage: T[] | null;
}

export default function useChunkPagination<T>(
  items: T[],
  chunkSize: number,
): ChunkPaginationReturnType<T> {
  const [pageIndex, setPageIndex] = useState(0);

  const pages = !chunkSize ? [items] : chunk(items, chunkSize);
  const pageCount = pages.length;

  const goToPage = (page: number) => {
    setPageIndex(clamp(page, 0, pageCount));
  };

  return {
    canGoBack: pageIndex > 0,
    canGoForward: pageIndex < pageCount - 1,
    goToPage,
    goToPageUnchecked: setPageIndex,
    goToNextPage: () => goToPage(pageIndex + 1),
    goToPrevPage: () => goToPage(pageIndex - 1),
    nextPage: (pages[pageIndex + 1] || null) as T[] | null,
    page: pages[pageIndex],
    pageIndex,
    pages,
    prevPage: (pages[pageIndex - 1] || null) as T[] | null,
    pageCount,
  };
}
