import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { fontUrl } from 'services/app';

interface IFontsResponse {
  fonts: string[];
}
export interface IFonts {
  baseUrl: string;
  label: string;
  name: string;
  value: string;
}

const fontCache = new Map();

const useFonts = () => {
  const [fonts, setFonts] = useState<IFonts[]>([]);
  const cacheRef = useRef(fontCache);

  useEffect(() => {
    let unmounted = false;
    if (cacheRef.current.has(`${fontUrl}/config.json`)) {
      return;
    }

    (async () => {
      const genSelectorData = (font: string): IFonts => {
        const formattedString = font.replace(/-|_/g, ' ');
        return ({ baseUrl: `${fontUrl}/${font}`, label: formattedString, name: font, value: font });
      };

      const response = await Axios.get<IFontsResponse>(`${fontUrl}/config.json`);

      if (unmounted) {
        return;
      }

      const newFonts = response.data.fonts.map(genSelectorData);
      cacheRef.current.set(`${fontUrl}/config.json`, newFonts);
      fontCache.set(`${fontUrl}/config.json`, newFonts);
      setFonts(newFonts);
    })();

    return () => {
      unmounted = true;
    };
  }, []);

  return fonts;
};

export default useFonts;
