import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultNavigation, getNavigationAppliedInThePage, getNavigationSelectedInTheDropdown } from 'services/navigationv2';
import hash from 'json-stable-stringify';
import { constructPathForSlug, getPageSlugById } from 'services/navigationv2/utils';

export const useNavigation = (id?: string) => {
  const selectedNavigation = useSelector(getNavigationSelectedInTheDropdown);
  const appliedNavigation = useSelector(getNavigationAppliedInThePage);
  const defaultNavigation = useSelector(getDefaultNavigation);
  const navigationData = useMemo(() => {
    if (id === selectedNavigation._id) return selectedNavigation;
    if (id === appliedNavigation._id) return appliedNavigation;
    return defaultNavigation;
  }, [id, hash(selectedNavigation), hash(appliedNavigation), hash(defaultNavigation)]);

  const getPathBySlug = React.useCallback(
    (slug: string) => constructPathForSlug(defaultNavigation, slug),
    [hash(defaultNavigation)],
  );

  const getSlugById = React.useCallback(
    (pageId: string) => getPageSlugById(defaultNavigation, pageId),
    [hash(defaultNavigation)],
  );

  return {
    navigationData,
    getSlugById,
    getPathBySlug,
  };
};
