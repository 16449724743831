import { useEffect, useMemo } from 'react';
import { db } from 'firebase-db';
import firebase from 'firebase/compat/app';
import { camelify } from 'shared/string-utils';

const DEFAULT_LIMIT = 100; // TODO: This is 100% arbitrary.
export type OnEventFn = (payload: {key: string, payload: any}) => void;
export interface IProps {
  channel: string;
  limit?: number;
  onMessage?: OnEventFn;
  onRemoved?: OnEventFn;
  onUpdated?: OnEventFn;
  property?: string;
}

const onEvent = (snapshot: firebase.database.DataSnapshot, callback: OnEventFn) => {
  if (!(snapshot && snapshot.key)) {
    return;
  }
  const { key } = snapshot;
  const payload = camelify(snapshot.val());
  if (callback) {
    callback({ key, payload });
  }
};

const useRealtimeChannel = ({
  channel,
  limit = DEFAULT_LIMIT,
  onMessage,
  onRemoved,
  onUpdated,
  property= 'messages',
}: IProps) => {

  const address = channel ? `channels:v2/${channel}/${property}` : null;
  const ref = useMemo<firebase.database.Query | null>(() => {
    return address ? db.database().ref(address).limitToLast(limit) : null;
  }, [
    address,
  ]);

  useEffect(() => {
    if (!ref || !onUpdated) {
      return;
    }

    const onChildAdded = (snapshot: firebase.database.DataSnapshot) => {
      onEvent(snapshot, onUpdated);
    };
    ref.on('child_changed', onChildAdded);
    return () => {
      ref.off('child_changed', onChildAdded);
    };
  }, [
    address,
    limit,
    onUpdated,
  ]);

  useEffect(() => {
    if (!ref || !onMessage) {
      return;
    }

    const onChildAdded = (snapshot: firebase.database.DataSnapshot) => {
      onEvent(snapshot, onMessage);
    };
    ref.on('child_added', onChildAdded);
    return () => {
      ref.off('child_added', onChildAdded);
    };
  }, [
    address,
    limit,
    onMessage,
  ]);

  useEffect(() => {
    if (!ref || !onRemoved) {
      return;
    }

    const onChildRemoved = (snapshot: firebase.database.DataSnapshot) => {
      onEvent(snapshot, onRemoved);
    };
    ref.on('child_removed', onChildRemoved);
    return () => {
      ref.off('child_removed', onChildRemoved);
    };
  }, [
    address,
    limit,
    onRemoved,
  ]);
};

export default useRealtimeChannel;
