import { useMemo } from 'react';
import useSelector from 'hooks/use-selector';
import {
  isEmbed,
  isOverlayEmbed,
  isMobilePanelEmbed,
  isTheaterEmbed,
  isVideoEmbed,
} from 'services/user-layout/selectors';
import {
  isDesktopLayout,
  isLandscape,
  isMobileLayout,
} from 'services/device/selectors';
import { isWelcomeScreenActive } from 'services/welcome-screen';
import { isAdminViewingAccessTab } from 'services/gate/selectors';
import { isLandingPageType } from 'services/app/selectors';
import { getCustomPanels } from 'services/custom-panels/selectors';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

interface IOutput {
  showMobile: boolean;
  showMobileLayout: boolean;
  showSidebar: boolean;
}

const useSiteLayout = (): IOutput => {
  const customPanels = useSelector(getCustomPanels);
  const mobilePanelEmbedLayout = useSelector(isMobilePanelEmbed);
  const overlayEmbedLayout = useSelector(isOverlayEmbed);
  const desktopLayout = useSelector(isDesktopLayout);
  const welcomeScreenActive = useSelector(isWelcomeScreenActive);
  const embedLayout = useSelector(isEmbed);
  const landscapeOrientation = useSelector(isLandscape);
  const isLanding = useSelector(isLandingPageType);
  const theaterEmbeded = useSelector(isTheaterEmbed);
  const videoEmbeded = useSelector(isVideoEmbed);
  const mobileLayout = useSelector(isMobileLayout);
  const showAccessTab = useSelector(isAdminViewingAccessTab);
  const isOrientationLocked = useSelector(getIsOrientationLocked);

  const showMobile = useMemo(() => (
    (
      mobileLayout &&
      (!welcomeScreenActive || showAccessTab) &&
      !embedLayout
    ) ||
    mobilePanelEmbedLayout
  ), [
    embedLayout,
    mobileLayout,
    mobilePanelEmbedLayout,
    welcomeScreenActive,
  ]);

  const showMobileLayout = useMemo(() => (
    showMobile &&
    (isOrientationLocked || !landscapeOrientation) &&
    !mobilePanelEmbedLayout &&
    !theaterEmbeded &&
    !videoEmbeded
  ), [
    landscapeOrientation,
    showMobile,
    mobilePanelEmbedLayout,
    isOrientationLocked,
    theaterEmbeded,
    videoEmbeded,
  ]);

  const showSidebar = useMemo(() => (
    !videoEmbeded && (
      (!(embedLayout || overlayEmbedLayout) && !(isLanding && !customPanels.length) &&
      desktopLayout &&
      !welcomeScreenActive) || mobilePanelEmbedLayout
    )
  ), [
    customPanels.length,
    embedLayout,
    isLanding,
    overlayEmbedLayout,
    desktopLayout,
    welcomeScreenActive,
    mobilePanelEmbedLayout,
    videoEmbeded,
  ]);

  return {
    showMobile,
    showMobileLayout,
    showSidebar,
  };
};

export default useSiteLayout;
