import useFonts from 'hooks/use-fonts';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

type TContext = ReturnType<typeof useFonts>;

export const FontsContext = createContext<TContext | null>(null);

export const FontsProvider: FC<PropsWithChildren> = ({ children }) => (
  <FontsContext.Provider value={useFonts()}>
    {children}
  </FontsContext.Provider>
);

export const useFontsContext = () => {
  const context = useContext(FontsContext);
  if (!context) {
    throw new Error('useFontsContext must be used within a FontsProvider');
  }

  return context;
};
