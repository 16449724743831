import { QUEST_SERVICE_OVERRIDE, SERVICE_BASE_URL } from 'config';
import { useEffect, useMemo, useState } from 'react';
import { getPrimaryToken } from 'services/auth/selectors';
import useAjax from './use-ajax';
import useSelector from './use-selector';

interface IQuestReward {
  claimCode: string | number;
  claimDate: number | null;
  questId: string;
  rewardDescription?: string;
  rewardImage?: string;
  rewardType: 'webhook' | 'prize-service-specific' | 'prize' | 'raffle';
}

interface IRaffleReward {
  broadcastId: string;
  claimDate: number;
  raffleReward: string;
}

interface IResponse {
  accountId: string;
  questRewards: IQuestReward[];
  raffleRewards: IRaffleReward[];
}

export type UseUserRewardsResult = {
  data: IResponse | null;
  loaded: true;
} | {
  data: null;
  loaded: false;
};

const baseUrl = QUEST_SERVICE_OVERRIDE || `${SERVICE_BASE_URL}/quest/v2`;
const useUserRewards = (): UseUserRewardsResult => {
  const primaryToken = useSelector(getPrimaryToken);
  const [filteredQuestRewards, setFilteredQuestRewards] = useState<IQuestReward[]>([]);

  // null urls will bypass the useAjax call, thus returning loaded true, data null
  // useful for when the user isnt logged in
  const url = useMemo(() => (
    primaryToken ? `${baseUrl}/rewards` : null
  ), [primaryToken]);

  const { data, loaded } = useAjax<IResponse>({
    headers: { Authorization: `Bearer ${primaryToken}` },
    method: 'GET',
    url,
  });

  // filter out all non-prize types from being displayed
  useEffect(() => {
    if (loaded && data && data.questRewards) {
      const questRewards = data.questRewards || [];
      const prizeOnly = questRewards.filter(({ rewardType }) => (
        ['prize-service-specific', 'prize'].includes(rewardType)
      ));
      setFilteredQuestRewards(prizeOnly);
    } else {
      setFilteredQuestRewards([]);
    }
  }, [
    data,
    loaded,
  ]);

  return loaded ? {
    data: data ? {
      ...data,
      questRewards: filteredQuestRewards,
    } : null,
    loaded,
  } : { loaded: false, data: null };
};

export default useUserRewards;
