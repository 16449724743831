import { useDispatch, useSelector } from 'react-redux';
import { setAccessControlFtue } from 'services/admin';
import { isAccessControlFTUE } from 'services/admin/selectors';

const useAccessFTUE = (): [boolean, () => void] => {
 const isAccessControlModeFTUE = useSelector(isAccessControlFTUE);
 const dispatch = useDispatch();

 const setAccessControlModeFTUE = () => {
   if (isAccessControlModeFTUE) {
     dispatch(setAccessControlFtue());
   }
 };

 return [isAccessControlModeFTUE, setAccessControlModeFTUE];
};

export default useAccessFTUE;
