import { useCallback } from 'react';

export default function useCopyToClipboard() {
  return useCallback((currentRef: HTMLInputElement) => {
    const { value } = currentRef;
    try {
      if (typeof value === 'string' || typeof value === 'number') {
        navigator.clipboard.writeText(value.toString());
      }
    } catch (clipboardErr) {
      try {
        currentRef.select();
        document.execCommand('copy');
      } catch (execCommandErr) {
        // tslint:disable-next-line:no-console
        console.error(
          'Failed to copy text to clipboard:',
          currentRef,
          execCommandErr,
          clipboardErr,
        );
      }
    }
  }, []);
}
