import { useRef, useEffect, Dispatch } from 'react';

const useTimeoutUpdate = <T>(
  setter: Dispatch<T>,
  delay: number,
  condition: boolean,
  newValue: T,
) => {
  const timeout = useRef<number>();
  useEffect(() => {
    if (condition) {
      timeout.current = setTimeout(() => setter(newValue), delay);
    }
    return () => clearTimeout(timeout.current);
  }, [condition, delay, setter, newValue]);
};

export default useTimeoutUpdate;
