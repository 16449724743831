import { useState, useEffect } from 'react';
import { useApis } from 'contexts/ApisContext';
import useSelector from './use-selector';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app';

export const useVideoTags = () => {
  const [existingTags, setExistingTags] = useState<string[]>([]);
  const { getAllTagsForSite } = useApis().video;
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);

  useEffect(
    () => {
      if (!primaryToken || !siteId)
        return;

      let abort = () => {
        // placeholder
      };

      getAllTagsForSite(
        {
          primaryToken,
          siteId,
        },
        (cancellator) => {
          abort = cancellator;
        },
      )
        .then(
          (tags) => setExistingTags([
            // dedup
            ...(new Set(tags) as any),
          ]),
        );

      return abort;
    },
    [primaryToken, siteId],
  );

  return existingTags;
};
