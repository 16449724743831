import { useState, useEffect } from 'react';

const useDeferredValue = <T = unknown> (value: T, delayMs = 100) => {
  const [deferredValue, setDeferredValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDeferredValue(value);
    }, delayMs);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delayMs]);

  return deferredValue;
};

export default useDeferredValue;
