import { Dispatch, useEffect, useReducer } from 'react';
import hash from 'json-stable-stringify';
import { IPoll, IPopulatedAnswer, PollTypes } from 'services/polls/models';
import shortid from 'shortid';
import usePoll from './use-poll';

export enum PredictionStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  COMPLETED = 'results',
}

export const POLL_STATUS_OPTIONS = [
  { label: 'Open', value: PredictionStatus.OPEN },
  { label: 'Closed', value: PredictionStatus.CLOSED },
];

export const PREDICTION_STATUS_OPTIONS = [
  ...POLL_STATUS_OPTIONS,
  { label: 'Completed', value: PredictionStatus.COMPLETED },
];

export const getLabelFromStatus = (status: PredictionStatus): {
  label: string, value: string,
} => (
  PREDICTION_STATUS_OPTIONS.find(({ value }) => {
    return status === value;
  })!
);

type Poll = Required<Pick<
  IPoll,
  'answers' | 'name' | 'open' | 'question' | 'showCount' | 'type'
>> & Partial<Exclude<
  IPoll,
  'answers' | 'modified' | 'name' | 'open' | 'question' | 'showCount' | 'type'
>>;

type PollAnswer = Required<Pick<
  IPopulatedAnswer,
  'answer'
>> & Exclude<IPopulatedAnswer, 'answer'>;

interface IState {
  correctAnswers: PollAnswer[];
  poll: Poll;
  predictionStatus: PredictionStatus;
}

const formatAnswers = ({
  answers,
  correctAnswers,
  predictionStatus,
  type,
} : {
  answers: PollAnswer[],
  correctAnswers: PollAnswer[],
  predictionStatus: PredictionStatus;
  type: PollTypes
}): PollAnswer[] => {
  const open = predictionStatus === 'open';
  const closed = predictionStatus === 'closed';
  const predictionCompleted = type === PollTypes.Prediction && !(open || closed);
  const canSetCorrect = type === PollTypes.Trivia || predictionCompleted;
  return answers
    .map(({ correct, arrayId, ...rest }) => rest)
    .map((answer) => {
      const isACorrectAnswer = correctAnswers.some((correctAnswer) => {
        const matchingIds = correctAnswer._id && correctAnswer._id === answer._id;
        const matchingText = correctAnswer.answer === answer.answer;
        return matchingIds || matchingText;
      });
      return {
        ...answer,
        answer: answer.answer,
        correct: isACorrectAnswer && canSetCorrect,
      };
    });
};

const getPredictionStatus = ({
  correctAnswers,
  open,
  type,
}: {
  correctAnswers: PollAnswer[];
  open: boolean;
  type: PollTypes;
}): PredictionStatus => {
  if (correctAnswers.length > 0 && type === PollTypes.Prediction) {
    return PredictionStatus.COMPLETED;
  }
  return open ? PredictionStatus.OPEN : PredictionStatus.CLOSED;
};

const getInitalAnswersState = () => (
  [{ answer: '' }, { answer: '' }]
);
const getInitialState = (initialDoc): IState => ({
  correctAnswers: [],
  poll: {
    answers: getInitalAnswersState(),
    name: initialDoc?.name || '',
    open: true,
    question: '',
    showCount: false,
    type: initialDoc?.type || PollTypes.Poll,
  },
  predictionStatus: PredictionStatus.OPEN,
});

type IAction = {
  payload: Poll;
  type: 'UPDATE_POLL';
} | {
  payload: IPopulatedAnswer[];
  type: 'SET_CORRECT_ANSWERS' | 'SET_ANSWERS'
} | {
  payload: string;
  type: 'SET_QUESTION' | 'SET_NAME'
} | {
  payload: PredictionStatus;
  type: 'SET_PREDICTION_STATUS';
} | {
  payload: boolean;
  type: 'SET_SHOW_COUNT'
} | {
  payload: PollTypes;
  type: 'SET_TYPE';
} | {
  type: 'ADD_BLANK_ANSWER' | 'DELETE_LAST_ANSWER';
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'ADD_BLANK_ANSWER': {
      const {
        correctAnswers,
        poll,
        predictionStatus,
      } = state;
      return {
        ...state,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers: [
              ...state.poll.answers,
              { answer: '', arrayId: shortid.generate() },
            ],
            correctAnswers,
            predictionStatus,
            type: poll.type,
          }),
        },
      };
    }
    case 'DELETE_LAST_ANSWER': {
      const {
        correctAnswers,
        poll,
        predictionStatus,
      } = state;
      const { answers, type } = poll;
      const modifiedAnswers = answers.slice(0, answers.length - 1);
      return {
        ...state,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers: modifiedAnswers,
            correctAnswers,
            predictionStatus,
            type,
          }),
        },
      };
    }
    case 'SET_ANSWERS': {
      const {
        correctAnswers,
        predictionStatus,
        poll,
      } = state;
      const type  = poll.type!;
      return {
        ...state,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers: action.payload,
            correctAnswers,
            predictionStatus,
            type,
          }),
        },
      };
    }
    case 'SET_CORRECT_ANSWERS': {
      const {
        poll,
        predictionStatus,
      } = state;
      const correctAnswers = action.payload;
      const { answers, type } = poll;
      return {
        ...state,
        correctAnswers,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers,
            correctAnswers,
            predictionStatus,
            type,
          }),
        },
      };
    }
    case 'SET_NAME': {
      return {
        ...state,
        poll: {
          ...state.poll,
          name: action.payload,
        },
      };
    }
    case 'SET_PREDICTION_STATUS': {
      const predictionStatus = action.payload;
      const { correctAnswers, poll } = state;
      const { answers, type } = poll;

      return {
        ...state,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers,
            correctAnswers,
            predictionStatus,
            type,
          }),
          open: predictionStatus === PredictionStatus.OPEN,
        },
        predictionStatus,
      };
    }
    case 'SET_QUESTION': {
      return {
        ...state,
        poll: {
          ...state.poll,
          question: action.payload,
        },
      };
    }
    case 'SET_SHOW_COUNT': {
      return {
        ...state,
        poll: {
          ...state.poll,
          showCount: action.payload,
        },
      };
    }
    case 'SET_TYPE': {
      const { correctAnswers, poll } = state;
      const { answers } = poll;
      const type = action.payload;

      // required when switching off prediction type
      const resetPredictionStatus = type !== PollTypes.Prediction &&
        state.predictionStatus === PredictionStatus.COMPLETED;

      const predictionStatus = resetPredictionStatus ? PredictionStatus.OPEN : state.predictionStatus;
      return {
        ...state,
        poll: {
          ...state.poll,
          answers: formatAnswers({
            answers,
            correctAnswers,
            predictionStatus,
            type,
          }),
          type,
        },
        predictionStatus,
      };
    }
    case 'UPDATE_POLL': {
      const {
        answers,
        modified,
        open,
        showCount = false,
        type,
        ...poll
      } = action.payload;
      const correctAnswers = answers ? answers.filter(({ correct }) => correct) : [];
      return {
        ...state,
        correctAnswers,
        poll: {
          ...poll,
          answers: answers || getInitalAnswersState(),
          open,
          type,
          showCount,
        },
        predictionStatus: getPredictionStatus({ correctAnswers, open, type }),
      };
    }
    default: {
      return state;
    }
  }
};

interface IParams {
  initialDoc?: any,
}

const usePollEditor = ({
  initialDoc,
}: IParams): [
  IState,
  Dispatch<IAction>,
] => {
  const { poll } = usePoll(initialDoc?._id);
  const [state, pollEditorDispatch] = useReducer(reducer, getInitialState(initialDoc));

  useEffect(() => {
    if (poll) {
      // @ts-ignore
      pollEditorDispatch({ payload: poll, type: 'UPDATE_POLL' });
    }
  }, [hash(poll)]);

  return [state, pollEditorDispatch];
};

export default usePollEditor;
