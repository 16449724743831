// TODO: Figure out generic useReducer, less `any` type
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import invariant from 'invariant';
import hash from 'json-stable-stringify';
import isObject from 'lodash/isObject';
import { useEffect, useMemo, useReducer } from 'react';

// @ts-ignore
import { camelify } from 'shared/string-utils';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';

interface IPendingState {
  data: null;
  error: null;
  loaded: false;
}

interface ISuccessState<T> {
  data: T;
  error: null;
  loaded: true;
}

interface IFailureState {
  data: null;
  error: Error;
  loaded: true;
}

interface IBypassState {
  data: null;
  error: null;
  loaded: true;
}

export type IReducerState<T = any> = IPendingState | ISuccessState<T> | IFailureState | IBypassState;

interface IStartAction {
  payload: null;
  type: 'start';
}

interface IErrorAction {
  payload: { message: string };
  type: 'error';
}

interface IEndAction {
  payload: { data: any };
  type: 'end';
}

interface IBypassAction {
  payload: null;
  type: 'bypass';
}

type IReducerAction = IStartAction | IErrorAction | IEndAction | IBypassAction;

export const getInitialState: IPendingState = {
  data: null,
  error: null,
  loaded: false,
};

const reducer = (state: IReducerState, action: IReducerAction): IReducerState => {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        data: null,
        error: null,
        loaded: false,
      };
    case 'error':
      return {
        ...state,
        data: null,
        error: new Error(action.payload.message),
        loaded: true,
      };
    case 'end':
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loaded: true,
      };
    case 'bypass':
      return {
        ...state,
        data: null,
        error: null,
        loaded: true,
      };
    default:
      return state;
  }
};

const EMPTY_OBJECT = {};

interface IStringMap {
  [key: string]: string;
}

export interface IUseAjaxOptions<T> {
  blockRequest?: boolean;
  body?: IStringMap | object;
  headers?: IStringMap;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: Record<string, string | number | object>;
  refreshKey?: unknown;
  responseType?: 'json' | 'blob';
  transform?: (rawData: unknown) => T;
  url: string | null;
}

function throwError(error: string): never {
  return invariant(false, error) as never;
}

export default function useAjax<T>(options: IUseAjaxOptions<T>): IReducerState<T> {
  const {
    body = null,
    headers = null,
    method = 'GET',
    params = EMPTY_OBJECT,
    refreshKey = null,
    responseType = 'json',
    transform = camelify,
    url = throwError('useAjax hook requires options.url'),
    blockRequest = false,
  } = options;
  const [state, dispatch] = useReducer(reducer, getInitialState);
  const siteId = useSelector(getSiteId);
  const primaryToken = useSelector(getPrimaryToken);
  const requestHeaders = useMemo(() => ({
    Authorization: `Bearer ${primaryToken}`,
    'x-maestro-client-id': siteId,
    ...(headers || {}),
  }), [siteId, primaryToken, headers]);

  useEffect(
    () => {
      if (!url || blockRequest) {
        const needsBypass = !state.loaded || state.data || state.error;
        if (needsBypass) {
          dispatch({ payload: null, type: 'bypass' });
        }
        return;
      }

      let cancelSource: CancelTokenSource | null = axios.CancelToken.source();
      const axiosConfig: AxiosRequestConfig = {
        cancelToken: cancelSource.token,
        data: body,
        headers: requestHeaders,
        method,
        params,
        responseType,
        url,
      };

      dispatch({ payload: null, type: 'start' });
      axios(axiosConfig)
        .then((response) => {
          const payload = { data: responseType === 'blob' ? response.data : transform(response.data) };
          dispatch({ payload, type: 'end' });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          let message = 'Unknown error';
          if (error.response) {
            if (isObject(error.response.data) && error.response.data.message) {
              message = error.response.data.message;
            } else if (error.response.statusText) {
              message = error.response.statusText;
            }
          } else if (typeof error.message === 'string') {
            message = error.message;
          }
          dispatch({ payload: { message }, type: 'error' });
        })
        .then(() => {
          cancelSource = null;
        });

      return () => {
        if (cancelSource) {
          cancelSource.cancel();
          cancelSource = null;
        }
      };
    },
    [
      hash(body),
      dispatch,
      hash(headers),
      method,
      hash(params),
      refreshKey,
      responseType,
      transform, // TODO: This seems easy to mess up
      url,
      blockRequest,
    ],
  );

  return state;
}
