import { useSelector as reduxUseSelector } from 'react-redux';
import IState from 'services/state';

const useSelector = <TSelected>(
  selector: (state: IState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
  return reduxUseSelector(selector, equalityFn);
};

export default useSelector;
