import { joinKeySegments } from 'firebase-utils';
import isEqual from 'lodash/isEqual';
import hash from 'json-stable-stringify';
import { useEffect, useMemo } from 'react';
import { getDocument, isDocumentLoaded, subscribe, unsubscribe } from 'services/realtime';
import useDispatch from './use-dispatch';
import useSelector from './use-selector';

interface SearchParam {
  collection: string;
  id: string | null;
}

const useRealtimeDocuments = <T>(searchParams: SearchParam[]): [(T | null)[], boolean[]] => {
  const documents = useMemo(() => (
    searchParams.map(({ collection, id }) => ({
      collection,
      id,
      path: (id && joinKeySegments([collection, id])) || null,
    }))
  ), [hash(searchParams)]);

  const dispatch = useDispatch();

  useEffect(() => {
    for (const { path } of documents) {
      if (path) {
        dispatch(subscribe(path));
      }

    }
    return () => {
      for (const { path } of documents) {
        if (path) {
          dispatch(unsubscribe(path));
        }
      }
      return;
    };
  }, [hash(documents)]);

  const loadedMap = useSelector((state) => (
    documents.map(({ collection, id, path }) => (
      Boolean(path && id && isDocumentLoaded(state, collection, id))
    ))
  ), isEqual);

  const docs = useSelector((state) => (
    documents.map(({ collection, id, path }) => (
      path && id ? getDocument(state, collection, id) as T : null
    ))
  ), isEqual);

  return [docs, loadedMap];
};

export default useRealtimeDocuments;
