import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAccountRole, RoleScope } from 'services/auth';
import hash from 'json-stable-stringify';

// ['*', `channel:${id}`, `object:${id}`, 'Chat_Moderator'];
const write = true;

const findRole = (roles: IAccountRole[], scope: string & RoleScope) => (
  roles.find((r: IAccountRole) => r.scope === scope) || null
);

/*
  pass a user's roles array and a target scope
  get a role for that scope or null
*/
const useRole = (roles: IAccountRole[], scope: string & RoleScope) => {
  const [roleValue, setRoleValue] = useState(findRole(roles, scope));

  useEffect(() => {
    setRoleValue(findRole(roles, scope));
  }, [hash(roles), scope]);

  const pageId = useMemo(() => get(roleValue, 'pageId', []), [roleValue]);

  const setPageIds = useCallback((pageIds) => {
    setRoleValue({
      pageId: pageIds,
      scope,
      write,
    });
  }, [setRoleValue, scope]);

  const toggleRole = useCallback(() => {
    const newValue = roleValue ? null : { scope, pageId, write };
    setRoleValue(newValue);
  }, [roleValue, setRoleValue]);

  return [roleValue!, toggleRole, setPageIds] as const;
};

export default useRole;
