import { IProduct, IVariant, OPTION_TYPE, Option, OptionData, SelectedOption } from 'components/Ecommerce/models';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { SHOPIFY_DEFAULT_TITLE } from 'services/shopify/constants';
import hash from 'json-stable-stringify';
import { getSelectedVariant } from './useProductVariant';

const OPTIONS_ORDER = {
  [OPTION_TYPE.color]: 1,
  [OPTION_TYPE.size]: 2,
  [OPTION_TYPE.generic]: 3,
};

interface IUseProductOptionsResult {
  handleOptionChange: (type: string, value: OptionData) => void;
  options: Option[];
  selectedOptions: SelectedOption[];
  selectedVariant: IVariant | null;
}

/**
 * Custom hook that encapsulates logic to manage options availability and selection.
 *
 * @function
 * @param {IProduct} product - The product object with its options and variants.
 * @param {SelectedOption[]} [preSelectedOptions] - Preselected options (if it exists).
 * ex: User chooses a Blue Large shirt and add it to the Cart, when the User goes to the Cart,
 * the Blue Large shirt will be preselected - as opposed of selecting the first available color/size by default.
 * @returns {IUseProductOptionsResult} An object containing the following:
 * selectedOptions: if preSelectedOptions is provided, it will be used as initial value,
 *  if a preSelectedOption is not available, it will be set to the first available option value
 *  (ex 1: if a Medium Green shirt is not available, it will fallback to a Small Green shirt)
 *  (ex 2: if a Green shirt is not available, it will fallback to a Blue shirt)
 *  if preSelectedOptions is not provided at all, getDefaultSelectedOptions will be used to
 *  set the initial default value ( it gets the first available option value for each option type )
 * options: an array of options with availability information based on which options are selected ( selectedOptions )
 *  ex: if Green is selected as color, the sizes not available for Green will be disabled to the User ( cant select it )
 * handleOptionChange: a function that updates the selectedOptions based on the chosen option type and value ( user input )
 * selectedVariant: The variant object from the product's list of variants that matches the user's selected options.
 */

export const useProductOptions = (
  product: IProduct, preSelectedOptions?: SelectedOption[],
): IUseProductOptionsResult => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);

  useEffect(() => {
    if (preSelectedOptions) {
      setSelectedOptions(getPreSelectedOptions(product, preSelectedOptions));
    } else {
      setSelectedOptions(getDefaultSelectedOptions(product));
    }
  }, [hash(preSelectedOptions), product.id]);

  const options = useMemo(() => {
    // sort options according to predefined order
    const sortedOptions = [...product.options].sort((a, b) => {
      const orderA = OPTIONS_ORDER[a.type];
      const orderB = OPTIONS_ORDER[b.type];
      return orderA - orderB;
    });

    // iterate over sorted options and set availability based on selectedOptions
    return sortedOptions
    // remove the default title from the options
    .filter(option => option.values.filter(value => value.name.toLowerCase() !== SHOPIFY_DEFAULT_TITLE).length > 0)
    .map((option) => {
      const updatedValues = option.values.map(value => {
        // only consider the options before the current one for availability check
        const hypotheticalSelectedOption = transformToSelectedOption(option, value);
        const isAvailable = isOptionAvailable(
          product.variants,
          hypotheticalSelectedOption,
        );
        return { ...value, isAvailable };
      });
      return { ...option, values: updatedValues };
    });
  }, [product, selectedOptions]);

  const handleOptionChange = useCallback((type: string, value: OptionData) => {
    const optionIndex = options.findIndex(option => option.type === type);

    if (optionIndex === -1) return;

    setSelectedOptions(prevSelectedOptions => {
      const updatedSelectedOptions = prevSelectedOptions.map(selectedOption => {
        if (selectedOption.type === type) {
          return { ...selectedOption, value };
        }
        return selectedOption;
      });

      // after updating the selected option, check for the availability of the remaining combination
      for (let i = optionIndex + 1; i < options.length; i++) {
        const option = options[i];
        const currentSelectedValue = updatedSelectedOptions[i].value;

        // check if the currently selected value is still available with the new color
        const isCurrentSelectedValueAvailable = isOptionAvailable(
          product.variants,
          { ...updatedSelectedOptions[i], value: currentSelectedValue },
        );

        // if the current selected value is not available, find the first available value
        if (!isCurrentSelectedValueAvailable) {
          const availableValue = option.values.find(v => {
            const hypotheticalSelectedOption = { ...updatedSelectedOptions[i], value: v };
            return isOptionAvailable(product.variants, hypotheticalSelectedOption);
          });

          if (availableValue) {
            updatedSelectedOptions[i] = { ...updatedSelectedOptions[i], value: availableValue };
          } else {
            // if no available value found, fallback to the first value
            updatedSelectedOptions[i] = { ...updatedSelectedOptions[i], value: option.values[0] };
          }
        }
      }
      return updatedSelectedOptions;
    });
  }, [product, options]);

  const selectedVariant = useMemo(() => {
    return getSelectedVariant(product.variants, selectedOptions);
  }, [product.variants, selectedOptions]);

  return { options, selectedOptions, handleOptionChange, selectedVariant };
};

const getPreSelectedOptions = (product: IProduct, preSelectedOptions: SelectedOption[]) => {
  const previousSelectedOptions: SelectedOption[] = [];
  const selectedOptions: SelectedOption[] = [];

  for (const option of product.options) {
    const preSelectedOption = preSelectedOptions.find(pre => option?.values?.find(i => i.name === pre.value.name));

    // if preSelectedOption is found and available, return it
    if (preSelectedOption) {
      if (isOptionAvailable(product.variants, preSelectedOption)) {
        previousSelectedOptions.push(preSelectedOption);
        selectedOptions.push(preSelectedOption);
        continue;
      }
    }

    // if preSelectedOption is not found or not available, find the first available option value
    const firstAvailableValue = option.values.find((optionValue: OptionData) => {
      const selOption = transformToSelectedOption(option, optionValue);
      return isOptionAvailable(product.variants, selOption);
    });

    // if no available value is found, use the first value as default
    const selectedOption = firstAvailableValue
      ? transformToSelectedOption(option, firstAvailableValue)
      : transformToSelectedOption(option, option.values[0]);

    previousSelectedOptions.push(selectedOption);
    selectedOptions.push(selectedOption);
  }

  return selectedOptions;
};

const getDefaultSelectedOptions = (product: IProduct) => {
  const previousSelectedOptions: SelectedOption[] = [];
  const selectedOptions: SelectedOption[] = [];

  for (const option of product.options) {
    const firstAvailableValue = option.values.find((optionValue: OptionData) => {
      const selOption = transformToSelectedOption(option, optionValue);
      return isOptionAvailable(product.variants, selOption);
    });

    if (!firstAvailableValue) {
      selectedOptions.push(transformToSelectedOption(option, option.values[0]));
      continue;
    }

    const selectedOption = transformToSelectedOption(option, firstAvailableValue);
    previousSelectedOptions.push(selectedOption);
    selectedOptions.push(selectedOption);
  }

  return selectedOptions;
};

const isOptionAvailable = (
  variants: IVariant[],
  selectedOption: SelectedOption,
) => {
  return variants.some((variant) => {
    const selectedOptions = [selectedOption];
    return selectedOptions.every((selOption) => {
      const variantContainsSelectedOption = variant.selectedOptions.find(variantSelectedOption => {
        return (
          variantSelectedOption.type === selOption.type &&
          variantSelectedOption.value.name === selOption.value.name
        );
      });
      return variantContainsSelectedOption && variant.availableForSale;
    });
  });
};

const transformToSelectedOption = (option: Option, value: OptionData): SelectedOption => {
  return { name: option.name, type: option.type, value };
};
