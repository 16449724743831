import { useSelector } from 'react-redux';
import { getLivestreamVideoId } from 'services/livestream';
import { useRealtimeDocument } from 'hooks';
import { IEPGResponse, IVideo } from 'models';
import { getObjectId } from 'services/app';
import type IModel from 'models/IModel';

export const useStreamStartTime = () => {
  const videoId = useSelector(getLivestreamVideoId);
  const [video] = useRealtimeDocument<IVideo>('video', videoId);

  const channelId = useSelector(getObjectId);
  const [epg] = useRealtimeDocument<IEPGResponse & IModel>('epg', channelId);
  const epgAssetId = epg?.asset?.contentId;
  const epgTimestamp = epg?.modified;

  // fallback to the time at which /startStream was requested if no epg info
  // is available
  const [liveBroadcast] = useRealtimeDocument<{ startTime: number }>('livebroadcast', video?.liveBroadcastId);

  if (epgAssetId === videoId && epgTimestamp) {
    return epgTimestamp;
  }

  return liveBroadcast?.startTime;
};
