import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { VALIDATE_SERVICE_BASE_URL } from 'config';
import hash from 'json-stable-stringify';
import { useEffect, useMemo, useReducer } from 'react';
import useAjax from './use-ajax';

interface IProps {
  collection: string;
  currentSlugs?: string[],
  doc: any;
  enabled: boolean;
}

interface IState {
  data: any;
  error: null | TI18nKey;
  fetching: boolean;
  valid: boolean;
}

const getInitialState = (): IState => ({
  data: null,
  error: null,
  fetching: false,
  valid: false,
});

type IAction = {
  payload: string;
  type: 'error';
} | {
  payload: any;
  type: 'valid'
} | {
  type: 'fetching' | 'reset' | 'bypass';
};

const TRANSLATION_KEYS = {
  'Slug already in use': 'ADMIN_LABEL_SLUG_UNAVAILABLE',
  'unknown model': 'ADMIN_LABEL_UNKNOWN_MODEL',
} as const;

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'bypass': {
      return {
        data: null,
        error: null,
        fetching: false,
        valid: true,
      };
    }
    case 'error': {
      return {
        data: null,
        error: TRANSLATION_KEYS[hash(action.payload)] || 'ADMIN_LABEL_MODEL_CREATION_ERROR',
        fetching: false,
        valid: false,
      };
    }
    case 'fetching': {
      return {
        data: null,
        error: null,
        fetching: true,
        valid: false,
      };
    }
    case 'reset': {
      return getInitialState();
    }
    case 'valid': {
      return {
        data: action.payload,
        error: null,
        fetching: false,
        valid: true,
      };
    }
    default: {
      return state;
    }
  }
};

const useValidator = ({
  collection,
  currentSlugs,
  doc,
  enabled,
}: IProps): IState => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const url = useMemo(() => {
    return enabled && doc && (!currentSlugs || currentSlugs.indexOf(doc.slug) === -1) ? `${VALIDATE_SERVICE_BASE_URL}/${collection}` : null;
  }, [
    collection,
    enabled,
    hash(doc),
  ]);

  const {
    data: ajaxData,
    error: ajaxError,
    loaded: ajaxLoaded,
  } = useAjax({
    body: doc,
    method: 'POST',
    url,
  });

  useEffect(() => {
    // set valid to true if not enabled
    if (!enabled) {
      dispatch({ type: 'bypass' });
      return;
    }
    if (!ajaxLoaded) {
      dispatch({ type: 'fetching' });
      return;
    }
    if (ajaxError) {
      dispatch({ payload: ajaxError.message, type: 'error' });
      return;
    }
    if (ajaxData === null && currentSlugs === doc.slug) {
      dispatch({ payload: ajaxData, type: 'valid' });
      return;
    }
    // reset state, do not show valid
    if (ajaxData === null) {
      dispatch({ type: 'reset' });
      return;
    }
    dispatch({ payload: ajaxData, type: 'valid' });
  }, [
    enabled,
    hash(ajaxData),
    hash(ajaxError),
    ajaxLoaded,
  ]);

  return state;
};

export default useValidator;
