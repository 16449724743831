
// Value changes as page scrolls
export const getViewportBoundingBox = (element: HTMLElement) => {
  const { bottom, left, right, top } = element.getBoundingClientRect();
  return { bottom, left, right, top };
};

export const doesElementFullyContainChild = (parent: HTMLElement, child: HTMLElement) => {
  const parentBox = getViewportBoundingBox(parent);
  const childBox = getViewportBoundingBox(child);
  return (
    parentBox.bottom >= childBox.bottom &&
    parentBox.left <= childBox.left &&
    parentBox.right >= childBox.right &&
    parentBox.top <= childBox.top
  );
};

export const isInViewport = (elem: HTMLElement) => {
  if (!elem) {
    return false;
  }
  const distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0 && distance.left >= 0 &&
    distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

type IRectPositionCheckingBoundsParam = {
  /**
   * Target element
   */
  elem?: HTMLElement | null;
  /**
   * Margin to be added to the position if the element exceeds the viewport
   */
  margin?: number;
  /**
   * For fixed positioning
   */
  position?: { x: number; y: number };
};

type GetRectPositionResult = {
  /**
   * For absolute positioning
   */
  left: number;
  /**
   * For absolute positioning
   */
  top: number;
  /**
   * For fixed positioning
   */
  x: number;
  /**
   * For fixed positioning
   */
  y: number;
};

/**
 * @description Get the position of the element with respect to the viewport. If the element exceeds the viewport, adjust the position accordingly.
 * Works for both fixed and absolute positioning.
 * @example
 * For absolute positioning:
 * const rect = getRectPositionCheckingBounds({ elem: elemRef.current, margin: 10 });
 * elemRef.current.style.left = `${rect.left}px`;
 * elemRef.current.style.top = `${rect.top}px`;
 * <div ref={elemRef} style={{ position: 'absolute' }}></div>
 *
 *
 *
 * For fixed positioning:
 * const anchorElementRect = anchorElementRef.current.getBoundingClientRect();
 * const rect = getRectPositionCheckingBounds({ elem: elemRef.current, margin: 10, position: { x: anchorElementRect.x, y: anchorElementRect.y } });
 * elemRef.current.style.left = `${rect.x}px`;
 * elemRef.current.style.top = `${rect.y}px`;
 * <div ref={elemRef} style={{ position: 'fixed' }}></div>
 */
export const getRectPositionCheckingBounds = ({ elem, margin, position }: IRectPositionCheckingBoundsParam): GetRectPositionResult => {
  const safePos = position || { x: 0, y: 0 };
  const safeMargin = margin || 0;

  if (!elem) {
    return {
      x: safePos.x,
      y: safePos.y,
      left: 0,
      top: 0,
    };
  }

  const rect = elem.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;

  // <-- Fixed positioning logic -->
  const { x, y } = safePos;
  const passedX = x + rect.width + safeMargin > innerWidth;
  const passedY = y + rect.height + safeMargin > innerHeight;
  const newX = passedX ? (innerWidth - rect.width - safeMargin) : x;
  const newY = passedY ? (innerHeight - rect.height - safeMargin) : y;
  // <-- Fixed positioning logic -->


  // <-- Absolute positioning logic -->
  let adjustedLeft = 0;
  let adjustedTop = 0;

  if (rect.right + safeMargin > innerWidth) {
      adjustedLeft = -1 * (rect.right + safeMargin - innerWidth);
  }

  // Check if the actual element exceeds the viewport height
  if (rect.bottom + safeMargin > innerHeight) {
      adjustedTop = -1 * (rect.bottom + safeMargin - innerHeight);
  }
  // <-- Absolute positioning logic -->


  return {
    /**
     * For fixed positioning
     */
    x: newX,
    /**
     * For fixed positioning
     */
    y: newY,

    /**
     * For absolute positioning
     */
    left: adjustedLeft,
    /**
     * For absolute positioning
     */
    top: adjustedTop,
  };
};

export const matchMedia = window.matchMedia || (() => {
  // For browsers that support matchMedium api such as IE 9 and webkit
  // @ts-ignore
  let styleMedia = (window.styleMedia || window.media);

  // For those that don't support matchMedium
  if (!styleMedia) {
    const style = document.createElement('style');
    const script = document.getElementsByTagName('script')[0];
    let info: CSSStyleDeclaration;

    style.type = 'text/css';
    style.id = 'matchmediajs-test';

    if (!script) {
      document.head.appendChild(style);
    } else {
      // @ts-ignore
      script.parentNode.insertBefore(style, script);
    }

    // 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
    // @ts-ignore
    info = ('getComputedStyle' in window) ? window.getComputedStyle(style, null) : style.currentStyle;

    // @ts-ignore
    styleMedia = {
      matchMedium(media) {
        const text = `@media ${media}{ #matchmediajs-test { width: 1px; } }`;

        // 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
        // @ts-ignore
        if (style.styleSheet) {
          // @ts-ignore
          style.styleSheet.cssText = text;
        } else {
          style.textContent = text;
        }

        // Test if media query is true or false
        return info.width === '1px';
      },
    };
  }

  return (media: string) => {
    return {
      matches: styleMedia.matchMedium(media || 'all'),
      media: media || 'all',
    };
  };
})();

export const FULLSCREEN_VIDEO_ID = 'fullscreen-video-id';

export const hasAirplaySupport = !!window.WebKitPlaybackTargetAvailabilityEvent;
