import useDispatch from './use-dispatch';

const useAnalytics = (trackingAction) => {
  // TODO view analytic
  const dispatch = useDispatch();
  const onEngage = (...args) => dispatch(trackingAction('engage', ...args));
  return [onEngage];
};

export default useAnalytics;
