import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  FC,
  ReactNode,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFloatMenu from 'hooks/use-float-menu';
import {
  getPageViewType,
} from 'services/app';
import {
  getEcommerceView,
  getProductDetail,
} from 'services/ecommerce/selectors';
import {
  getShopifyBlockPanel,
} from 'services/user-layout/selectors';
import { PageType } from 'models';
import { openEcommerceChannelCheckoutView, pushEcommerceView } from 'services/ecommerce';
import { isMobileLayout } from 'services/device';

type FloatingCartButtonState = {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  handleClick: () => void;
  isOpen: boolean;
  positionMenu: (menu: HTMLDivElement | null) => void;
};

const useFloatingCartButton = () => {
  const dispatch = useDispatch();
  const ecommerceView = useSelector(getEcommerceView);
  const pageType = useSelector(getPageViewType);
  const productDetail = useSelector(getProductDetail);
  const shopifyBlockPanel = useSelector(getShopifyBlockPanel);
  const isMobile = useSelector(isMobileLayout);
  const isLanding = pageType === PageType.LANDING;
  const isChannel = pageType === PageType.CHANNEL;

  const setMenuPosition = useCallback((menu: HTMLDivElement, menuContainer: HTMLDivElement) => {
    menu.style.right = '15px';
    menu.style.position = 'fixed';
    if (!isMobile) {
      menu.style.top = `${menuContainer.getBoundingClientRect().y + menuContainer.getBoundingClientRect().height + 5}px`;
    }
  }, [isMobile]);

  const { isOpen, containerRef, positionMenu, toggleMenu: toggleLandingPageFloatCheckoutMenu } = useFloatMenu(setMenuPosition);

  useEffect(() => {
    if (ecommerceView === 'productDetail' && isLanding) {
      toggleLandingPageFloatCheckoutMenu();
    }
  }, [ecommerceView, toggleLandingPageFloatCheckoutMenu, isLanding, productDetail]);

  const handleOpenChannelCheckoutMenu = useCallback(() => {
    dispatch(openEcommerceChannelCheckoutView());
  }, [dispatch, openEcommerceChannelCheckoutView]);

  const handleClick = useCallback(() => {
    if (isChannel) {
      return handleOpenChannelCheckoutMenu();
    }

    if (isLanding) {
      return toggleLandingPageFloatCheckoutMenu();
    }
  }, [isChannel, isLanding, handleOpenChannelCheckoutMenu, toggleLandingPageFloatCheckoutMenu]);

  return { containerRef, handleClick, isOpen, positionMenu };
};

const FloatingCartButtonContext = createContext<FloatingCartButtonState | null>(null);

export const FloatingCartButtonProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const floatingCartButtonState = useFloatingCartButton();

  return (
    <FloatingCartButtonContext.Provider value={floatingCartButtonState}>
      {children}
    </FloatingCartButtonContext.Provider>
  );
};

export const useFloatingCartButtonContext = () => {
  const context = useContext(FloatingCartButtonContext);

  if (!context) {
    throw new Error('useFloatingCartButtonContext must be used within a FloatingCartButtonProvider');
  }

  return context;
};
