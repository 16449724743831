import axios from 'axios';
import { GATE_SERVICE_BASE_URL } from 'config';
import hash from 'json-stable-stringify';
import isEqual from 'lodash/isEqual';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveSiteOverlays,
  getActiveSitePanels,
  getActiveSiteQuestActions,
  getSiteId,
} from 'services/app/selectors';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { showAdminErrorModal } from 'services/modals/actions';
import { camelify } from 'shared/string-utils';
import { getPrimaryToken } from '../services/auth';

export interface IState {
  featureMap: Record<string, boolean>;
  overlayMap: Record<string, boolean>;
  panelMap: Record<string, boolean>;
  questActionMap: Record<string, boolean>;
}

type Action = 'feature' | 'overlay' | 'panel' | 'questAction';
interface IAction {
  payload: {
    features: string[];
    value: boolean;
  };
  type: Action;
}

const constructMap = (action: IAction): Record<string, boolean> => (
  action.payload.features.reduce((obj, feature) => ({
    ...obj,
    [feature]: action.payload.value,
  }), {})
);

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'feature': {
      return {
        ...state,
        featureMap: {
          ...state.featureMap,
          ...constructMap(action),
        },
      };
    }
    case 'overlay': {
      return {
        ...state,
        overlayMap: {
          ...state.overlayMap,
          ...constructMap(action),
        },
      };
    }
    case 'panel': {
      return {
        ...state,
        panelMap: {
          ...state.panelMap,
          ...constructMap(action),
        },
      };
    }
    case 'questAction': {
      return {
        ...state,
        questActionMap: {
          ...state.questActionMap,
          ...constructMap(action),
        },
      };
    }
    default: {
      // tslint:disable-next-line no-console
      console.error(`unrecognized action: ${JSON.stringify(action)}`);
      return state;
    }
  }
};

const useFeatureGate = () => {
  const token = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const featureMap = useSelector(getActiveSiteFeatures, isEqual);
  const panelMap = useSelector(getActiveSitePanels, isEqual);
  const overlayMap = useSelector(getActiveSiteOverlays, isEqual);
  const questActionMap = useSelector(getActiveSiteQuestActions, isEqual);
  const globalDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, {
    featureMap,
    overlayMap,
    panelMap,
    questActionMap,
  });

  const noUpdate = isEqual(featureMap, state.featureMap) &&
    isEqual(overlayMap, state.overlayMap) &&
    isEqual(questActionMap, state.questActionMap) &&
    isEqual(panelMap, state.panelMap);

  useEffect(() => {
    const gateEndpoint = `${GATE_SERVICE_BASE_URL}/${siteId}`;
    if (noUpdate) {
      return;
    }

    axios({
      data: {
        ...state.featureMap,
        overlays: state.overlayMap,
        panels: camelify(state.panelMap),
        questActions: camelify(state.questActionMap),
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': siteId,
      },
      method: 'post',
      url: gateEndpoint,
    }).catch((error) => {
      globalDispatch(showAdminErrorModal('Error updating site features'));
      // tslint:disable no-console
      console.error('Error updating site features:');
      console.error(error);
      // tslint:enable no-console
    });
  }, [
    hash(state),
    token,
    siteId,
  ]);

  const setFeatures = (action: Action, features: string[], value: boolean) => (
    dispatch({
      payload: {
        features,
        value,
      },
      type: action,
    })
  );

  return [state, setFeatures] as const;
};

export default useFeatureGate;
