import { useSelector } from 'react-redux';
import { IObject, PageType } from 'models';
import { getObject } from 'services/app';
import { DynamicTranslationType, TEndUserTranslator, useAdminTranslation, useEndUserTranslation } from 'hooks/use-translation';

/**
 * returns a tuple containing
 *
 * `[localizedString, baseLanguageString]`
 */
export const useLocalizedPageOrChannelTitle = (
  /** if *falsy*, defaults to the current channel or page */
  pageOrChannel?: IObject | null,
): [string, string] => {
  const { t } = useAdminTranslation();
  const { endUserT } = useEndUserTranslation();

  const currentPage = useSelector(getObject);
  const page = pageOrChannel || currentPage;

  const loadingText = t('ADMIN_IMAGE_LIBRARY_LOADING');
  const fallbackText = t('BRAND_NAME');

  if (!page) {
    return [loadingText, loadingText];
  }

  const objectTitle = page?.data?.name || page?.seo?.title;

  const transObjectTitle = (
    page && translatePageOrChannelTitle(
      endUserT,
      page,
    )
  ) || objectTitle;

  if (transObjectTitle) return [transObjectTitle, objectTitle!];

  return [fallbackText, fallbackText];
};

const translatePageOrChannelTitle = (t: TEndUserTranslator, channelOrPage: IObject) => {
  if (channelOrPage.type === PageType.CHANNEL) {
    return t([
      DynamicTranslationType.channelTitle,
      { channelId: channelOrPage._id },
    ]);
  }

  return t([
    DynamicTranslationType.pageTitle,
    { pageId: channelOrPage._id },
  ]);
};
