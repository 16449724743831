import { useMemo } from 'react';
import range from 'lodash/range';
import hash from 'json-stable-stringify';
import moment, { Moment } from 'moment';
import { useAdminTranslation } from './use-translation';

function daysInMonth(m: number, y: number) {
  return moment([y, m]).daysInMonth();
}

const shortWeekDays = [
  'Su',
  'Mo',
  'Tu',
  'We',
  'Th',
  'Fr',
  'Sa',
] as const;
const longWeekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

export const SHORT_WEEK_DAY_TRANSLATIONS = {
  'Su': 'LABEL_SHORT_SUNDAY',
  'Mo': 'LABEL_SHORT_MONDAY',
  'Tu': 'LABEL_SHORT_TUESDAY',
  'We': 'LABEL_SHORT_WEDNESDAY',
  'Th': 'LABEL_SHORT_THURSDAY',
  'Fr': 'LABEL_SHORT_FRIDAY',
  'Sa': 'LABEL_SHORT_SATURDAY',
} as const;
export const WEEK_DAY_TRANSLATIONS = {
  'Su': 'LABEL_SUNDAY',
  'Mo': 'LABEL_MONDAY',
  'Tu': 'LABEL_TUESDAY',
  'We': 'LABEL_WEDNESDAY',
  'Th': 'LABEL_THURSDAY',
  'Fr': 'LABEL_FRIDAY',
  'Sa': 'LABEL_SATURDAY',
} as const;

export const monthsShort = [
  'LABEL_SHORT_JANUARY',
  'LABEL_SHORT_FEBRUARY',
  'LABEL_SHORT_MARCH',
  'LABEL_SHORT_APRIL',
  'LABEL_SHORT_MAY',
  'LABEL_SHORT_JUNE',
  'LABEL_SHORT_JULY',
  'LABEL_SHORT_AUGUST',
  'LABEL_SHORT_SEPTEMBER',
  'LABEL_SHORT_OCTOBER',
  'LABEL_SHORT_NOVEMBER',
  'LABEL_SHORT_DECEMBER',
] as const;
const monthsLong = [
  'LABEL_JANUARY',
  'LABEL_FEBRUARY',
  'LABEL_MARCH',
  'LABEL_APRIL',
  'LABEL_MAY',
  'LABEL_JUNE',
  'LABEL_JULY',
  'LABEL_AUGUST',
  'LABEL_SEPTEMBER',
  'LABEL_OCTOBER',
  'LABEL_NOVEMBER',
  'LABEL_DECEMBER',
] as const;

export type WeekDay = typeof shortWeekDays[number];
export type WeekDayLong = typeof longWeekDays[number];

export interface DateData {
  day: number;
  isDisabled?: boolean;
  isSelected?: boolean;
  month: number;
  year: number;
}

export interface UseCalendarProps {
  month: number;
  selectedDate?: DateData;
  startingWeekDay?: WeekDay;
  year: number;
  isOutsideRange?(date: Moment): boolean;
}

export default function useCalendar({
  isOutsideRange = (date: Moment) => {
    const today = moment().startOf('day');
    return date < today;
  },
  month,
  selectedDate,
  startingWeekDay = 'Su',
  year,
}: UseCalendarProps) {
  const [prevMonth, nextMonth] = useMemo(() => ([
    month - 1 < 0 ? 11 : month - 1,
    month + 1 > 11 ? 0 : month + 1,
  ]), [month]);
  const [prevYear, nextYear] = useMemo(() => ([
    month - 1 < 0 ? year - 1 : year,
    month + 1 > 11 ? year + 1 : year,
  ]), [month, year]);

  const startingWeekDayIndex = useMemo(() => (
    shortWeekDays.findIndex(weekDay => startingWeekDay === weekDay)
  ), [startingWeekDay]);

  const orderedWeekDays = useMemo(() => ([
    startingWeekDay,
    ...shortWeekDays.slice(startingWeekDayIndex + 1),
    ...shortWeekDays.slice(0, startingWeekDayIndex),
  ]), [startingWeekDayIndex]);

  const [
    prevMonthDayCount,
    currMonthDayCount,
    nextMonthDayCount,
    firstDayOfTheMonth,
  ] = useMemo(() => {
    const firstDay = new Date(year, month, 1).getDay() - startingWeekDayIndex;
    return [
      daysInMonth(prevMonth, prevYear),
      daysInMonth(month, year),
      daysInMonth(nextMonth, nextYear),
      firstDay < 0 ? 6 : firstDay,
    ];
  }, [prevMonth, month, nextMonth, prevYear, year, nextYear]);
  const { t } = useAdminTranslation();

  const visibleDays = useMemo(() => {
    const prevMonthVisibleDays = firstDayOfTheMonth ? range(prevMonthDayCount - firstDayOfTheMonth + 1, prevMonthDayCount + 1) : [];
    const currMonthDays = range(1, currMonthDayCount + 1);
    const nextMonthVisibleDays = range(1, 43 - prevMonthVisibleDays.length - currMonthDays.length);

    const days: DateData[] = [
      ...prevMonthVisibleDays.map((day) => ({ day, isDisabled: isOutsideRange(moment([prevYear, prevMonth, day])), month: prevMonth, year: prevYear })),
      ...currMonthDays.map((day) => ({ day, isDisabled: isOutsideRange(moment([year, month, day])), month, year })),
      ...nextMonthVisibleDays.map((day) => ({ day, isDisabled: isOutsideRange(moment([nextYear, nextMonth, day])), month: nextMonth, year: nextYear })),
    ];

    if (!selectedDate) {
      return days;
    }
    const selectedIndex = days.findIndex(({ day: currDay, month: currMonth, year: currYear }) => (
      selectedDate.day === currDay &&
      selectedDate.month === currMonth &&
      selectedDate.year === currYear
    ));

    if (selectedIndex < 0) {
      return days;
    }
    days[selectedIndex].isSelected = true;
    return days;
  }, [
    isOutsideRange,
    prevMonthDayCount,
    currMonthDayCount,
    nextMonthDayCount,
    month,
    nextMonth,
    prevMonth,
    year,
    nextYear,
    prevYear,
    firstDayOfTheMonth,
    hash(selectedDate),
  ]);

  const visibleDaysMatrix = useMemo(() => {
    return visibleDays.reduce((acc: DateData[][], day, index) => {
      if (index % 7 === 0) {
        acc.push([]);
      }

      acc[acc.length - 1].push({
        day: day.day,
        month: day.month,
        year: day.year,
      });

      return acc;
    }, [] as DateData[][]);
  }, [visibleDays]);

  return {
    monthsLong: monthsLong.map(m => t(m)),
    monthsShort: monthsShort.map(m => t(m)),
    visibleDays,
    visibleDaysMatrix,
    weekDays: orderedWeekDays.map(d => t(SHORT_WEEK_DAY_TRANSLATIONS[d])),
    weekDaysLong: orderedWeekDays.map(d => t(WEEK_DAY_TRANSLATIONS[d])),
  };
}
