import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeNotification,
  sendMediumPriorityNotification,
} from 'services/admin-notifications/actions';
import { ADMIN_TEXT_100 } from 'style/constants';
import { getObject, isPagePublic } from 'services/app/selectors';
import { getMediumPriorityNotifications } from 'services/admin-notifications/selectors';

export default function usePrivatePageNotifications() {
  const dispatch = useDispatch();
  const isPublic = useSelector(isPagePublic);
  const page = useSelector(getObject);
  const notifications = useSelector(getMediumPriorityNotifications);

  useEffect(() => {
    const privatePageNotification = notifications.find(
      (notification) => notification.message.textKey === 'ADMIN_PRIVATE_PAGE_NAVBAR_WARNING',
    );
    if (privatePageNotification) {
      if (isPublic) {
        dispatch(removeNotification({
          id: privatePageNotification.id,
          priority: privatePageNotification.priority,
        }));
        return; // if it's showing a privatePageNotification and navigated to a public page, remove it
      }
      return; // if it's showing a privatePageNotification and navigated to a private page, keep it and dont show another
    } else {
      if (isPublic) {
        return; // if it's not showing a privatePageNotification and navigated to a public page, do nothing
      }
    }

    dispatch(sendMediumPriorityNotification({
      buttons: [
        {
          textKey: 'OK',
          buttonCss: 'margin-left: 15px;',
        },
        {
          background: 'transparent',
          buttonCss: 'padding: 0 10px;',
          icon: 'cancel',
          text: '',
          textColor: ADMIN_TEXT_100,
        },
      ],
      notificationTextKey: 'ADMIN_PRIVATE_PAGE_NAVBAR_WARNING',
    }));
  }, [isPublic, page._id]);
}
