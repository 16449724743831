import { IProduct, IProductVariant, IVariant, SelectedOption } from 'components/Ecommerce/models';
import React, { useMemo, useState } from 'react';

interface IUseProductVariantResult {
  productVariant: IProductVariant | null;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * Custom hook that encapsulates logic to determine the current selected variant of a product based on selected options.
 *
 * @function
 * @param {IProduct} product - The product object with its variants and associated options.
 * @param {SelectedOption[]} selectedOptions - The options selected by the user (e.g., red color, medium size, etc.).
 * @returns {IUseProductVariantResult} An object containing the following:
 * - productVariant: A combination of product and selected variant data into one simple object.
 * - setQuantity: A setter function to adjust the quantity of the product variant.
 */

export const useProductVariant = (
  product: IProduct, selectedOptions: SelectedOption[], selectedVariant: IVariant | null,
): IUseProductVariantResult => {
  const [quantity, setQuantity] = useState(product.quantity);

  const productVariant = useMemo(() => {
    if (!selectedVariant) {
      return null;
    }

    return {
      productId: product.id,
      name: product.name,
      description: product.description,
      quantity,
      variantId: selectedVariant.id,
      variantTitle: selectedVariant.title,
      price: selectedVariant.price,
      requiresShipping: selectedVariant.requiresShipping,
      selectedOptions: selectedVariant.selectedOptions,
      availableForSale: selectedVariant.availableForSale,
      images: selectedVariant.images || product.images || null,
    } as IProductVariant;
  }, [product, selectedOptions, selectedVariant, quantity, product.quantity]);

  return { productVariant, setQuantity };
};

export const getSelectedVariant = (variants: IProduct['variants'], selectedOptions: SelectedOption[]) => {
  const isSingleVariant = variants.length === 1;
  if (isSingleVariant) {
    return variants[0]; // return the only variant
  }

  return variants.find(variant => {
    return [...variant.selectedOptions].every(selOption => {
      const selectedOption = selectedOptions.find(option => option.type === selOption.type);
      return selectedOption?.value.name === selOption.value.name;
    });
  }) || null;
};
