import React from 'react';

const useQuestLifespan = (timeMinimum?: number | null, timeMaximum?: number | null) => {
  const [expiresIn, setExpiresIn] = React.useState('');
  const [availableIn, setAvailableIn] = React.useState('');
  const [expiredIn, setExpiredIn] = React.useState('');

  React.useEffect(() => {
    if (!timeMaximum) {
      setExpiresIn('');
      return;
    }

    const maxDate = new Date(timeMaximum);
    const now = Date.now();
    if (now > maxDate.getTime()) {
      setExpiresIn('');
      return;
    }

    const diff = maxDate.getTime() - Date.now();
    if (diff <= 0) {
      setExpiresIn('');
      return;
    }

    const daysInterval = 86400000;
    const minutesInterval = 60000;
    const secondsInterval = 1000;
    const getIntervalMs = () => {
      if (diff > daysInterval) {
        return daysInterval;
      }

      if (diff > (minutesInterval * 5)) {
        return minutesInterval;
      }

      return secondsInterval;
    };

    const updateExpiration = () => {
      const newDiff = maxDate.getTime() - Date.now();
      if (newDiff <= 0) {
        setExpiresIn('');
        clearInterval(interval);
        return;
      }

      const days = Math.floor(newDiff / 86400000);
      const hours = String(Math.floor(newDiff / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((newDiff % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((newDiff % 60000) / 1000)).padStart(2, '0');

      if (newDiff > daysInterval) {
        setExpiresIn(`Expires in ${days} ${days === 1 ? 'day' : 'days'}`);
        return;
      }

      if (newDiff > minutesInterval) {
        setExpiresIn(`Expires in ${hours}h ${minutes}m`);
        return;
      }

      setExpiresIn(`Expires in ${hours}:${minutes}:${seconds}`);
    };

    updateExpiration();
    const interval = setInterval(() => {
      updateExpiration();
    }, getIntervalMs());

    return () => {
      clearInterval(interval);
    };
  }, [timeMaximum]);

  // handle 'Expired 12 mins ago and relatives
  React.useEffect(() => {
    if (!timeMaximum) {
      setExpiredIn('');
      return;
    }

    const maxDate = new Date(timeMaximum);
    const now = Date.now();
    if (now <= maxDate.getTime()) {
      setExpiredIn('');
      return;
    }

    const diff = Date.now() - maxDate.getTime();
    if (diff <= 0) {
      setExpiredIn('');
      return;
    }

    const daysInterval = 86400000;
    const minutesInterval = 60000;
    const secondsInterval = 1000;
    const getIntervalMs = () => {
      if (diff > daysInterval) {
        return daysInterval;
      }

      if (diff > (minutesInterval * 5)) {
        return minutesInterval;
      }

      return secondsInterval;
    };

    const updatedExpired = () => {
      const newDiff = Date.now() - maxDate.getTime();
      if (newDiff <= 0) {
        setExpiredIn('');
        clearInterval(interval);
        return;
      }

      const days = Math.floor(newDiff / 86400000);
      const hours = String(Math.floor(newDiff / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((newDiff % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((newDiff % 60000) / 1000)).padStart(2, '0');

      if (newDiff > daysInterval) {
        setExpiredIn(`Expired ${days} ${days === 1 ? 'day' : 'days'} ago`);
        return;
      }

      if (newDiff > minutesInterval) {
        setExpiredIn(`Expired ${hours}h ${minutes}m ago`);
        return;
      }

      setExpiredIn(`Expired ${hours}:${minutes}:${seconds} ago`);
    };

    updatedExpired();
    const interval = setInterval(() => {
      updatedExpired();
    }, getIntervalMs());

    return () => {
      clearInterval(interval);
    };
  }, [timeMaximum]);

  React.useEffect(() => {
    if (!timeMinimum) {
      setAvailableIn('');
      return;
    }

    const minDate = new Date(timeMinimum);
    const now = Date.now();
    if (now > minDate.getTime()) {
      setAvailableIn('');
      return;
    }

    const diff = minDate.getTime() - Date.now();
    if (diff <= 0) {
      setAvailableIn('');
      return;
    }

    const daysInterval = 86400000;
    const minutesInterval = 60000;
    const secondsInterval = 1000;
    const getIntervalMs = () => {
      if (diff > daysInterval) {
        return daysInterval;
      }

      if (diff > (minutesInterval * 5)) {
        return minutesInterval;
      }

      return secondsInterval;
    };

    const updateAvailability = () => {
      const newDiff = minDate.getTime() - Date.now();
      if (newDiff <= 0) {
        setAvailableIn('');
        clearInterval(interval);
        return;
      }

      const days = Math.floor(newDiff / 86400000);
      const hours = String(Math.floor(newDiff / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((newDiff % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((newDiff % 60000) / 1000)).padStart(2, '0');

      if (newDiff > daysInterval) {
        setAvailableIn(`Available in ${days} ${days === 1 ? 'day' : 'days'}`);
        return;
      }

      if (newDiff > minutesInterval) {
        setAvailableIn(`Available in ${hours}h ${minutes}m`);
        return;
      }

      setAvailableIn(`Available in ${hours}:${minutes}:${seconds}`);
    };

    updateAvailability();
    const interval = setInterval(() => {
      updateAvailability();
    }, getIntervalMs());

    return () => {
      clearInterval(interval);
    };
  }, [timeMinimum]);

  return {
    expiresIn,
    availableIn,
    expiredIn,
  };
};

export default useQuestLifespan;
