export const WITH_PADDING = 'with-padding';

export const DROPDOWN_LABEL = 'dropdown-label';
export const DROPDOWN_MENU = 'dropdown-menu';
export const DROPDOWN_TITLE = 'dropdown-title';
export const DROPDOWN_BUTTON = 'dropdown-button';
export const DROPDOWN_CONTAINER = 'dropdown-container';

export const CALENDAR_WRAPPER = 'calendar-wrapper';
export const TIME_INPUT = 'time-input';
export const TIME_INPUT_ITEM = 'time-input-item';
export const SUGGESTION_CONTAINER = 'suggestion-container';

export const MEDIA_LIBRARY_TABLE_THEAD = 'media-library-table-thead';
export const MEDIA_LIBRARY_TABLE_TBODY = 'media-library-table-tbody';
