import { joinKeySegments } from 'firebase-utils';
import isEqual from 'lodash/isEqual';
import hash from 'json-stable-stringify';
import { useEffect, useMemo } from 'react';
import { getDocument, isDocumentLoaded, subscribe, unsubscribe } from 'services/realtime';
import useDispatch from './use-dispatch';
import useSelector from './use-selector';

export interface IParams {
  collection: string;
  idField?: string;
  items: Record<string, any>[]
  keyField?: string;
}

interface IRef {
  id: string | null,
  index: number;
  key: string;
  path: string | null
}

interface IRealtimeDoc<T> {
  data: T | null,
  id: string | null,
  index: number,
  key: string;
  loaded: boolean
}

type LoadedDocument<T> = T & { id: string | null };

export interface IRealtimeDocumentList {
  loadedDocuments: LoadedDocument<unknown>[];
  rawData: IRealtimeDoc<unknown>[];
}

const useRealtimeDocumentList = <T>({
  collection,
  idField = 'id',
  keyField = 'id',
  items,
}: IParams): IRealtimeDocumentList => {
  const dispatch = useDispatch();
  const refs = useMemo<IRef[]>(() => (
    (items || []).map((item, index) => {
      const id = item[idField] || null;
      const key = item[keyField] || null;
      const path = (id && joinKeySegments([collection, id])) || null;

      return {
        id,
        index,
        key,
        path,
      };
    })
  ), [collection, hash(items)]);

  useEffect(() => {
    for (const { path } of refs) {
      if (path) {
        dispatch(subscribe(path));
      }
    }
    return () => {
      for (const { path } of refs) {
        if (path) {
          dispatch(unsubscribe(path));
        }
      }
    };
  }, [hash(refs)]);

  const rawData = useSelector<IRealtimeDoc<T>[]>((state) => (
    refs.map(({ id, index, key, path }) => {
      if (!path) {
        return { data: null, id, index, key, loaded: false };
      }
      return {
        data: path && id && getDocument(state, collection, id) || null,
        id,
        index,
        key,
        loaded: Boolean(path && id && isDocumentLoaded(state, collection, id)),
      };
    })
  ), isEqual);

  const result = useMemo(() => {
    const loadedDocuments: LoadedDocument<unknown>[] = rawData.map(({ data, id }) => ({
      id,
      ...data,
    }));
    return {
      rawData,
      loadedDocuments,
    };
  }, [hash(rawData)]);

  return result;
};

export default useRealtimeDocumentList;
