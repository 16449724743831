import { useState, useCallback, SyntheticEvent, useMemo } from 'react';
import { useCachedCallback } from './use-cached-callback';

export const validator = <T>(fn: (v: any) => boolean) =>
  (v: any): v is T => fn(v);

export const validateNonEmptyString = validator<string>(
  (v) => (
    typeof v === 'string'
      && Boolean(v.trim())
  ),
);

export const validateUrl = validator<string>(
  (v) => {
    try {
      return Boolean(new URL(v));
    } catch {
      return false;
    }
  },
);

export const validateStringArray = validator<string[]>(
  (v) => {
    if (!Array.isArray(v))
      return false;

    for (const item of v) {
      if (!validateNonEmptyString(item))
        return false;
    }

    return true;
  },
);

type Field<R extends boolean, T> = {
  emptyValues: T[];
  required: R;
  type: T;
  validate?: (value: any) => value is T;
};

export const formField = <R extends boolean, T>(
  required: R,
  emptyValues: T[],
  validate?: Field<R, T>['validate'],
): Field<R, T> => ({
  emptyValues,
  required,
  type: undefined as any as T,
  validate,
});

type FormData<X> = {
  [K in keyof X]: X[K] extends { required: any; type: any } ?
    X[K]['required'] extends true
      ? X[K]['type']
      : X[K]['type'] | undefined
    : never;
};

export const useSimpleForm = <
  FormFields extends Record<
    string,
    Field<any, any>
  >,
>(fields: FormFields, defaultValues: Partial<FormData<FormFields>> = {}) => {
  const [formValues, setFormValues] = useState(
    (() => {
      const initialData = {} as any as FormData<FormFields>;

      for (const k in fields) {
        if (!fields.hasOwnProperty(k))
          continue;

        const key = k as keyof FormData<FormFields>;

        if (key in defaultValues) {
          initialData[key] = defaultValues[key] as any;
        } else {
          initialData[key] = fields[key].emptyValues[0] as any;
        }
      }

      return initialData;
    })(),
  );

  const handleChange = useCachedCallback(
    useCallback(
      <K extends keyof FormData<FormFields>>(key: K) => {
        const update = (value: FormData<FormFields>) =>
          setFormValues(
            (prevValues) => ({
              ...prevValues,
              [key]: value,
            }),
          );

        return {
          rawValue: update,
          eventHandler: (ev: SyntheticEvent<{ value: any }>) => {
            update(ev.currentTarget.value);
          },
        };
      },
      [],
    ),
  );

  const changeHandlers = useMemo(
    () => new Proxy(
      {} as {
        [K in keyof FormData<FormFields>]: {
          eventHandler: (ev: SyntheticEvent<{ value: any }>) => void;
          rawValue: (value: FormData<FormFields>[K]) => void;
        }
      },
      {
        get(_, property) {
          return handleChange(property as any);
        },
      },
    ),
    [handleChange],
  );

  return [formValues, changeHandlers] as const;
};
