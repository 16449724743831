// tslint:disable jsdoc-format
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const useGlobalVideoState = () => {
  const [muted, setMuted] = useState(true);
  const [shareId, setShareId] = useState<string>();

  return {
    muted,
    setMuted,
    shareId,
    setShareId,
  };
};

const GlobalVideoContext = createContext(
  null as any as ReturnType<typeof useGlobalVideoState>,
);

/**
 * # Use with caution! Most data doesn't really need to be global
 *
 * This context holds only the very minimal global information related
 * to videos (ie: mute state and id to be shared on socials)
 **/
export const useGlobalVideoContext = () => useContext(GlobalVideoContext);

export const useSetShareVideoId = (videoId: string | null | undefined) => {
  const { setShareId } = useGlobalVideoContext();

  useEffect(() => {
    setShareId(videoId ?? undefined);
  }, [setShareId, videoId]);

  useEffect(() => {
    return () => {
      setShareId(undefined);
    };
  }, []);
};

export const GlobalVideoContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <GlobalVideoContext.Provider value={useGlobalVideoState()}>
      {children}
    </GlobalVideoContext.Provider>
  );
};
