import { useCallback, useRef } from 'react';

export default function useCooldown(cooldownDurationMs: number) {
  const cooldown = useRef(performance.now());

  const resetCooldown = useCallback(() => {
    cooldown.current = performance.now();
  }, []);

  const finishCooldown = useCallback(() => {
    cooldown.current -= cooldownDurationMs;
  }, [cooldownDurationMs]);

  const isCooldownFinished = useCallback(() => {
    const isFinished = performance.now() - cooldown.current > cooldownDurationMs;
    cooldown.current = performance.now();
    return isFinished;
  }, [cooldownDurationMs]);

  return {
    cooldown,
    isCooldownFinished,
    finishCooldown,
    resetCooldown,
  };
}
