import { useEffect } from 'react';

const useDebounceWithFunction = <T>(callback: () => void, value: T, delay: number) => {
  useEffect(
    () => {
      const typingTimer = setTimeout(callback, delay);

      return () => clearTimeout(typingTimer);
    },
    [delay, value],
  );
};

export default useDebounceWithFunction;
