import { joinKeySegments } from 'firebase-utils';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { getDocument, isDocumentLoaded, subscribe, unsubscribe } from 'services/realtime';
import useDispatch from './use-dispatch';
import useSelector from './use-selector';

const useRealtimeDocument = <T>(collection: string, id: string | null | undefined): [T | null, boolean] => {
  const path = useMemo(
    () => (id && joinKeySegments([collection, id])) || null,
    [collection, id],
  );

  const dispatch = useDispatch();

  useEffect(
    () => {
      if (!path) {
        return;
      }

      dispatch(subscribe(path));
      return () => {
        dispatch(unsubscribe(path));
        return;
      };
    },
    [path],
  );

  const loaded = useSelector((state) => (
    path && id && isDocumentLoaded(state, collection, id)
  ), isEqual);
  const doc = useSelector((state) => (
    path && id && getDocument(state, collection, id)
  ), isEqual);

  return [doc, Boolean(loaded)];
};

export default useRealtimeDocument;
