import { createObjectId } from 'utils';
import { CardType } from 'components/objects/PanelV2/constants';
import { injectT, TTranslator } from 'hooks/use-translation';
import { lazy } from 'utils/lazy';

export default function* createDefaultCard({
  cardType,
  id,
  siteId,
  t,
}: { cardType: CardType, id: string, siteId: string, t: TTranslator }) {
  const card = {
    collection: 'cards',
    renderer: {
      ...DEFAULT_RENDERER(t)[cardType],
      id,
    },
    type: 'card',
  };

  const objs = DEFAULT_OBJ(t);
  if (cardType in objs) {
    const _id = createObjectId();
    yield { ...objs[cardType as keyof typeof objs], _id, siteId };
    if ('pollId' in card.renderer) {
      card.renderer.pollId = _id;
    } else if ('personId' in card.renderer) {
      card.renderer.personId = _id;
    }
  }

  yield card;
}

// The keys here represent card types that are linked to a document in the objects collection so whenever we create a
// new card of these types (which should only happen for fresh sites with no cards) we also need to create that object
export const DEFAULT_OBJ = injectT(t => lazy(() => ({
  person: {
    'collection': 'people',
    'slug': 'tokimonsta',
    'type': 'person',
    'renderer': {
      'linkIconName': '',
      'linkText': '',
      'linkUrl': '',
      'personLinks': [
        {
          'linkIconName': 'twitter',
          'linkText': t('ICON_NAME_TWITTER'),
          'linkUrl': 'https://twitter.com/tokimonsta',
        },
        {
          'linkIconName': 'globe',
          'linkText': t('LABEL_WEBSITE'),
          'linkUrl': ' https://tokimonsta.com/',
        },
        {
          'linkIconName': 'instagram',
          'linkText': t('ICON_NAME_INSTAGRAM'),
          'linkUrl': 'https://www.instagram.com/tokimonsta',
        },
      ],
      'personBio': t('LABEL_DEFAULT_PERSON_BIO'),
      'personImageUrl': 'https://static.maestro.io/media/5c08126958dfae005af67972/5c086ad8430aa40069f6f1f7.jpg',
      'personName': t('LABEL_DEFAULT_PERSON_NAME'),
      'personTitle': t('LABEL_DEFAULT_PERSON_TITLE'),
    },
  },
  poll: {
    'collection': 'polls',
    'data': {
      'answer': '1',
      'kind': 'six_option_poll',
      'name': t('LABEL_DEFAULT_POLL_QUESTION'),
      'option_1': t('LABEL_DEFAULT_POLL_OPTION_1'),
      'option_1_image': '',
      'option_2': t('LABEL_DEFAULT_POLL_OPTION_2'),
      'option_2_image': '',
      'option_3': t('LABEL_DEFAULT_POLL_OPTION_3'),
      'option_4': t('LABEL_DEFAULT_POLL_OPTION_4'),
      'option_5': t('LABEL_DEFAULT_POLL_OPTION_5'),
      'option_6': t('LABEL_DEFAULT_POLL_OPTION_6'),
      'question': t('LABEL_DEFAULT_POLL_QUESTION'),
      'show_numbers': false,
      'title': t('LABEL_DEFAULT_POLL_QUESTION'),
    },
    'slug': 'who-is-your-favorite-tank-to-play',
    'type': 'poll',
  },
})));

export const DEFAULT_RENDERER = injectT(t => lazy(() => ({
  image: {
    cardType: 'image',
    imageUrl: 'https://static.maestro.io/media/5c08126958dfae005af67972/5c087265ce8b1b005b98e58d.png',
    title: t('LABEL_DEFAULT_IMAGE_CARD_TITLE'),
  },
  message: {
    cardType: 'message',
    ctaRenderer: {
      cardCtaType: 'facebook_share',
    },
    message: t('LABEL_DEFAULT_MESSAGE_CARD_MESSAGE'),
    title: t('LABEL_DEFAULT_MESSAGE_CARD_TITLE'),
  },
  person: {
    cardType: 'person',
    personId: '5c086b2d8eef42bdadaca3b8',
    title: t('LABEL_DEFAULT_PERSON_CARD_TITLE'),
  },
  poll: {
    cardType: 'poll',
    pollId: '5ab57cd90f63f6b110659b75',
    title: t('LABEL_DEFAULT_POLL_CARD_TITLE'),
  },
  tweet: {
    cardType: 'tweet',
    tweetUrl: 'https://twitter.com/Reuters/status/1070115536321753088',
  },
})));
