import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { interpolateTranslation } from 'services/localization/interpolate';
import { enumToLiteral } from 'utils/makeEnum';
import { useAdminTranslation } from './admin';
import { DynamicTranslator, TEndUserInterpolator, TEndUserTranslator } from './types';

const useDynamicT = () => {
  const { t, i18n } = useTranslation();

  const dynamicT = useCallback(
    ((type, params) => {
      const key = dynamicKeyMap[enumToLiteral(type)]?.(params as any);

      if (!key) {
        return `<${type}>`;
      }

      return t(
        [`endUser.${key}`, ''],
        (typeof params === 'object' && 'variables' in params)
          ? params.variables
          : {},
      );
    }) as DynamicTranslator,
    [t],
  );

  return {
    dynamicT,
    currentLocale: i18n.language,
  };
};

export const useEndUserTranslation = () => {
  const { t, i } = useAdminTranslation();
  const { dynamicT, currentLocale } = useDynamicT();

  /**
   * This is the main translation function that should be used for
   * end-user facing strings. There is scenarios that you might want
   * to fallback to the admin translation system if the end-user
   * translation system doesn't have a translation for a given string.
   *
   * This function can also be used to interpolate simple dynamic values, such as
   * strings, by passing an optional object of variables in endUserArgs.
   * If you need to pass in complex dynamic values with custom tags or components,
   * use 'endUserInterpolator' instead.
   *
   * @param endUserArgs - The arguments to pass to the dynamic
   * translation system.
   * @param adminFallback - The arguments to pass to the admin
   * translation system.
   * @returns The translated string.
   */
  const endUserT = useCallback(
    ((
      endUserArgs,
      adminFallback,
    ) => {
      const fallback = (
        adminFallback?.[0]
          ? t(adminFallback[0], adminFallback[1])
          : ''
      );

      return dynamicT(...endUserArgs) || fallback;
    }) as TEndUserTranslator,
    [t, dynamicT],
  );

  /**
   * This function is used to interpolate dynamic values with custom tags
   * or components into translated strings for end-user-facing content.
   * It also provides a fallback to admin translation if a translation
   * is not available in end-user.
   *
   * @param {Array} endUserArgs - An array containing the translation key and an
   * optional object of variables to interpolate into the translation.
   * @param {Array} adminFallback - An array containing the translation key and an
   * optional object of variables to use for the admin translation system fallback.
   *
   * @returns {Array|null} An array of React components representing the interpolated
   * translation, or null if the translation is not found.
   */
  const endUserInterpolator = useCallback(
    ((
      endUserArgs,
      adminFallback,
    ) => {
      const { variables, ...endUserArgsWithoutVariables } = endUserArgs[1] || {};
      // purposefully not passing the translation variables
      const rawStr = dynamicT(endUserArgs[0], endUserArgsWithoutVariables as any);

      if (rawStr) {
        return interpolateTranslation(rawStr, variables);
      }

      if (!adminFallback?.[0])
        return null;

      return i(adminFallback[0], adminFallback[1] ?? {} as any);
    }) as TEndUserInterpolator,
    [i, dynamicT],
  );

  return { endUserT, endUserInterpolator, currentLocale };
};

/**
 * Every single piece of interface that we localize (the title of a
 * page, the description of a page builder block, panel name, etc)
 * must be added to this object that maps a thing to its dynamic
 * localization id.
 *
 * This should match what's implemented in
 * [node-services](https://github.com/lessthan3/node-services/blob/master/packages/http-services/localization-http-v2/src/routes/localizationTemplate/index.ts)
 */
export const dynamicKeyMap = {
  channelTitle(
    p: {
      channelId: string;
    },
  ) {
    return `channel.${p.channelId}.title`;
  },
  imageAndTextBlockTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  imageAndTextBlockLabel(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.label`;
  },
  imageAndTextBlockDescription(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.description`;
  },
  imageAndTextBlockCta(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.cta`;
  },
  textBannerTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  textBannerLabel(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.label`;
  },
  textBannerSubtitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.subtitle`;
  },
  textBannerCta(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.cta`;
  },
  // TODO: look into abstracting common block logic
  faqBlockTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  faqBlockQuestion(
    p: {
      blockId: string;
      pageId: string;
      questionId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.question.${p.questionId}`;
  },
  faqBlockAnswer(
    p: {
      answerId: string;
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.answer.${p.answerId}`;
  },
  heroBannerTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  heroBannerDescription(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.description`;
  },
  heroBannerPrimaryCta(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.primaryCta`;
  },
  heroBannerSecondaryCta(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.secondaryCta`;
  },
  imageGalleryTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  imageGalleryImageCardTitle(
    p: {
      blockId: string;
      imageCardId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.imageCard.${p.imageCardId}.title`;
  },
  imageGalleryImageCardDescription(
    p: {
      blockId: string;
      imageCardId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.imageCard.${p.imageCardId}.description`;
  },
  imageGalleryImageCardLabel(
    p: {
      blockId: string;
      imageCardId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.imageCard.${p.imageCardId}.label`;
  },
  imageGalleryImageCardCta(
    p: {
      blockId: string;
      imageCardId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.imageCard.${p.imageCardId}.cta`;
  },
  playlistTitle(
    p: {
      playlistId: string;
    },
  ) {
    return `playlist.${p.playlistId}.title`;
  },
  pageRowTitle(
    p: {
      blockId: string;
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.block.${p.blockId}.title`;
  },
  videoTitle(
    p: {
      videoId: string;
    },
  ) {
    return `video.${p.videoId}.title`;
  },
  videoDescription(
    p: {
      videoId: string;
    },
  ) {
    return `video.${p.videoId}.description`;
  },
  videoTag(
    p: {
      tag: string;
    },
  ) {
    return `video.tag.${p.tag}`;
  },
  pageTitle(
    p: {
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.title`;
  },
  gateSubtitle(
    p: {
      pageId: string;
    },
  ) {
    return `page.${p.pageId}.gate.subtitle`;
  },
  gateTitle(
    p: {
      pageId: string;
    },
  ) {
    return `page.${p.pageId}.gate.title`;
  },
  gateAlreadyPurchasedQuestion() {
    return 'static.gate.alreadyPurchasedQuestion';
  },
  gateInvalidPassword() {
    return 'static.gate.invalidPassword';
  },
  gateHaveAnAccessCode() {
    return 'static.gate.haveAnAccessCode';
  },
  gateEnterCode() {
    return 'static.gate.enterCode';
  },
  gateInvalidAccessCode() {
    return 'static.gate.invalidAccessCode';
  },
  gateBoughtTicketDifferentAccount() {
    return 'static.gate.boughtTicketDifferentAccount';
  },
  gateSwitchAccount() {
    return 'static.gate.switchAccount';
  },
  globalActionLogIn() {
    return 'static.global.actionLogIn';
  },
  globalFormLabelEnterEmailAddr() {
    return 'static.global.formLabel.enterYourEmailAddress';
  },
  globalPlaceholderEmail() {
    return 'static.global.placeholder.email';
  },
  globalValidationInvalidEmail() {
    return 'static.global.validation.invalidEmail';
  },
  globalActionSubmit() {
    return 'static.global.actionSubmit';
  },
  globalActionBack() {
    return 'static.global.actionBack';
  },
  globalActionNext() {
    return 'static.global.actionNext';
  },
  globalActionOk() {
    return 'static.global.actionOk';
  },
  globalActionDone() {
    return 'static.global.actionDone';
  },
  globalEmail() {
    return 'static.global.email';
  },
  globalPassword() {
    return 'static.global.password';
  },
  globalFormLabelEnterPassword() {
    return 'static.global.formLabel.enterYourPassword';
  },
  globalFormLabelOr() {
    return 'static.global.formLabel.or';
  },
  globalValidationInvalidPassword() {
    return 'static.global.validation.invalidPassword';
  },
  loginScreenFormLabelForgotPassword() {
    return 'static.loginScreen.formLabel.forgotPassword';
  },
  loginScreenWelcomeBack(
    _: {
      variables: {
        userName: string;
      },
    },
  ) {
    return 'static.loginScreen.welcomeBack';
  },
  accountLookupTitle() {
    return 'static.accountLookup.title';
  },
  accountLookupDescription() {
    return 'static.accountLookup.description';
  },
  accountLookupNoMaestroAuthTitle() {
    return 'static.accountLookup.noMaestroAuthTitle';
  },
  accountLookupNoMaestroAuthDescription() {
    return 'static.accountLookup.noMaestroAuthDescription';
  },
  createAccountTitle() {
    return 'static.createAccount.title';
  },
  createAccountDescription() {
    return 'static.createAccount.description';
  },
  createAccountConfirmEmailPlaceholder() {
    return 'static.createAccount.confirmEmailPlaceholder';
  },
  createAccountConfirmEmailErrorLabel() {
    return 'static.createAccount.confirmEmailErrorLabel';
  },
  createAccountPasswordErrorLabel() {
    return 'static.createAccount.passwordErrorLabel';
  },
  createAccountUsernamePlaceholder() {
    return 'static.createAccount.usernamePlaceholder';
  },
  createAccountUsernameErrorLabel() {
    return 'static.createAccount.usernameErrorLabel';
  },
  createAccountSignupButton() {
    return 'static.createAccount.signupButton';
  },
  createAccountAgreeTermsOfUse() {
    return 'static.createAccount.agreeTermsOfUse';
  },
  createAccountAgreeMaestroTermsOfUse() {
    return 'static.createAccount.agreeMaestroTermsOfUse';
  },
  createAccountTermsOfUse() {
    return 'static.createAccount.termsOfUse';
  },
  createAccountAnd() {
    return 'static.createAccount.and';
  },
  createAccountPrivacyPolicy() {
    return 'static.createAccount.privacyPolicy';
  },
  forgotPasswordTitle() {
    return 'static.forgotPassword.title';
  },
  forgotPasswordDescription() {
    return 'static.forgotPassword.description';
  },
  checkEmailTitle() {
    return 'static.checkEmail.title';
  },
  checkEmailDescription() {
    return 'static.checkEmail.description';
  },
  resetPasswordDescription() {
    return 'static.resetPassword.description';
  },
  setPasswordSuccessDescription() {
    return 'static.setPasswordSuccess.description';
  },
  externalLoginWith(
    _: {
      variables: {
        provider: string;
      },
    },
  ) {
    return 'static.externalLogin.loginWith';
  },
  externalSignupWith(
    _: {
      variables: {
        provider: string;
      },
    },
  ) {
    return 'static.externalLogin.signupWith';
  },
  externalLoginOrSignup() {
    return 'static.externalLogin.loginOrSignup';
  },
  passwordScreenWrongPassword() {
    return 'static.passwordScreen.wrongPassword';
  },
  passwordScreenEnterPassword() {
    return 'static.passwordScreen.enterPassword';
  },
  panelScheduleAddToCalendar() {
    return 'static.panel.schedule.addToCalendar';
  },
  panelScheduleMoreInfo() {
    return 'static.panel.schedule.moreInfo';
  },
  panelScheduleDefaultEventTitle() {
    return 'static.panel.schedule.defaultEventTitle';
  },
  panelScheduleDefaultEventTime() {
    return 'static.panel.schedule.defaultEventTime';
  },
  playlistCountAndVideo(
    _: {
      variables: {
        count: string;
      },
    },
  ) {
    return 'static.playlist.videoCountAndLabel';
  },
  cardPersonDefaultProfile() {
    return 'static.card.person.defaultProfile';
  },
  channelSelectName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  imagePanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  schedulePanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  schedulePanelDescription(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.description`;
  },
  schedulePanelEventName(
    p: {
      arrayId: string;
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.event.${p.arrayId}.name`;
  },
  schedulePanelEventLocation(
    p: {
      arrayId: string;
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.event.${p.arrayId}.location`;
  },
  schedulePanelEventMoreInfo(
    p: {
      arrayId: string;
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.event.${p.arrayId}.moreInfo`;
  },
  peoplePanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  personCardTitle(
    p: {
      cardId: string;
    },
  ) {
    return `card.${p.cardId}.title`;
  },
  personName(
    p: {
      personId: string;
    },
  ) {
    return `person.${p.personId}.name`;
  },
  personTitle(
    p: {
      personId: string;
    },
  ) {
    return `person.${p.personId}.title`;
  },
  personBio(
    p: {
      personId: string;
    },
  ) {
    return `person.${p.personId}.bio`;
  },
  groupName(
    p: {
      groupId: string;
    },
  ) {
    return `group.${p.groupId}.name`;
  },
  groupBio(
    p: {
      groupId: string;
    },
  ) {
    return `group.${p.groupId}.bio`;
  },
  globalStudioInviteReceivedTitle() {
    return 'static.global.studioInviteReceivedTitle';
  },
  globalStudioInviteReceivedMessage() {
    return 'static.global.studioInviteReceivedMessage';
  },
  tipLog() {
    return 'static.panel.tips.tipLog';
  },
  tipShowYourSupport() {
    return 'static.panel.tips.showYourSupport';
  },
  tip() {
    return 'static.panel.tips.tip';
  },
  tipThankYou() {
    return 'static.panel.tips.tipThankYou';
  },
  tipTopSupporters() {
    return 'static.panel.tips.topSupporters';
  },
  tipNoTopSupporters() {
    return 'static.panel.tips.noTopSupportersYet';
  },
  tipSeeAll() {
    return 'static.panel.tips.tipSeeAll';
  },
  howMuchToTip() {
    return 'static.panel.tips.howMuchToTip';
  },
  alternativeEnterCustomTip() {
    return 'static.panel.tips.alternativeEnterCustomTip';
  },
  tipMessageTitle() {
    return 'static.panel.tips.tipMessageTitle';
  },
  tipMessagePlaceholder() {
    return 'static.panel.tips.tipMessagePlaceholder';
  },
  tipVisibilityWarning() {
    return 'static.panel.tips.tipVisibilityWarning';
  },
  tipMessageRemoved() {
    return 'static.panel.tips.tipMessageRemoved';
  },
  tipTotal() {
    return 'static.panel.tips.tipTotal';
  },
  saveAndTip() {
    return 'static.panel.tips.saveAndTip';
  },
  loginToTip() {
    return 'static.panel.tips.loginToTip';
  },
  processingTip() {
    return 'static.panel.tips.processingTip';
  },
  giveTip() {
    return 'static.panel.tips.giveTip';
  },
  noTipsYet() {
    return 'static.panel.tips.noTipsYet';
  },
  tipPanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  tipThankYouMessage(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.thankYouMessage`;
  },
  chatPanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  chatTypeMessage() {
    return 'static.panel.chat.typeAMessage';
  },
  chatShowMoreMessages() {
    return 'static.panel.chat.showMoreMessages';
  },
  chatPostMessage() {
    return 'static.panel.chat.post';
  },
  chatMessageAdminLabel() {
    return 'static.panel.chat.messageAdminLabel';
  },
  chatDefaultBotName() {
    return 'static.panel.chat.defaultChatBotName';
  },
  addToCalendarModalDescription() {
    return 'static.panel.schedule.modal.description';
  },
  multipleChoicePanelDefaultLoginButtonText() {
    return 'static.panel.multipleChoice.defaultLoginButtonText';
  },
  multipleChoicePanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  multipleChoicePanelDescription(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.description`;
  },
  multipleChoicePanelLoginButtonText(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.loginButtonText`;
  },
  multipleChoicePanelType(
    p: {
      type: string;
    },
  ) {
    return `static.panel.multipleChoice.${p.type}`;
  },
  multipleChoicePanelCorrect() {
    return 'static.panel.multipleChoice.correct';
  },
  multipleChoicePanelIncorrect() {
    return 'static.panel.multipleChoice.incorrect';
  },
  multipleChoicePanelLocked() {
    return 'static.panel.multipleChoice.locked';
  },
  overlayTitle(
    p: {
      type: string;
    },
  ) {
    return `static.overlay.${p.type}`;
  },
  overlayViewed() {
    return 'static.overlay.viewed';
  },
  overlayEngaged() {
    return 'static.overlay.engaged';
  },
  overlayPreview() {
    return 'static.overlay.preview';
  },
  overlayRotatePhone() {
    return 'static.overlay.rotatePhone';
  },
  multipleChoiceOverlayResults() {
    return 'static.overlay.multipleChoice.results';
  },
  multipleChoiceOverlayAnswer() {
    return 'static.overlay.multipleChoice.answer';
  },
  multipleChoiceName(
    p: {
      multipleChoiceId: string;
      multipleChoiceType: string;
    },
  ) {
    return `${p.multipleChoiceType}.${p.multipleChoiceId}.name`;
  },
  multipleChoiceQuestion(
    p: {
      multipleChoiceId: string;
      multipleChoiceType: string;
    },
  ) {
    return `${p.multipleChoiceType}.${p.multipleChoiceId}.question`;
  },
  multipleChoiceAnswer(
    p: {
      answerId: string;
      multipleChoiceId: string;
      multipleChoiceType: string;
    },
  ) {
    return `${p.multipleChoiceType}.${p.multipleChoiceId}.answer.${p.answerId}`;
  },
  logoTitle(
    p: {
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.logo.title`;
  },
  countdownTitle(
    p: {
      pageId: string;
    },
  ) {
    return `landing.${p.pageId}.countdown.title`;
  },
  overlayText(
    p: {
      overlayId: string;
    },
  ) {
    return `overlay.${p.overlayId}.text`;
  },
  overlayCtaText(
    p: {
      overlayId: string;
    },
  ) {
    return `overlay.${p.overlayId}.ctaText`;
  },
  overlayCtaTweet() {
    return 'static.overlay.cta.tweet';
  },
  overlayCtaViewStats() {
    return 'static.overlay.cta.viewStats';
  },
  overlayCtaLogin() {
    return 'static.overlay.cta.login';
  },
  overlayCtaShare() {
    return 'static.overlay.cta.share';
  },
  overlayCtaGoToChannel(
    _: {
      variables: {
        slug: string;
      },
    },
  ) {
    return 'static.overlay.cta.goToChannel';
  },
  questPanelName(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.name`;
  },
  questPanelDescription(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.description`;
  },
  questPanelLoginButtonText(
    p: {
      pageId: string;
      panelId: string;
    },
  ) {
    return `channel.${p.pageId}.panel.${p.panelId}.loginButtonText`;
  },
  questPanelRules() {
    return 'static.panel.quest.rules';
  },
  questPanelHelp() {
    return 'static.panel.quest.help';
  },
  questPanelLogin() {
    return 'static.panel.quest.login';
  },
  questTitle(
    p: {
      questId: string;
    },
  ) {
    return `quest.${p.questId}.title`;
  },
  questDescription(
    p: {
      questId: string;
    },
  ) {
    return `quest.${p.questId}.description`;
  },
  questRewardDescription(
    p: {
      questId: string;
    },
  ) {
    return `quest.${p.questId}.rewardDescription`;
  },
  questClaimedMessage(
    p: {
      questId: string;
    },
  ) {
    return `quest.${p.questId}.claimedMessage`;
  },
  questActionText(
    p: {
      actionArrayId: string;
      questId: string;
    },
  ) {
    return `quest.${p.questId}.action.${p.actionArrayId}.text`;
  },
  questActionCta(
    p: {
      actionArrayId: string;
      questId: string;
    },
  ) {
    return `quest.${p.questId}.action.${p.actionArrayId}.cta`;
  },
  questOnChannel(
    _: {
      variables: {
        slug: string;
      },
    },
  ) {
    return 'static.quest.onChannel';
  },
  questClaimed() {
    return 'static.quest.claimed';
  },
  questNotClaimed() {
    return 'static.quest.notClaimed';
  },
  questViewPrize() {
    return 'static.quest.viewPrize';
  },
  questClaimPrize() {
    return 'static.quest.claimPrize';
  },
  questSubmitEntry() {
    return 'static.quest.submitEntry';
  },
  questEntrySubmitted() {
    return 'static.quest.entrySubmitted';
  },
  questAvailable() {
    return 'static.quest.available';
  },
  questNotAvailable() {
    return 'static.quest.notAvailable';
  },
  questExpired() {
    return 'static.quest.expired';
  },
  questReward() {
    return 'static.quest.reward';
  },
  questTbd() {
    return 'static.quest.tbd';
  },
  gateBundleSoldOut() {
    return 'static.gate.bundle.soldOut';
  },
  gateBundleBuy() {
    return 'static.gate.bundle.buy';
  },
  gateSubscriptionSubscribe() {
    return 'static.gate.subscription.subscribe';
  },
  gateTicketBuy() {
    return 'static.gate.ticket.buy';
  },
  gateEntitlementName(
    p: {
      pageId: string;
      subscriptionId: string;
    },
  ) {
    return `page.${p.pageId}.gate.entitlement.${p.subscriptionId}.name`;
  },
  gateEntitlementDescription(
    p: {
      pageId: string;
      subscriptionId: string;
    },
  ) {
    return `page.${p.pageId}.gate.entitlement.${p.subscriptionId}.description`;
  },
  gateBundleName(
    p: {
      bundleId: string;
      pageId: string;
    },
  ) {
    return `page.${p.pageId}.gate.bundle.${p.bundleId}.name`;
  },
  gateBundleDescription(
    p: {
      bundleId: string;
      pageId: string;
    },
  ) {
    return `page.${p.pageId}.gate.bundle.${p.bundleId}.description`;
  },
} as const;
