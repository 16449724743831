import { throttle } from 'lodash';
import { useCallback, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface Size {
  height: number
  width: number
}

export default function useResizeObserver<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  // We use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const handleSize = useCallback((entry: ResizeObserverEntry) => {
    const { width, height } = entry.contentRect;
    setSize({ width, height });
  }, []);

  useEffect(() => {
    const callback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
      entries.forEach(handleSize);
    };

    const throttledCallback = throttle(callback, 200);
    const resizeObserver = new ResizeObserver(throttledCallback);
    if (ref) {
      resizeObserver.observe(ref);
    }
    return () => {
      if (ref) {
        resizeObserver.unobserve(ref);
      }
    };
  }, [!ref, handleSize]);

  return [setRef, size];
}
