import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import axios from 'axios';
import useRealtimeDocument from 'hooks/use-realtime-document';
import hash from 'json-stable-stringify';
import { PLAYLIST_SERVICE_BASE_URL, VIDEO_SERVICE_BASE_URL } from 'config';
import { getSiteId } from 'services/app/selectors';
import { getPrimaryToken } from 'services/auth';

const findManyUrl: Record<contentKinds, string> = {
  playlist: `${PLAYLIST_SERVICE_BASE_URL}/list`,
  video: `${VIDEO_SERVICE_BASE_URL}/list`,
};

enum contentKinds {
  playlist = 'playlist',
  video = 'video',
}

// fetch all required video/playlist data from schedule events to render them
const useScheduleContent = (
  channelId: string,
  earliestTimestamp: number,
  latestTimestamp: number,
) => {
  const siteId = useSelector(getSiteId);
  const primaryToken = useSelector(getPrimaryToken);
  const [channel, loaded] = useRealtimeDocument('objects', channelId);
  const [contentData, setContentData] = useState({});

  // build map of video/playlist ids needed to populate schedule events
  const contentIdMap = useMemo(() => {
    const schedule = get(channel, 'data.content.live.schedule', []);
    return schedule.reduce((idMap: Record<string, string[]>, scheduleItem: any) => {
      const { duration, kind, repeat } = scheduleItem;
      const contentId = get(scheduleItem, `[${kind}]._id`);
      const startTimestamp = new Date(scheduleItem.start_time).getTime();
      const repeating = ['daily', 'weekly', 'monthly'].includes(repeat);
      const endTimestamp = repeating ?
        Infinity :
        startTimestamp + ((duration || 24 * 60 * 60) * 1000);
        // ignore items that are invalid or out of range
      if (!contentId || (endTimestamp < earliestTimestamp || startTimestamp > latestTimestamp)) {
        return idMap;
      }
      idMap[kind].push(contentId);
      return idMap;
    }, {
      playlist: [],
      video: [],
    });
  }, [hash(channel), channelId, earliestTimestamp, latestTimestamp, loaded]);

  // fetch content for schedule items
  useEffect(() => {
    if (channel && loaded) {
      // @ts-ignore
      Object.entries(contentIdMap).forEach(([kind, ids]: [contentKinds, string[]]) => {
        if (ids.length) {
          axios({
            headers: {
              Authorization: `Bearer ${primaryToken}`,
              'x-maestro-client-id': siteId,
            },
            method: 'GET',
            params: { ids: ids.join(',') },
            url: findManyUrl[kind],
          }).then(({ data }) => setContentData({
            ...contentData,
            ...data,
          })).catch((e) => {
            // tslint:disable-next-line:no-console
            console.warn(`error fetching scheduled ${kind} data: ${e.message}`);
          });
        }
      });
    }
  }, [hash(channel), hash(contentIdMap)]);

  return contentData;
};


export default useScheduleContent;
