import React from 'react';
import useFocusArea from './use-focus-area';

const useFloatMenu = (setMenuPosition: (menu: HTMLDivElement, menuContainer: HTMLDivElement) => void) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const containerRef = useFocusArea<HTMLDivElement>({
    onExit: () => {
      setIsOpen(false);
    },
    active: isOpen,
    ignoreIDs: ['modal-portal'],
  });

  const toggleMenu = React.useCallback((e?: React.MouseEvent) => {
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  }, []);

  const positionMenu = React.useCallback((menu: HTMLDivElement | null) => {
    const menuContainer = containerRef.current;
    if (!menu || !menuContainer) {
      return;
    }
    setMenuPosition(menu, menuContainer);
  }, [containerRef]);

  return {
    isOpen,
    containerRef,
    toggleMenu,
    positionMenu,
  };
};

export default useFloatMenu;
