import React, { useEffect } from 'react';
import { sleepMs } from 'utils';

interface IUseDismissOnSwipeDownProps {
  closeregionRef: React.RefObject<HTMLDivElement>;
  containerRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  padding?: number;
}

/**
 *
 * @param onClose - callback to close the menu
 * @param containerRef - ref to the container element
 * @param closeregionRef - ref to the element that will be used to detect the swipe down gesture
 * @param padding - padding to be used to calculate the swipe down gesture
 * @returns {object} - object with the following properties:
 * - isCloseMarkActive - boolean, true if the close mark is active
 * - slidingDownValue - number, the value of the swipe down gesture
 */
const useDismissOnSwipeDown = ({
  onClose,
  containerRef,
  closeregionRef,
  padding = 0,
}: IUseDismissOnSwipeDownProps) => {
  const [slidingDownValue, setSlidingDownValue] = React.useState(0);
  const [isCloseMarkActive, setIsCloseMarkActive] = React.useState(false);

  const slideDownAndDismissAnimation = async () => {
    if (containerRef.current) {
      containerRef.current.style.transform = 'translateY(100%)';
      containerRef.current.style.transition = 'transform 0.3s ease';
      await sleepMs(300);
      onClose();
      setSlidingDownValue(0);
      setIsCloseMarkActive(false);
    }
  };

  const handleTouchStart = () => {
    setIsCloseMarkActive(true);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (closeregionRef.current) {
      const { clientY } = e.touches[0];
      setSlidingDownValue(clientY - padding);
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    // if user scrolled more than 100px, dismiss the menu
    if (e.changedTouches[0].clientY - padding > 100) {
      slideDownAndDismissAnimation();
    } else {
      setSlidingDownValue(0);
      setIsCloseMarkActive(false);
    }
  };

  useEffect(() => {
    if (closeregionRef.current) {
      closeregionRef.current.addEventListener('touchstart', handleTouchStart);
      closeregionRef.current.addEventListener('touchmove', handleTouchMove);
      closeregionRef.current.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (closeregionRef.current) {
        closeregionRef.current.removeEventListener(
          'touchstart',
          handleTouchStart,
        );
        closeregionRef.current.removeEventListener(
          'touchmove',
          handleTouchMove,
        );
        closeregionRef.current.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [closeregionRef.current]);

  return {
    isCloseMarkActive,
    slidingDownValue,
  };
};

export default useDismissOnSwipeDown;
