import { useDispatch, useSelector } from 'react-redux';
import { AdminActionEvents } from 'services/admin/models';
import { getPlan, getPlanUnitPrice } from 'services/billing';
import { trackEvent } from 'services/segment-analytics';

interface IPlanPurchasedData {
  buttonTitle: string;
  errorText: string;
  planBillingAmount: number;
  planId: string;
  planName: string;
}

type CallbackParams = Pick<IPlanPurchasedData, 'buttonTitle' | 'errorText'>;
export type Callback = (data: CallbackParams) => void;

export const useTrackPlanPurchased = (): Callback => {
  const dispatch = useDispatch();
  const plan = useSelector(getPlan);
  const planUnitPrice = useSelector(getPlanUnitPrice);


  return (data: CallbackParams) => {
    const { buttonTitle, errorText } = data;

    const properties: IPlanPurchasedData = {
      buttonTitle,
      planBillingAmount: planUnitPrice || 0,
      planId: plan?._id || '',
      planName: plan?.name || '',
      errorText,
    };

    dispatch(trackEvent({
      event: AdminActionEvents.PLAN_PURCHASED,
      properties: {
        ...properties,
      },
    }));
  };
};

export default useTrackPlanPurchased;
