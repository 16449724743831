import { shardName } from 'firebase-db';
import { getUserId } from 'services/auth/selectors';
import { getMaestroPath } from 'services/insights/selectors'; // "private" selector
import { getRenderer } from 'services/renderer/selectors';
import { setLastEventSentTime, setSessionStart } from 'services/insights/actions';
import { hasOwn } from 'utils';

const init = process.env.NODE_ENV === 'production' ?
  () => { } :
  (store) => {
    const selectorMap = {
      getMaestroPath,
      getRenderer,
      getUserId,
    };

    const dev = {
      actions: {
        setLastEventSentTime: (timeMs) => {
          if (typeof timeMs === 'number' && timeMs < 1) {
            throw new Error('value must be epoch ms time greater than 0');
          }
          store.dispatch(setLastEventSentTime(timeMs));
        },
        setSessionStartTime: (timeMs) => {
          if (typeof timeMs === 'number' && timeMs < 1) {
            throw new Error('value must be epoch ms time greater than 0');
          }
          store.dispatch(setSessionStart(timeMs));
        },
      },
      selectors: {
        // See below
      },
      shardName,
      state: store.getState(),
      store,
    };

    Object.entries(selectorMap).forEach(([name, selector]) => {
      dev.selectors[name] = (...args) => selector(dev.state, ...args);
    });

    const emittedErrors = {};
    const emitError = (message, value) => {
      if (hasOwn(emittedErrors, message)) {
        return;
      }
      emittedErrors[message] = true;
      /* eslint-disable no-console */
      console.warn([
        'REDUX ERROR:',
        message,
      ].join('\n'));
      console.warn(value);
      /* eslint-enable */
    };

    const traverse = (target, path, seen = new Set()) => {
      if (target && (typeof target === 'object' || typeof target === 'function')) {
        if (seen.has(target)) {
          emitError([
            'Circular reference detected:',
            path.join('.'),
            "(How did you even manage that? I'm impressed!)",
          ].join('\n'));
          return;
        }
        seen.add(target);
      }
      if (Array.isArray(target)) {
        target.forEach((item, index) => traverse(item, path.concat([index]), new Set(seen)));
      } else {
        // Can be extended
        const isFunction = typeof target === 'function';
        const isIterator = typeof target?.next === 'function';
        const isClassInstance = ( // React component, Map, Set, etc.
          target &&
          typeof target === 'object' &&
          Object.getPrototypeOf(target) !== Object.getPrototypeOf({}) &&
          Object.getPrototypeOf(target) !== null // TODO: Shouldn't rely on null prototypes
          // No need to check for arrays - handled in earlier case
        ) || typeof target === 'symbol';
        const nonSerializable = isFunction || isIterator || isClassInstance;
        if (nonSerializable) {
          const { constructor } = target || {};
          const messageLines = [
            'Non-serializable value detected!',
            `Path: ${path.join('.')}`,
            `Type: "${typeof target}"`,
            constructor && `Constructor name: "${constructor.name}"`,
          ].filter(line => line);
          if (isFunction) {
            messageLines.push(
              'You probably tried to store a callback function in a reducer.',
              "That's not allowed. You have brought great shame upon your family.",
            );
          } else if (isIterator) {
            messageLines.push(
              'You probably tried to store an iterator in a reducer.',
              'y tho?',
            );
          } else if (isClassInstance) {
            messageLines.push(
              'You probably tried to store a non-plain object (class instance) in a reducer.',
              "I can't believe you've done this.",
            );
          }
          emitError(messageLines.join('\n'), target);
        }

        if (target && typeof target === 'object' && !nonSerializable) {
          for (const [key, item] of Object.entries(target)) {
            traverse(item, path.concat([key]), new Set(seen));
          }
        }
      }
    };

    const checkReducer = (name) => {
      traverse(
        dev.state[name],
        ['state', name],
      );
    };

    Object.keys(dev.state).forEach(name => checkReducer(name));

    store.subscribe(() => {
      const oldState = dev.state;
      const newState = store.getState();
      dev.state = newState;
      Object.keys(newState).forEach((name) => {
        const oldReducer = oldState[name];
        const newReducer = newState[name];
        if (oldReducer !== newReducer) {
          checkReducer(name);
        }
      });
    });
    window.dev = dev;
  };

export default init;
