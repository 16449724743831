import useRealtimeDocument from 'hooks/use-realtime-document';
import useSelector from 'hooks/use-selector';
import { getSiteId } from 'services/app/selectors';
import { getUserId } from 'services/auth/selectors';

const useUserAchievementPoints = (): [number | null, boolean] => {
  const userId = useSelector(getUserId);
  const siteId = useSelector(getSiteId);
  const [points, pointsLoaded] = useRealtimeDocument<number | null>(
    'user_achievement_points_v2', `${siteId}:${userId}`,
  );

  // Integrated leaderboard
  if (!pointsLoaded) {
    return [null, false];
  }
  return [points || null, true];
};

export default useUserAchievementPoints;
