import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import {
    CHANNEL_SELECT_ID,
    CHAT_ID,
    IMAGE_V2_ID,
    MULTIPLE_CHOICE_ID,
    PEOPLE_ID,
    SCHEDULE_ID,
    QUEST_ID,
} from 'components/objects/PanelV2/constants';

type IUsePanelTitleParams = {
    pageId: string;
    panelId: string;
    panelKind: string;
    title: string;
};

const localizedPanelKeys: { [kind: string]: DynamicTranslationType } = {
    [CHANNEL_SELECT_ID]: DynamicTranslationType.channelSelectName,
    [CHAT_ID]: DynamicTranslationType.chatPanelName,
    [IMAGE_V2_ID]: DynamicTranslationType.imagePanelName,
    [MULTIPLE_CHOICE_ID]: DynamicTranslationType.multipleChoicePanelName,
    [PEOPLE_ID]: DynamicTranslationType.peoplePanelName,
    [QUEST_ID]: DynamicTranslationType.questPanelName,
    [SCHEDULE_ID]: DynamicTranslationType.schedulePanelName,
};

/**
 * Provides a scable solution for retrieving the
 * desired Panel's localized title
 *
 * params: {pageId, panelId, panelKind, title}
 * return: [localizedPanelTitle, adminTitle]
 */
export const useLocalizedPanelTitle = ({
    pageId,
    panelId,
    panelKind,
    title,
}: IUsePanelTitleParams): [string, string] => {
    const { endUserT } = useEndUserTranslation();
    const result: [string, string] = ['', title];
    const dynamicTranslationType = localizedPanelKeys[panelKind] ?? '';

    if (dynamicTranslationType) {
        result[0] = endUserT([dynamicTranslationType,  { pageId, panelId }]);
    }

    return result;
};
