import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import type { ResolvedReturnType } from 'utils';
import { lazy } from 'utils/lazy';
import { unbreakable } from 'utils/unbreakable';
import * as RealtimeBridge from 'services/realtime/bridge';

// add your api/service here
const moduleLoaders = {
  video: () => import('services/video/api'),
  videoEditor: () => import('services/video-editor/api'),
  videoUpload: () => import('services/video-upload/api'),
  notification: () => import('services/notification/api'),
  epg: lazy(() => import('services/epg').then((m) => m.makeEpgService(RealtimeBridge))),
  caniplay: lazy(() => import('services/caniplay').then((m) => m.makeCanIPlayService())),
  page: () => import('services/page/api'),
};

export type TApis = {
  [K in keyof typeof moduleLoaders]: ResolvedReturnType<(typeof moduleLoaders)[K]>;
};

export const ApisContext = createContext(
  unbreakable() as TApis,
);

const lazyModules = lazy(
  () => new Proxy<TApis>(
    {} as any,
    {
      get(apis: any, apiName) {
        if (!apis[apiName]) {
          apis[apiName] = new Proxy(
            {},
            {
              get: (api: any, method) => {
                if (!api[method]) {
                  api[method] = (...args: any[]) => (
                    (moduleLoaders as any)[apiName]?.()?.then(
                      (module: any) => module[method](...args),
                    )
                  );
                }

                return api[method];
              },
            },
          );
        }

        return apis[apiName];
      },
    },
  ),
);

export const ApisProvider: FC<PropsWithChildren> = ({ children }) => (
  <ApisContext.Provider value={lazyModules()}>
    {children}
  </ApisContext.Provider>
);

export const useApis = () => useContext(ApisContext);
