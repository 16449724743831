import { useState, useEffect } from 'react';

export const useElapsedTime = (timestamp: number) => {
  const [now, setNow] = useState(Date.now());

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setNow(Date.now()),
        200,
      );
      return () => clearTimeout(timeout);
    },
    [now],
  );

  return now - timestamp;
};
