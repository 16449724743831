import { useEffect, useState } from 'react';
import { createBitFieldMap, createBitmask } from 'utils';
import hash from 'json-stable-stringify';

type RenderCondition<T extends string> = {
  condition: boolean;
  fields: T[];
};

interface RenderConditionsProps<T extends string> {
  fieldMap: readonly T[];
  renderConditions: readonly RenderCondition<T>[];
}

export default function useRenderConditions<T extends string>({
  fieldMap,
  renderConditions,
}: RenderConditionsProps<T>) {
  const [bitFieldMap, setBitFieldMap] = useState(createBitFieldMap(fieldMap));
  const [actions, setActions] = useState(createBitmask(bitFieldMap));

  useEffect(() => {
    const newActions = createBitmask(bitFieldMap);

    renderConditions.forEach(({ condition, fields }) => {
      if (condition) {
        fields.forEach(newActions.add);
      }
    });

    setActions(newActions);
  }, [hash(renderConditions)]);

  useEffect(() => {
    setBitFieldMap(createBitFieldMap(fieldMap));
  }, [fieldMap]);

  return actions.has;
}
