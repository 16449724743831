import { useRef, useCallback } from 'react';

export const useCachedCallback = <K extends string, T>(callbackFactory: (key: K) => T) => {
  const cache = useRef({
    cache: {} as Record<string, T>,
    factory: callbackFactory,
  });

  // invalidate cache when factory changes
  if (cache.current.factory !== callbackFactory) {
    cache.current = {
      factory: callbackFactory,
      cache: {},
    };
  }

  return useCallback(
    (key: K) => {
      if (!cache.current.cache[key]) {
        cache.current.cache[key] = callbackFactory(key);
      }

      return cache.current.cache[key];
    },
    [callbackFactory],
  );
};
