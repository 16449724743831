import useRealtimeDocument from 'hooks/use-realtime-document';

export declare interface IPubGMatchTeam {
  players: Array<{
    damageDealt: number,
    dbnos: number,
    kills: number,
    name: string,
  }>;
  rank: number;
}

export declare interface IPubGMatch {
  teams: IPubGMatchTeam[];
}

export declare interface IPubGTournament {
  matches: IPubGMatch[];
}

const usePubGTournament = ({ tournamentId = '' }: {
  tournamentId: string,
}): [IPubGTournament | {}, boolean] => {
  const sanitized = tournamentId.replace(/[+=$.#\/\[\]]/g, '');
  const [doc, docLoaded] = useRealtimeDocument<IPubGTournament>('pubg_tournament', sanitized);

  if (docLoaded && doc && doc.matches) {
    return [doc, docLoaded];
  }
  return [{ matches: [] }, docLoaded];
};

export default usePubGTournament;
