import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IObject } from 'models';
import IGate, { GateKind } from 'models/IGate';
import { TargetTypes, getDraftHomeId, getPendingPageDocs, setPendingAdminChange } from 'services/admin';
import { channelSelectEnabled, getChannelSelectPages } from 'services/app';
import { archivePage, clearPendingPageDoc, setPendingPageDoc } from 'services/admin/actions';
import { replace } from 'services/app-router/actions';
import { getCurrentPageId } from 'services/app/selectors';
import useRealtimeDocument from './use-realtime-document';
import { removeItemFromNavigations } from 'services/navigationv2';

const useDeletePage = (pageId: string) => {
  const homeId = useSelector(getDraftHomeId);
  const [homePage] = useRealtimeDocument<IObject>('objects', homeId);
  const [pageToBeDeleted] = useRealtimeDocument<IObject>('objects', pageId);
  const pageDraftDocs = useSelector(getPendingPageDocs);
  const channelSelectPages = useSelector(getChannelSelectPages);
  const isChannelSelectEnabled = useSelector(channelSelectEnabled);
  const currentPageId = useSelector(getCurrentPageId);

  const dispatch = useDispatch();

  const getInitialDoc = useCallback((doc: IObject) => {
    if (!doc) {
      return null;
    }
    const newObj: IObject = JSON.parse(JSON.stringify(doc));
    if (!doc.data.gate) {
      newObj.data.gate = { kind: GateKind.NONE } as IGate;
    }
    return newObj;
  }, []);

  const removeChannelInCarousel = (slug: string) => {
    if (!initialDoc) return;
    const channelWithoutThisPage = channelSelectPages?.filter(data => data.page.slug !== slug);

    if (channelSelectPages?.length !== channelWithoutThisPage?.length) {
      updatePages({
        pages: channelWithoutThisPage,
        state: isChannelSelectEnabled ? 'on' : 'off',
      });
    }

    clearPendingPageDoc(initialDoc._id);
  };

  const updatePages = (data: any) => {
    dispatch(
      setPendingAdminChange(
        TargetTypes.CHANNEL_NAVIGATION,
        data,
        true,
      ),
    );
  };

  const initialDoc = getInitialDoc(pageDraftDocs[pageId!] || pageToBeDeleted || homePage);
  const docExists = Boolean(pageToBeDeleted);
  const canDelete = initialDoc && initialDoc._id !== homeId;

  const deleteDoc = () => {
    if (!canDelete) return;

    if (docExists) {
      removeChannelInCarousel(initialDoc.slug);
      dispatch(archivePage(initialDoc._id));
      dispatch(removeItemFromNavigations(initialDoc._id));
    } else {
      dispatch(setPendingPageDoc(initialDoc._id, null));
    }

    if (currentPageId === initialDoc._id) {
      dispatch(replace({ path: '/' }));
    }
  };

  return { deleteDoc, canDelete };
};

export default useDeletePage;
