import { useDispatch } from 'react-redux';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics/actions';

export interface IAccessTicketCreatedData {
  additionalPrices: string[];
  defaultCurrency: string;
  defaultPrice: string;
  eventDate: string | null;
  sku: string;
  ticketName: string;
}


export type fnCallback = (data: IAccessTicketCreatedData) => void;

export const useTrackTicketCreated = (): fnCallback => {
  const dispatch = useDispatch();

  return (params: IAccessTicketCreatedData) => {
    const { additionalPrices, defaultCurrency, defaultPrice, eventDate, sku, ticketName } = params;

    const properties: IAccessTicketCreatedData = {
      additionalPrices,
      defaultCurrency,
      defaultPrice,
      eventDate,
      sku,
      ticketName,
    };

    dispatch(
      trackEvent({
        event: AdminActionEvents.ACCESS_TICKET_CREATED,
        properties,
      }),
    );
  };
};

export default useTrackTicketCreated;
