import usePanels from 'hooks/use-panels';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

type TContext = ReturnType<typeof usePanels>;

export const PanelsContext = createContext<TContext>(
  {} as any,
);

export const PanelsProvider: FC<PropsWithChildren> = ({ children }) => (
  <PanelsContext.Provider
    value={usePanels()}
  >
    {children}
  </PanelsContext.Provider>
);

export const usePanelsContext = () =>
  useContext(PanelsContext);
