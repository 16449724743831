import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import useTimeoutUpdate from './use-timeout-update';
import useCopyToClipboard from './use-copy-to-clipboard';

const useLivestreamClipboard = () => {
  const copy = useCopyToClipboard();
  const [keyCopied, setKeyCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  useTimeoutUpdate(setUrlCopied, 3000, urlCopied, false);
  useTimeoutUpdate(setKeyCopied, 3000, keyCopied, false);
  const inputStreamKeyRef = useRef(null);
  const inputStreamUrlRef = useRef(null);

  const onCopy = useCallback(
    (e: SyntheticEvent) => {
      const validateInput = e.currentTarget.id;
      let currentInput = inputStreamKeyRef.current;

      if (validateInput !== 'streamKey') {
        currentInput = inputStreamUrlRef.current;
        setUrlCopied(true);
      } else {
        setKeyCopied(true);
      }

      if (currentInput)
        copy(currentInput);
    },
    [],
  );

  return {
    inputStreamKeyRef,
    inputStreamUrlRef,
    keyCopied,
    onCopy,
    urlCopied,
  };
};

export default useLivestreamClipboard;
