import { useDispatch, useSelector } from 'react-redux';
import { trackFooter } from 'services/insights';
import { isUserAdmin } from 'services/auth';
import { isFooterEnabled } from 'services/app/selectors';
import { onSocialClick } from 'services/third-party-analytics';
import { isFullscreenActive } from 'services/user-layout';
import { getPendingOrActualFooterLinks, getPendingOrActualFooterSponsors, getPendingOrActualHashtag, getPendingOrActualSocialsLinks, getPendingOrActualTweetMessage } from 'services/admin/selectors';

export default function useFooterState() {
  const dispatch = useDispatch();

  return ({
    enabled: useSelector(isFooterEnabled),
    footerLinks: useSelector(getPendingOrActualFooterLinks),
    fullscreenActive: useSelector(isFullscreenActive),
    hashtag: useSelector(getPendingOrActualHashtag),
    isAdmin: useSelector(isUserAdmin),
    socials: useSelector(getPendingOrActualSocialsLinks),
    sponsors: useSelector(getPendingOrActualFooterSponsors),
    tweetMessage: useSelector(getPendingOrActualTweetMessage),
    onEngage: (doc: Record<string, any>, extra?: Record<string, any>) => {
      dispatch(trackFooter('engage', doc, extra));
      dispatch(onSocialClick(doc.data.name));
    },
  });
}
