import hash from 'json-stable-stringify';
import ISubscription from 'models/ISubscription';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPendingPageDoc,
  setGateTitleChanges as setGateTitleChangesAction,
  setGateSubtitleChanges as setGateSubtitleChangesAction,
  setGateDateAndTime as setGateDateAndTimeAction,
  setGateNavigation as setGateNavigationAction,
  setGateLogo as setGateLogoAction,
  setGateBackground as setGateBackgroundAction,
  setGateKindInformation as setGateKindInformationAction,
} from 'services/admin/actions';
import { getEditingGate, isAccessControlModeActive as isAccessControlModeActiveSelector } from 'services/admin/selectors';
import { getEditingPageDoc } from 'services/admin/selectors';
import { hasPageChanges } from 'services/admin/selectors';
import { IAccessGateBackground, ILogoData, INavigationLegacyFromGate, GateKind, defaultGateData, defaultGateInformation } from 'models/IGate';
import { produce } from 'immer';
import { useAdminTranslation } from 'hooks/use-translation';
import { getGate } from 'services/app/selectors';
import IBundle from 'models/IBundle';
import useGatePassword from './use-gate-password';
import useLegacySubscriptions from './use-legacy-subscriptions';
import { AmazonBenefitData } from 'components/admin2/AccessControlModal/AmazonBenefitGateSettings';
import { getIsRichTextEditorV3Enabled } from 'services/feature-gate/selectors';
import { getDefaultEditorState, getDefaultTextNode } from 'components/ui/RichTextEditorV3/getDefaultEditorState';
import { DEFAULT_GATE_SUBTITLE, DEFAULT_GATE_TITLE } from 'shared/constants';
import { getDefaultRichTextEditorData } from 'utils';

const useEditGate = () => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const { editingPassword } = useGatePassword();
  const { getSubscriptionGateWithMoreData } = useLegacySubscriptions();
  const hasPendingChanges = useSelector(hasPageChanges);
  const editingPage = useSelector(getEditingPageDoc);
  const publishedGate = useSelector(getGate);
  const draftGate = useSelector(getEditingGate);

  const isAccessControlModeActive = useSelector(
    isAccessControlModeActiveSelector,
  );

  const editingGate = isAccessControlModeActive ? draftGate : publishedGate;

  const withSetDraftDocument = useCallback(
    <TFn extends (...args: any) => any>(fn: TFn): (...args: Parameters<TFn>) => void => {
      const setDraftDocument = () => {
        if (!hasPendingChanges) {
          dispatch(setPendingPageDoc(editingPage._id, editingPage));
        }
        /**
         * Old sites does not have gate property.
         * We set the default gate data as part of the draft document
         */
        if (!editingPage.data.gate) {
          const editingPageWitGate = produce(editingPage, (draftEditingPage) => {
            draftEditingPage.data.gate = defaultGateData;
          });
          dispatch(setPendingPageDoc(editingPage._id, editingPageWitGate));
        }
        /**
         * Created pages doesn't have gate.gate property
         */
        if (!editingPage.data.gate.gate) {
          const editingPageWithGateInformation = produce(editingPage, (draftEditingPage) => {
            draftEditingPage.data.gate.gate = defaultGateInformation(t);
          });
          dispatch(setPendingPageDoc(editingPage._id, editingPageWithGateInformation));
        }
      };

      return (...args: any[]) => {
        setDraftDocument();
        fn(...args);
      };
    },
    [dispatch, hasPendingChanges, hash(editingPage), t],
  );

  const isRichTextEditorV3Enabled = useSelector(getIsRichTextEditorV3Enabled);

  const setGateTitleChanges = withSetDraftDocument((styledTitle: string) => {
    const field = isRichTextEditorV3Enabled ? 'titleRawDataV2' : 'titleRawData';
    dispatch(setGateTitleChangesAction(editingPage._id, styledTitle, field));
  });
  const setGateSubtitleChanges = withSetDraftDocument((styledSubtitle: string) => {
    const field = isRichTextEditorV3Enabled ? 'subtitleRawDataV2' : 'subtitleRawData';
    dispatch(
      setGateSubtitleChangesAction(editingPage._id, styledSubtitle, field),
    );
  });
  const setGateDateAndTime = withSetDraftDocument((dateAndTime: number | null) => {
    dispatch(setGateDateAndTimeAction(editingPage._id, dateAndTime));
  });
  const setGateNavigation = withSetDraftDocument((navigation: INavigationLegacyFromGate) => {
    dispatch(setGateNavigationAction(editingPage._id, navigation));
  });
  const setGateLogo = withSetDraftDocument((logoData: ILogoData) => {
    dispatch(setGateLogoAction(editingPage._id, logoData));
  });
  const setGateBackground = withSetDraftDocument((backgroundData: IAccessGateBackground) => {
    dispatch(setGateBackgroundAction(editingPage._id, backgroundData));
  });
  const setGateKindInformation = withSetDraftDocument(
    (
      kind: GateKind,
      password?: string,
      subscriptionsData?: {
        bundles: Array<IBundle>;
        hiddenBundles: string[];
        hiddenEntitlements: string[];
        subscriptions: Array<ISubscription>;
      },
      amazonBenefitData?: AmazonBenefitData,
    ) => {
      dispatch(
        setGateKindInformationAction(
          editingPage._id,
          kind,
          password,
          subscriptionsData,
          amazonBenefitData,
        ),
      );
    },
  );

  // Default Data
  // This section handles initializations and legacy data.
  const defaultTextTitle =
    editingGate?.gate?.title ||
    editingGate?.gate?.subscriptionTitle ||
    DEFAULT_GATE_TITLE(t);

  // Default Data
  const defaultTextSubtitle =
    editingGate?.gate?.subtitle ||
    editingGate?.gate?.subscriptionSubtitle ||
    DEFAULT_GATE_SUBTITLE(t);


  return {
    editingGate,
    editingGateInformation: {
      ...editingGate?.gate,
      subscriptions: editingGate?.gate?.subscriptions || [],
      titleRawData: editingGate?.gate?.titleRawData || getDefaultRichTextEditorData({ text: defaultTextTitle, fontSize: 30 }),
      titleRawDataV2: editingGate?.gate?.titleRawDataV2 || getDefaultEditorState([getDefaultTextNode({ text: defaultTextTitle, fontSize: 30 })]),
      subtitleRawData: editingGate?.gate?.subtitleRawData || getDefaultRichTextEditorData({ text: defaultTextSubtitle, fontSize: 14 }),
      subtitleRawDataV2: editingGate?.gate?.subtitleRawDataV2 || getDefaultEditorState([getDefaultTextNode({ text: defaultTextSubtitle, fontSize: 14 }),
      ]),
    },
    editingGateKing: editingGate?.kind,
    editingIsGateActive: editingGate?.active,
    editingPassword,
    setGateTitleChanges,
    setGateSubtitleChanges,
    setGateDateAndTime,
    setGateNavigation,
    setGateLogo,
    setGateBackground,
    setGateKindInformation,
    getSubscriptionGateWithMoreData,
  };
};

export default useEditGate;
