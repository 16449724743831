import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getHasAdminModal } from 'services/admin/selectors';

type Handler = (event: MouseEvent | KeyboardEvent) => void;

type UseFocusAreaParams<T> = {
  /**
   * The flag to start or stop listening for clicks outside the focus area.
   */
  active: boolean;
  /**
   * A reference to the element to focus on. Defaults to null
   */
  externalRef?: T;
  /**
   * An array of IDs to ignore when checking if the click is outside the focus area.
   */
  ignoreIDs?: string[];
  /**
   * A function to call when the user clicks outside the focus area.
   */
  onExit: Handler;
};

export default function useFocusArea<T extends HTMLElement>({ onExit, active, externalRef, ignoreIDs }: UseFocusAreaParams<T>) {
  const componentRef = useRef(externalRef || null);
  const callbackRef = useRef(onExit);
  const hasAdminModal = useSelector(getHasAdminModal);

  useEffect(() => {
    callbackRef.current = onExit as Handler;
  }, [onExit]);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    const ref = externalRef || componentRef.current;
    const target = event.target as HTMLElement;
    if (hasAdminModal) {
      return;
    }

    if (!ref) {
      return;
    }

    if (ref.contains(target)) {
      return;
    }

    if (ignoreIDs?.length) {
      if (ignoreIDs.includes(target.id)) {
        return;
      }
      let parent = target.parentElement;
      while (parent) {
        if (ignoreIDs.includes(parent.id)) {
          return;
        }
        parent = parent.parentElement;
      }
    }

    callbackRef.current(event);
  }, [externalRef, ignoreIDs, hasAdminModal]);

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callbackRef.current(event);
    }
  }, []);

  useEffect(() => {
    const disable = () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keyup', handleEscape);
    };

    if (active) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keyup', handleEscape);
    } else {
      disable();
    }

    return disable;
  }, [active, handleEscape, handleOutsideClick]);

  return componentRef;
}
