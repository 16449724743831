import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import hash from 'json-stable-stringify';

const useDebouncedState = <T>(
  initialValue: T, delayMs: number,
): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const debounce = setTimeout(() => setDebouncedValue(value), delayMs);
    return () => clearTimeout(debounce);
  }, [hash(value)]);

  return [debouncedValue, setValue, value];
};

export default useDebouncedState;

