import { getSubscriptionById } from 'services/subscription-v2/api';
import { getTypeValue } from 'shared/string-utils';
import ISubscription from 'models/ISubscription';
import { useSelector } from 'react-redux';
import { getSiteId } from 'services/app';
import { getPrimaryToken } from 'services/auth';

const useLegacySubscriptions = () => {
  const primaryToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);

  const isMissingSomeSubscriptionData = ({
    type,
    visible,
    prices,
  }: ISubscription) =>
    typeof type === 'undefined' ||
    typeof visible === 'undefined' ||
    typeof prices === 'undefined';

  const getSubscriptionGateWithMoreData = async (hiddenEntitlements, subscriptions) => {
    const hiddenEntitlementsData = hiddenEntitlements || [];

    const isSubscriptionHidden = (subscriptionSku: string) =>
      hiddenEntitlementsData.includes(subscriptionSku);

    const res = await Promise.all(
      subscriptions.map(async (sub) => {
        if (sub._id && isMissingSomeSubscriptionData(sub)) {
          const subFromApi = await getSubscriptionById(
            sub._id,
            siteId,
            primaryToken || '',
          );

          const { recurrence, prices } = subFromApi;
          return {
            ...sub,
            prices,
            type: getTypeValue(recurrence!),
            visible: !isSubscriptionHidden(sub.sku),
          };
        }
        return sub;
      }),
    );
    return res;
  };

  return {
    getSubscriptionGateWithMoreData,
  };
};

export default useLegacySubscriptions;
