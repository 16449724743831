import React, { forwardRef } from 'react';
import { useEndUserTranslation } from './endUser';
import { WithExtraProps } from 'utils';

/**
 * When its needed to localize a class comoonent, use this function to wrap it.
 */
export function withEndUserT<T>(component: T) {
  return forwardRef((props, ref) => {
    const { endUserT, endUserInterpolator } = useEndUserTranslation();
    const Component = component as any;

    return <Component ref={ref} endUserT={endUserT} endUserInterpolator={endUserInterpolator} {...props} />;
  }) as WithExtraProps<T, { endUserT: never }>;
}
