import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { IVideo } from 'models';

export type PageEditorContext = ReturnType<typeof usePageEditorContextLogic>;

const usePageEditorContextLogic = () => {
  const [editableVideo, setEditableVideo] = useState<IVideo>();

  return {
    editableVideo,
    setEditableVideo,
  };
};

export const PageEditorContext = createContext({} as PageEditorContext);

export const PageEditorContextProvider: FC<PropsWithChildren> = ({
  children,
}) => (
  <PageEditorContext.Provider value={usePageEditorContextLogic()}>
    {children}
  </PageEditorContext.Provider>
);

export const usePageEditorContext = () => useContext(PageEditorContext);
