export const AUTH_MODAL_SIGNUP_CLASS = 'auth-modal-signup';
export const PROFILE_NAME_INPUT_CLASS = 'profile-name-input';

// Admin
export const ADMIN_SIDEBAR_LOGO = 'admin-bar-logo';
export const ADMIN_SIDEBAR_LABEL = 'admin-bar-logo-label';
export const ADMIN_SIDEBAR = 'admin-bar';

// Tool Bar
export const TOOL_BAR = 'toolbar';

// Schedule Panel Legacy
export const SCHED_ADD_TO_CALENDAR_CLASS = 'schedule-add-to-calendar';
export const SCHED_DATE_WIDGET = 'schedule-date-time-widget';
export const SCHED_EVENT_DETAILS = 'schedule-event-details';
export const SCHED_HIDDEN_TITLE = 'schedule-hidden-title';
export const SCHED_EVENT_TITLE = 'schedule-event-title';

// Predictions
export const MULTIPLE_CHOICE = 'multiple-choice';
export const MULTI_CHOICE_PREDICTION_KIND_CLASS = 'type';
export const MULTI_CHOICE_ITEM_CLASS = 'item';
export const PREDICTION_QUESTION_TEXT = 'multi-choice-prediction-question-text';
export const PREDICTION_LOCK_ICON = 'multi-prediction-lock-icon';
export const PREDICITON_LOCK_BANNER = 'multi-prediction-lock-banner';
export const PREDICTION_HEADER = 'multi-prediction-header';
export const PREDICTION_OPTION_TEXT = 'multi-prediction-option-text';
export const PREDICTION_DOT = 'multi-prediction-dot';
export const MULTIPLE_CHOICE_PANEL_ANSWER_WITH_IMAGE_CLASS = 'sidebar-panel-multiple-choice-answer-with-image';
export const MULTIPLE_CHOICE_PANEL_ANSWER_NO_IMAGE_CLASS = 'sidebar-panel-multiple-choice-answer-no-image';
export const MULTI_CHOICE_QUESTION_TEXT = 'multi-choice-prediction-question-text';
export const MULTI_CHOICE_LOCK_ICON = 'multi-prediction-lock-icon';
export const MULTI_CHOICE_LOCK_BANNER = 'multi-prediction-lock-banner';
export const MULTI_CHOICE_HEADER = 'multi-prediction-header';
export const MULTI_CHOICE_OPTION_TEXT = 'multi-prediction-option-text';
export const MULTI_CHOICE_OPTION_PERCENT = 'multi-prediction-option-percent';
export const MULTI_CHOICE_DOT = 'multi-prediction-dot';

// Panel
export const PANEL_LOGIN_BUTTON = 'panel-login-button';

// Quest
export const QUEST = 'quest';
export const REWARD = 'reward';
export const AVAILABLE = 'available';
export const PROGRESS_BAR = 'progress-bar';
export const ACTION_CONTAINER = 'action-container';

// Regions
export const COUNTDOWN = 'countdown';
export const OVERLAY_CLASS = 'overlay';

// Sidebar
export const SIDEBAR_PANEL_ROOT = 'sidebar-panel-root';

// Mobile-header
export const LOGIN = 'login';
export const NAVIGATION = 'navigation';
export const CHANNEL_SELECT = 'channel-select';

// Achievement
export const USER_ACHIEVEMENT_POINTS = 'user-achievement-points';

// User Profile
export const USER_PROFILE_TICKETS = 'user-profile-tickets';
export const USER_PROFILE_SUBSCRIPTIONS = 'user-profile-subscriptions';
export const USER_PROFILE_PAYMENT_METHODS = 'user-profile-payment-methods';


// Video player (controls)
export const CONCURRENTS_ICON = 'concurrents';
export const LIVE_ICON = 'live';
export const FULLSCREEN_TOGGLE_ICON = 'fullscreen-toggle';
export const THEATER_MODE_TOGGLE_ICON = 'theater';
export const VIDEO_TOGGLE_ICON = 'toggle';
export const SHARE_ICON = 'share';
export const CC_ICON = 'cc';
export const PLAY_PAUSE = 'play-pause';
export const VIDEO_VOLUME = 'volume';
export const VIDEO_PLACEHOLDER = 'video-placeholder';

// Chanel Select
export const CHANNEL = 'channel';

// General resusable classes
export const LOGO = 'logo';
export const TITLE = 'title';
export const ACTIVE = 'active';
export const MESSAGE = 'message';
export const SUBMIT = 'submit';
export const REJECT = 'reject';
export const FORM_LABEL = 'form-label';
export const FORM_INPUT = 'form-input';
export const ITEM = 'item';
export const ENDDATE = 'end-date';
export const TIMER = 'timer';
export const HEADER = 'header';
export const LOGIN_BUTTON = 'login-button';
export const LOGOUT_BUTTON = 'logout-button';
export const PANELS = 'panel';
export const OPTION = 'option';
export const ICON = 'icon';
export const TEXT = 'text';
export const ACTION = 'action';
export const DESCRIPTION = 'description';
export const NAME = 'name';
export const QUESTION = 'question';
export const PERCENT = 'percent';
export const RADIO = 'radio';
export const RESPONSE_SELECTED = 'response-selected';
export const RESPONSE_UNSELECTED = 'response-unselected';
export const PROGRESS_COMPLETE = 'progress-complete';
export const PROGRESS_INCOMPLETE = 'progress-incomplete';
export const DATE_TIME = 'date-time';
export const SUBTITLE = 'subtitle';
export const BUY = 'buy';
export const ENTITLEMENT_CLASS = 'entitlement';

// Cards
export const CARD_DISMISS = 'card-dismiss';

//  moldals invite
export const MANAGE_DEVICES = 'manage-devices';
export const NEED_USERNAME = 'need-username';
export const ENTITLEMENT_BORDER = 'entitlementborder';
export const PANEL_HEADER_BACKGROUND = 'panelheaderbackground';
export const PANEL_CONTENT_BACKGROUND = 'panelcontentbackground';
export const QUANTITY = 'quantity';
export const ENTITLEMENT_HEADER = 'entitlementheader';
export const TEXT_BOLD = 'text-bold';
export const FEE_BREAKDOWN = 'feebreakdown';
export const SUBSCRIPTION_TYPE = 'subscriptiontype';

// Login flow
export const BUTTON = 'button';
export const DEEP_LINK = 'deep-link';
export const FORM_FIELD = 'form-field';
export const FORM_FIELD_ID_TEXT = 'form-field-id-text';
export const MODAL_BACKGROUND = 'modal-background';
export const EMAIL_ADDRESS = 'email-address';
