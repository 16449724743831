import useRealtimeDocument from 'hooks/use-realtime-document';
import { useMemo } from 'react';
import hash from 'json-stable-stringify';
import { IVideo }  from 'models';
import { youtubeVideoFallbackThumbnail } from 'services/video/utils';

export type RealtimeVideoResult = {
  loaded: true;
  video: IVideo | null;
} | {
  loaded: false;
  video: null;
};

export default function useVideo(
  id: string | null,
): RealtimeVideoResult {
  const [video, loaded] = useRealtimeDocument<IVideo>('video', id);
  const withFallbackYtThumbnail = youtubeVideoFallbackThumbnail(video);

  const output: RealtimeVideoResult = useMemo(
    () => (id && loaded) ?
      { loaded: true, video: withFallbackYtThumbnail } :
      { loaded: false, video: null },
    [id, hash(withFallbackYtThumbnail), loaded],
  );

  return output;
}
