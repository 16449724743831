import React from 'react';

type UseInfiniteScrollParams<T extends HTMLElement> = {
  offsetPx?: number;
  onReachBottom: () => void;
  refElement: React.RefObject<T>;
};

function useInfiniteScroll<T extends HTMLElement>({ refElement, onReachBottom, offsetPx = 0 }: UseInfiniteScrollParams<T>) {
  const [isFetching, setIsFetching] = React.useState(false);

  const handleScroll = React.useCallback((event: Event) => {
    const currentElement = event.target as HTMLDivElement;
    if (currentElement.scrollTop + currentElement.clientHeight + offsetPx >= currentElement.scrollHeight) {
      onReachBottom();
    }
  }, [onReachBottom, offsetPx]);

  React.useEffect(() => {
    const currentElement = refElement.current;
    if (!currentElement) return;
    currentElement.addEventListener('scroll', handleScroll);
    return () => currentElement.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return [isFetching, setIsFetching] as const;
}

export default useInfiniteScroll;
