import {  PLAYLIST_SERVICE_BASE_URL, VIDEO_SERVICE_BASE_URL } from 'config';
import useAjax from 'hooks/use-ajax';
import invariant from 'invariant';
import get from 'lodash/get';
import { useEffect, useMemo, useState } from 'react';
import hash from 'json-stable-stringify';

export interface IUseCollectionOptions {
  collection: keyof typeof CollectionUrls;
  limit: number;
  pageOffset: number;
  refreshKey: string;
  search: string;
  sortBy: string;
  sortDirection: string;
}

export interface IResponse {
  data: {
    results: [],
  } | null;
  error: Error | null;
  loaded: boolean;
}

const CollectionUrls = {
  playlists: PLAYLIST_SERVICE_BASE_URL,
  videos: VIDEO_SERVICE_BASE_URL,
};

function throwError(error: string): never {
  return invariant(false, error) as never;
}

const useCollection = <T = any>(options: IUseCollectionOptions): [T[], Error | null, boolean] => {
  const [pageResults, setResults] = useState<T[]>([]);
  const [ajaxError, setError] = useState<Error | null>(null);
  const [dataLoaded, setLoaded] = useState(false);
  const {
    collection = throwError('useCollection requires a collection'),
    limit = 50,
    pageOffset = 0,
    refreshKey,
    search,
    sortBy = 'modified',
    sortDirection = 'desc',
  } = options;

  const serviceUrl = useMemo<string>(() => {
    const url = get(CollectionUrls, collection);
    if (!url) {
      invariant(false, `Invalid collection: '${collection}'`);
    }
    return url;
  }, [collection, hash(CollectionUrls)]);

  const { data, loaded, error }: IResponse = useAjax({
    params: {
      limit: limit.toString(),
      search,
      offset: (limit * pageOffset).toString(),
      refreshKey,
      sortBy,
      sortDirection,
    },
    url: serviceUrl,
  });

  useEffect(() => {
    if (loaded && data) {
      setResults(data.results);
    }
    if (error) {
      setError(error);
    }
    setLoaded(loaded);
  }, [hash(data), loaded, hash(error)]);

  return [pageResults, ajaxError, dataLoaded];
};

export default useCollection;
