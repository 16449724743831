import invariant from 'invariant';

const PATH_SEGMENT_REGEX = /^[^/.]+$/;

export const joinKeySegments = (parts: string[]): string => {
  invariant(
    Array.isArray(parts),
    'joinKeySegments accepts an array',
  );
  parts.forEach((part) => {
    invariant(
      part && typeof part === 'string' && PATH_SEGMENT_REGEX.test(part),
      `Invalid Firebase key segments: ${JSON.stringify(parts, null, 2)}`,
    );
  });
  return parts.join('/');
};
