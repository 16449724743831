import React from 'react';

const useSwipeable = <T extends HTMLElement> ({
  onSwipedLeft,
  onSwipedRight,
}: {
  onSwipedLeft: () => void;
  onSwipedRight: () => void;
}) => {
  const touchStartXRef = React.useRef<number | null>(null);
  const touchEndXRef = React.useRef<number | null>(null);

  const handleTouchStart = (event: React.TouchEvent<T>) => {
    touchStartXRef.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event: React.TouchEvent<T>) => {
    touchEndXRef.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartXRef.current !== null && touchEndXRef.current !== null) {
      const deltaX = touchStartXRef.current - touchEndXRef.current;
      if (deltaX > 50) {
        onSwipedLeft();
      } else if (deltaX < -50) {
        onSwipedRight();
      }
    }
    // Reset values
    touchStartXRef.current = null;
    touchEndXRef.current = null;
  };

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  };
};

export default useSwipeable;
