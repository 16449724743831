import useSelector from './use-selector';
import {
  Feature,
  Panel,
  QuestActions,
} from 'services/feature-gate/constants';
import {
  isFeatureEnabled,
  isOverlayEnabled,
  isPanelEnabled,
} from 'services/feature-gate/selectors';

const selectors = {
  feature: isFeatureEnabled,
  overlay: isOverlayEnabled,
  panel: isPanelEnabled,
};

interface IParams {
  feature: string;
  type: 'feature' | 'overlay' | 'panel'
}

const useFeatureGateEnabled = ({
  feature,
  type,
}: IParams): boolean => {
  return useSelector(state => (
    selectors[type](state, feature)
  ));
};

export {
  Feature,
  Panel,
  QuestActions,
};

export default useFeatureGateEnabled;
