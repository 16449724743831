import { useCallback, useEffect, useState } from 'react';
import useRealtimeDocument from 'hooks/use-realtime-document';
import { useDispatch, useSelector } from 'react-redux';
import IStream from 'models/IStream';
import { getCurrentChannelStreamId } from 'services/app/selectors';
import { MetadataStatus, StreamSource } from 'services/livestream/constants';
import {
  setMaestroLivestreamPreview,
  goLiveWithMaestroLivestream,
  goOfflineWithMaestroLivestream,
} from 'services/admin/actions';
import {
  getLivestreamError,
  getStreamSource,
  isLivestreamPublished,
  getMetadataStatus,
  getStudioSessionReady,
  isPublishedWithStudioStream,
  isPublishedWithMaestroStream,
  isStreamBeingCreatedForCurrentChannel,
} from 'services/livestream/selectors';
import { useIsChannelLive } from './use-is-channel-live';
import { getObjectId } from 'services/app';

export default function useStreamModeState() {
  const dispatch = useDispatch();
  const [streamIsStarting, setStreamIsStarting] = useState(false);
  const [streamIsEnding, setStreamIsEnding] = useState(false);
  const livestreamError = useSelector(getLivestreamError);
  const livestreamIsPublished = useSelector(isLivestreamPublished);
  const source = useSelector(getStreamSource);
  const metadataStatus = useSelector(getMetadataStatus);
  const studioSessionReady = useSelector(getStudioSessionReady);
  const studioStreamPublished = useSelector(isPublishedWithStudioStream);
  const thirdPartyStreamPublished = useSelector(isPublishedWithMaestroStream);
  const [streamStatus, setStreamStatus] = useState<'online' | 'offline'>('offline');
  const isStreamCreationInProgress = useSelector(isStreamBeingCreatedForCurrentChannel);

  const channelId = useSelector(getObjectId);
  const streamId = useSelector(getCurrentChannelStreamId);
  const [doc] = useRealtimeDocument<IStream>('stream', streamId);

  const isEpgPublished = useIsChannelLive(channelId);

  const isWaitingForMetadata = metadataStatus === MetadataStatus.IDLE || metadataStatus === MetadataStatus.PENDING;
  const isGoLiveButtonDisabled = (() => {
    if (source === StreamSource.STUDIO)
      return studioStreamPublished ? false : (!studioSessionReady || isWaitingForMetadata);
    return thirdPartyStreamPublished ? false : (!doc?.activeSessionId || isWaitingForMetadata);
  })();
  const isGoOfflineButtonDisabled = studioStreamPublished ? streamIsEnding || !studioSessionReady : streamIsEnding;

  const goLive = () => dispatch(goLiveWithMaestroLivestream());
  const goOffline = () => dispatch(goOfflineWithMaestroLivestream());

  useEffect(() => {
    setStreamStatus(
      (livestreamIsPublished && isEpgPublished)
        ? 'online'
        : 'offline',
    );
  }, [livestreamIsPublished, isEpgPublished]);

  useEffect(() => {
    dispatch(setMaestroLivestreamPreview(true));
  }, []);

  // set loading state
  useEffect(() => {
    if (!livestreamIsPublished) {
      setStreamIsEnding(false);
      setStreamIsStarting(false);
    }
  }, [livestreamIsPublished]);

  useEffect(() => {
    if (isEpgPublished) {
      setStreamIsStarting(false);
    }
  }, [isEpgPublished]);

  // reset loading state on failure to go live
  useEffect(() => {
    if (livestreamError) {
      setStreamIsStarting(false);
      setStreamIsEnding(false);
      setStreamStatus('offline');
    }
  }, [livestreamError]);

  const startStream = useCallback(() => {
    if (streamIsStarting) {
      return null;
    }
    setStreamIsStarting(true);
    return goLive();
  }, [
    streamIsStarting,
    setStreamIsStarting,
    goLive,
  ]);

  const endStream = useCallback(() => {
    if (streamIsEnding) {
      return null;
    }
    setStreamIsEnding(true);
    return goOffline();
  }, [
    streamIsEnding,
    setStreamIsEnding,
    goOffline,
  ]);

  return {
    activeSessionId: doc?.activeSessionId || '',
    endStream,
    ingestUrl: doc?.ingestUrl || '',
    isEnding: streamIsEnding,
    isGoOfflineButtonDisabled,
    isGoLiveButtonDisabled,
    isPublished: livestreamIsPublished,
    isStarting: streamIsStarting,
    isStreamCreationInProgress,
    source,
    startStream,
    streamKey: doc?.streamKey || '',
    streamStatus,
  };
}
