import IQuest from 'models/IQuest';
import { DynamicTranslationType, useEndUserTranslation } from './use-translation';

const useQuestEndUserTranslationTexts = (quest: IQuest) => {
  const { endUserT } = useEndUserTranslation();

  const title = quest._id ? (endUserT([DynamicTranslationType.questTitle, { questId: quest._id }]) || quest.title) : quest.title;
  const description = quest._id ? (endUserT([DynamicTranslationType.questDescription, { questId: quest._id }]) || quest.description) : quest.description;
  const rewardText = endUserT([DynamicTranslationType.questReward], ['REWARD']);
  const rewardDescription = quest._id ? (endUserT([DynamicTranslationType.questRewardDescription, { questId: quest._id }]) || quest.rewardDescription) : quest.rewardDescription;

  return {
    title,
    description,
    rewardText,
    rewardDescription,
  };
};

export default useQuestEndUserTranslationTexts;
