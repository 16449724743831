import { useMemo } from 'react';
import { IProductData, IProduct as IEcommerceProduct, OPTION_TYPE } from 'components/Ecommerce/models';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, getSubtotal } from 'services/ecommerce/selectors';
import { IProduct } from 'services/shopify/models';
import { getProductsByVariantIds } from 'services/shopify/selectors';
import IState from 'services/state';
import { pushEcommerceView } from 'services/ecommerce';
import { removeCartOrShopifyItemDuplicates } from 'services/ecommerce/utils';
import { useAdminTranslation } from 'hooks/use-translation';

const useEcommerceCart = () => {
  const dispatch = useDispatch();
  const cart = useSelector(getCart);
  const subtotal = useSelector(getSubtotal);
  const productVariantIds = cart.cartItems.map(item => item.variantId);
  const shopifyProducts = useSelector((state: IState) => getProductsByVariantIds(state, productVariantIds));
  const { t } = useAdminTranslation();
  let preventShopifyCheckoutError: string = '';

  const rawProductsData: IProductData[] = cart.cartItems.flatMap((cartItem) => {
    const product = shopifyProducts.find(p =>
      p.variants.edges.some(variant => variant.node.id === cartItem.variantId),
    );
    if (!product) return [];

    const transformedProduct = transformShopifyProductIntoEcommerceProduct(product, cartItem.quantity);
    const selectedOptions = transformedProduct.variants.find((variant) => {
      return variant.id === cartItem.variantId;
    })?.selectedOptions;

    if (!selectedOptions) return [];

    return [{
      product: transformedProduct,
      preSelectedOptions: selectedOptions,
    }];
  });
  const productsData = removeCartOrShopifyItemDuplicates(rawProductsData) as IProductData[];

  const onCheckout = () => {
    dispatch(pushEcommerceView('checkout'));
  };

  const shouldPreventCheckout = useMemo(() => {
    return cart.cartItems.some((item) => {
      const product = shopifyProducts.find((p) => p.id === item.productId);
      if (!product) {
        preventShopifyCheckoutError = 'Could not find product from site\'s shopify store';
        return true;
      }

      const variants = product.variants?.edges.map((edge) => edge.node);
      if (!variants) {
        preventShopifyCheckoutError =  t('ECOMMERCE_NO_PRODUCT_VARIANTS_ERROR');
        return true;
      }

      const variant = variants.find((v) => v.id === item.variantId);
      if (!variant) {
        preventShopifyCheckoutError = t('ECOMMERCE_NO_VARIANT_IN_PRODUCT_ERROR');
        return true;
      }

      if (!variant.availableForSale) {
        preventShopifyCheckoutError = t('ECOMMERCE_REMOVE_SOLD_OUT_ITEMS_ERROR');
        return true;
      }

      if (item.quantity === 0) {
        preventShopifyCheckoutError = t('ECOMMERCE_ZERO_QUANTITY_ITEM_ERROR');
        return true;
      }
      return false;
    });
  }, [cart.cartItems, shopifyProducts]);

  return { productsData, onCheckout, subtotal, shouldPreventCheckout, preventShopifyCheckoutError };
};

export default useEcommerceCart;

export const transformShopifyProductIntoEcommerceProduct = (product: IProduct, quantity: number): IEcommerceProduct => {
  return {
    availableForSale: product.availableForSale,
    description: product.description,
    id: product.id,
    images: product.images.edges.map((edge) => edge.node),
    name: product.title,
    options: mapShopifyOptionsToEcommerceOptions(product.options),
    quantity,
    variants: mapShopifyVariantsToEcommerceVariants(product.variants),
  };
};

const mapShopifyOptionNameToEcommerceOptionType = (name: string): IEcommerceProduct['options'][0]['type'] => {
  if (name.toLowerCase().includes('color')) return OPTION_TYPE.color;
  if (name.toLowerCase().includes('size')) return OPTION_TYPE.size;
  return OPTION_TYPE.generic;
};

const mapShopifyOptionsToEcommerceOptions = (options: IProduct['options']): IEcommerceProduct['options'] => {
  return options.map((option) => ({
    id: option.id,
    name: option.name,
    type: mapShopifyOptionNameToEcommerceOptionType(option.name),
    values: option.values.map((value) => ({
      name: value,
      value,
    })),
  }));
};

const mapShopifyVariantsToEcommerceVariants = (variants: IProduct['variants']): IEcommerceProduct['variants'] => {
  return variants.edges.map(({ node: variant }) => ({
    availableForSale: variant.availableForSale,
    id: variant.id,
    images: variant.image ? [variant.image] : null,
    price: Number(variant.price.amount),
    requiresShipping: variant.requiresShipping,
    selectedOptions: variant.selectedOptions.map((option) => ({
      id: option.id,
      name: option.name,
      type: mapShopifyOptionNameToEcommerceOptionType(option.name),
      value: {
        name: option.value,
        value: option.value,
      },
    })),
    title: variant.title,
  }));
};
