import { useEffect, useMemo, useState } from 'react';
import { useCast, useCastPlayer } from 'react-cast-sender';

export default function useChromecast() {
  const castPlayer = useCastPlayer();
  const castState = useCast();

  const [currentTime, setCurrentTime] = useState(castPlayer.currentTime);
  const [isMuted, setIsMuted] = useState(castPlayer.isMuted);
  const [isPaused, setIsPaused] = useState(castPlayer.isPaused);
  const connected = useMemo(() => (
    castState.connected ||
    window.cast?.framework?.CastContext?.getInstance()?.getCastState() === 'CONNECTED'
  ), [castState.connected, window.cast?.framework?.CastContext?.getInstance()?.getCastState()]);

  const stopCasting = () => {
    window.cast?.framework?.CastContext?.getInstance?.().endCurrentSession?.(true);
  };

  useEffect(() => {
    const playerState = window.cast?.framework?.CastContext?.getInstance()?.getCurrentSession()?.getMediaSession()?.playerState;
    if (!playerState || playerState === 'IDLE') {
      return;
    }
    const player = new window.cast.framework.RemotePlayer();
    const controller = new window.cast.framework.RemotePlayerController(player);

    if (!controller) {
      return;
    }
    setIsPaused(playerState === 'PAUSED');

    function onCurrentTimeChange(data: cast.framework.RemotePlayerChangedEvent) {
      setCurrentTime(data.value);
    }
    controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      onCurrentTimeChange,
    );

    function onIsMutedChange(data: cast.framework.RemotePlayerChangedEvent) {
      setIsMuted(data.value);
    }
    controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
      onIsMutedChange,
    );

    function onIsPausedChange(data: cast.framework.RemotePlayerChangedEvent) {
      setIsPaused(data.value);
    }
    controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      onIsPausedChange,
    );

    return () => {
      controller.removeEventListener(
        window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
        onCurrentTimeChange,
      );
      controller.removeEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
        onIsMutedChange,
      );
      controller.removeEventListener(
        window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
        onIsPausedChange,
      );
    };
  }, [window.cast?.framework?.CastContext?.getInstance()?.getCurrentSession()?.getMediaSession()?.playerState]);

  const [available, setAvailable] = useState(false);

  useEffect(
    () => {
      // need interval to be able to watch non-reactive value of window.cast.framework.CastContext
      const watchCastContext = () => {
        const isAvailableNow = connected || (typeof window.cast?.framework?.CastContext?.getInstance?.()?.requestSession === 'function');

        if (isAvailableNow !== available) {
          setAvailable(isAvailableNow);
        }
      };

      watchCastContext();

      const interval = setInterval(
        watchCastContext,
        1000,
      );

      return () => {
        clearInterval(interval);
      };
    },
    [connected, available],
  );

  return {
    ...castState,
    available,
    connected,
    initialized: useMemo(() => (
      castState.initialized || !!window.cast?.framework
    ), [castState.initialized]),
    requestCasting: () => {
      window.cast?.framework?.CastContext?.getInstance?.()?.requestSession?.();
    },
    stopCasting,
    castPlayer: {
      ...castPlayer,
      currentTime,
      isMuted,
      isPaused,
      seek: (time: number) => {
        const player = new window.cast.framework.RemotePlayer();
        const playerController = new window.cast.framework.RemotePlayerController(player);
        if (player && playerController) {
          player.currentTime = time;
          playerController.seek();
        }
      },
      togglePlay: () => {
        const player = new window.cast.framework.RemotePlayer();
        const playerController = new window.cast.framework.RemotePlayerController(player);
        playerController?.playOrPause();
      },
    },
  };
}
