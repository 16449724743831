import React from 'react';
import { PaymentMethod, PaymentRequestOptions, Stripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { addPaymentMethod, getBillingInfo, getDefaultPaymentMethod, getStripePaymentMethods, getWallet, getWalletData, setDefaultPaymentMethod } from 'services/billing';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { getPrimaryToken, getUserEmail, isLoggedIn } from 'services/auth';
import { getSiteId } from 'services/app';
import { getStripeCardToken, getStripePaymentMethod, submitPaymentMethod } from 'services/billing/api';

const getInitialPaymentRequest = (): PaymentRequestOptions => {
  return {
    country: 'US',
    currency: 'usd',
    displayItems: [],
    total: {
      label: 'Amount',
      amount: 0,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };
};

export interface IHandleSubmitCreditCardFormParams {
  cardNumber: StripeCardNumberElement;
  makePrimaryPaymentMethod?: boolean;
  savePaymentMethod?: boolean;
  stripe: Stripe;
}

const usePaymentMethods = () => {
  // Payment request options is used by Apple Pay and Google Pay
  const [paymentRequestOptions, setPaymentRequestOptions] = React.useState<PaymentRequestOptions>(
    getInitialPaymentRequest(),
  );
  // Stripe payment methods to charge the customer
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod | null>(null);
  const [stripeTokenId, setStripeTokenId] = React.useState<string | null>(null);

  const dispatch = useDispatch();
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);
  const paymentMethodsAvailable = useSelector(getStripePaymentMethods);
  const billingInfo = useSelector(getBillingInfo);
  const accessToken = useSelector(getPrimaryToken);
  const siteId = useSelector(getSiteId);
  const wallet = useSelector(getWallet);
  const email = useSelector(getUserEmail) || '';
  const isLogged = useSelector(isLoggedIn);

  // Remove payment method if was removed
  React.useEffect(() => {
    if (!paymentMethod) {
      return;
    }

    const paymentMethodExists = paymentMethodsAvailable.some((pm) => pm.id === paymentMethod.id);
    if (!paymentMethodExists) {
      setPaymentMethod(null);
    }
  }, [paymentMethodsAvailable, paymentMethod]);

  const submitCreditCard = async (params: IHandleSubmitCreditCardFormParams): Promise<{ paymentMethod: PaymentMethod | null; stripeTokenId: string | null; }> => {
    const { cardNumber, stripe, makePrimaryPaymentMethod = false } = params;

    const { paymentMethod: stripePaymentMethod } = await getStripePaymentMethod(cardNumber, stripe, billingInfo);
    if (!stripePaymentMethod) {
      return {
        paymentMethod: null,
        stripeTokenId: null,
      };
    }

    const { token } = await getStripeCardToken(cardNumber, stripe, billingInfo);
    if (!token) {
      return {
        paymentMethod: null,
        stripeTokenId: null,
      };
    }

    if (!isLogged) {
      savePaymentMethodOnStore(stripePaymentMethod, makePrimaryPaymentMethod);
      return {
        paymentMethod: stripePaymentMethod,
        stripeTokenId: token.id,
      };
    }

    if (!wallet || !accessToken || !siteId) {
      return {
        paymentMethod: stripePaymentMethod,
        stripeTokenId: token.id,
      };
    }

    const nameParts = billingInfo.name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    const result = await submitPaymentMethod(accessToken, siteId, wallet._id, {
      markAsDefault: makePrimaryPaymentMethod,
      paymentMethod: stripePaymentMethod,
      shopifyBillingData: {
        address: billingInfo.address || '',
        city: billingInfo.city || '',
        country: billingInfo.countryCode,
        email,
        firstName,
        lastName,
        phone: '',
        provinceCode: billingInfo.stateCode || '',
        zip: billingInfo.postalCode,
      },
      token,
    });

    if (!result.success) {
      return {
        paymentMethod: stripePaymentMethod,
        stripeTokenId: token.id,
      };
    }

    dispatch(getWalletData());
    savePaymentMethodOnStore(stripePaymentMethod, makePrimaryPaymentMethod);

    return {
      paymentMethod: stripePaymentMethod,
      stripeTokenId: token.id,
    };
  };

  const savePaymentMethodOnStore = (pm: PaymentMethod, makePrimaryPaymentMethod: boolean) => {
    dispatch(addPaymentMethod(pm));
    if (makePrimaryPaymentMethod) {
      dispatch(setDefaultPaymentMethod(pm.id));
    }
  };

  return {
    paymentRequestOptions,
    setPaymentRequestOptions,
    setPaymentMethod,
    paymentMethod,
    defaultPaymentMethod,
    paymentMethodsAvailable,
    submitCreditCard,
    setStripeTokenId,
    stripeTokenId,
  };
};

export default usePaymentMethods;
