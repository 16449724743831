import { MouseEvent, MouseEventHandler, useState } from 'react';

export default function useConfirmation(onConfirm?: (e: MouseEvent) => void): [boolean, MouseEventHandler, MouseEventHandler, MouseEventHandler] {
  const [hasConfirmation, setHasConfirmation] = useState(false);

  const onAskConfirm: MouseEventHandler = (e) => {
    e.stopPropagation();
    setHasConfirmation(true);
  };

  const onConfirmClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setHasConfirmation(false);
    onConfirm?.(e);
  };

  const onCancelClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setHasConfirmation(false);
  };

  return [hasConfirmation, onAskConfirm, onConfirmClick, onCancelClick];
}
