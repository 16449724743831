import { useEffect, useRef, useState } from 'react';

// will only return an updated `value` every `delay` ms
// best used in conjunction with useEffect:
// watch the throttled value, execute whatever async/expensive task in the effect

const useThrottle = <T = any>(value: T, delay: number = 300): T => {
  const [throttledValue, setThrottledValue] = useState(value);
  const now = Date.now();
  const lastUpdated = useRef(now);

  useEffect(
    () => {
      const msSinceLastUpdate = now - lastUpdated.current;
      const handler = setTimeout(() => {
        if (msSinceLastUpdate >= delay) {
          setThrottledValue(value);
          lastUpdated.current = now;
        }
      }, delay - msSinceLastUpdate);

      const tailCall = setTimeout(() => {
        if (msSinceLastUpdate > 0 && msSinceLastUpdate < delay) {
          setThrottledValue(value);
          lastUpdated.current = now;
        }
      }, delay);

      return () => {
        clearTimeout(handler);

        if (msSinceLastUpdate < delay) {
          clearTimeout(tailCall);
        }
      };
    },
    [value, delay],
  );

  return throttledValue;
};

export default useThrottle;
