import { set } from 'lodash';
import React from 'react';
interface IUsePageBlockParams<T> {
  item: T;
  onChange: (item: T) => void;
}

const usePageBlock = <TStringPaths, TData extends object> ({ item, onChange }: IUsePageBlockParams<TData>) => {
  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [updateQueue, setUpdateQueue] = React.useState<(({ item, onChange }: IUsePageBlockParams<TData>) => void)[]>([]);

  const handleChange = React.useCallback((stringPath: TStringPaths) => (value: unknown) => {
    setUpdateQueue(
      (queue) => [
        ...queue,
        ({ item: target, onChange: onChangeTarget }) => {
          const newItem: TData = structuredClone(target);
          if (value !== null && typeof value === 'object' && 'label' in value && 'value' in value) {
            if (value.value === newItem[stringPath as string]) {
              return;
            }
            set(newItem, stringPath as string, value.value);
          } else {
            if (value === newItem[stringPath as string]) {
              return;
            }
            set(newItem, stringPath as string, value);
          }
          onChangeTarget(newItem);
        },
      ],
    );
  }, []);

  React.useEffect(
    () => {
      const [pendingUpdate, ...queue] = updateQueue;
      if (!pendingUpdate) {
        return;
      }

      pendingUpdate({ item, onChange });
      setUpdateQueue(queue);
    },
    [item, onChange, updateQueue],
  );

  const handleOpenSettings = React.useCallback(() => {
    setIsSettingsOpen(true);
  }, []);

  const handleCloseSettings = React.useCallback(() => {
    setIsSettingsOpen(false);
  }, []);

  return {
    handleChange,
    isSettingsOpen,
    handleOpenSettings,
    handleCloseSettings,
  };
};

export default usePageBlock;
