import useRealtimeDocument from 'hooks/use-realtime-document';
import IFortniteLeaderboard from 'models/IFortniteLeaderboard';

const useFortniteLeaderboard = ({ eventId, eventWindowId }: {
  eventId: string, eventWindowId: string,
}): [IFortniteLeaderboard | null, boolean] => {
  return useRealtimeDocument<IFortniteLeaderboard>('fortnite_leaderboard', `${eventId}-${eventWindowId}`);
};

export default useFortniteLeaderboard;
