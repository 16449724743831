import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useVideoUploadService } from 'services/video-upload/hooks';

type TContext = ReturnType<typeof useVideoUploadService>;

export const VideoLibraryContext = createContext<TContext>(
  {} as any,
);

export const VideoLibraryProvider: FC<PropsWithChildren> = ({ children }) => (
  <VideoLibraryContext.Provider
    value={useVideoUploadService()}
  >
    {children}
  </VideoLibraryContext.Provider>
);

export const useVideoLibraryContext = () =>
  useContext(VideoLibraryContext);
