import React, { useEffect, useMemo, RefObject } from 'react';
import get from 'lodash/get';

const scrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
};

export type Option<T> = Record<string, T | string>;

export default function useScrollToView<E extends HTMLElement, T, S = Option<T>>(
  selection: S | null | undefined = {} as S,
  selectionPath: string = 'value',
  options: S[] = [],
  isTriggered: boolean,
): [RefObject<E>[], number] {
  // regen list refs every time our list of options updates
  const ItemRefs = useMemo<RefObject<E>[]>(() => {
    return options.map(() => React.createRef());
  }, [options]);

  const activeIndex = useMemo(() => {
    return options.findIndex(item => get(item, selectionPath) === get(selection, selectionPath));
  }, [selection, options]);

  // scroll into view every time a trigger action occurs
  useEffect(() => {
    if (typeof ItemRefs[activeIndex]?.current?.scrollIntoView === 'function') {
      ItemRefs[activeIndex]?.current?.scrollIntoView(scrollOptions);
    }
  }, [isTriggered]);

  return [ItemRefs, activeIndex];
}
