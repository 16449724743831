import type { ReactNode } from 'react';
import { EnumToLiteral, makeEnum, MakeEnumType } from 'utils/makeEnum';
import { dynamicKeyMap } from './endUser';

// import type without importing the actual map
type TTranslationDic = typeof import('strings/en-MAESTRO').default;

export type TParamsForAdminKey<TK extends TranslationKey> = TTranslationDic[TK] extends { _tParams: any }
  ? TTranslationDic[TK]['_tParams']
  : never;

export type TTranslatorResult<TK extends TranslationKey> = TTranslationDic[TK] extends { _tStr: any }
  ? TTranslationDic[TK]['_tStr']
  : TTranslationDic[TK];

export type TranslationKey = keyof TTranslationDic;

export type TTranslator = <TK extends TranslationKey>(
  key: TK,
  params?: TParamsForAdminKey<TK>,
) => TTranslatorResult<TK>;

export type TInterpolator = <TK extends TranslationKey>(
  key: TK,
  params: TTranslationDic[TK] extends { _tParams: any }
    ? TTranslationDic[TK]['_tParams']
    : never,
) => ReactNode[];

export type DynamicTranslationType = MakeEnumType<typeof DynamicTranslationType>;

export const DynamicTranslationType = makeEnum(
  ...(Object.keys(dynamicKeyMap) as (keyof typeof dynamicKeyMap)[]),
);

export type DynamicTranslator = <T extends DynamicTranslationType>(
  type: T,
  ...params: (TTranslationParams<T> extends undefined ? [never?] : [TTranslationParams<T>])
) => string;

export type TTranslationParams<T extends DynamicTranslationType> = Parameters<typeof dynamicKeyMap[EnumToLiteral<T>]>[0];

export type TEndUserTranslator = <
  TDynamicTranslationType extends DynamicTranslationType,
  TAdminTranslationKey extends TranslationKey | undefined
>(
  endUserArgs: [
    TDynamicTranslationType,
    ...(
      TTranslationParams<TDynamicTranslationType> extends undefined
        ? []
        : [TTranslationParams<TDynamicTranslationType>]
      )
  ],
  adminFallback?: [
      TAdminTranslationKey,
      ...(
        TParamsForAdminKey<NonNullable<TAdminTranslationKey>> extends never
          ? [never?]
          : [TParamsForAdminKey<NonNullable<TAdminTranslationKey>>]
      )
  ],
) => TAdminTranslationKey extends undefined
  ? string
  : TTranslatorResult<NonNullable<TAdminTranslationKey>>;

export type TEndUserInterpolator = <
  TDynamicTranslationType extends DynamicTranslationType,
  TAdminTranslationKey extends TranslationKey | undefined
>(
  endUserArgs: [
    TDynamicTranslationType,
    ...(
      TTranslationParams<TDynamicTranslationType> extends undefined
        ? []
        : [
          Omit<TTranslationParams<TDynamicTranslationType>, 'variables'> & {
            variables: TTranslationParams<TDynamicTranslationType> extends { variables: any } ? {
              [K in keyof TTranslationParams<TDynamicTranslationType>['variables']]: ReactNode;
            } : never;
          }
        ]
      )
  ],
  adminFallback?: [
      TAdminTranslationKey,
      ...(
        TParamsForAdminKey<NonNullable<TAdminTranslationKey>> extends never
          ? [never?]
          : [{ [K in keyof TParamsForAdminKey<NonNullable<TAdminTranslationKey>>]: ReactNode }]
      )
  ],
) => ReactNode[] | null;
