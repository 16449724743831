import { useHistory } from 'react-router';
import { useCallback } from 'react';

export const useGoToVideo = () => {
  const history = useHistory();

  return useCallback(
    (videoId: string) => {
      const url = new URL(location.href);
      url.searchParams.set('v', videoId);
      history.push({
        search: url.search,
      });
    },
    [history],
  );
};
