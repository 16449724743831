import { ContactOption } from '@stripe/stripe-js';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAccountAddress, fetchAccountAddresses, getAccountAddresses, getAccountAddressesLoading } from 'services/account-address';
import { getPrimaryToken } from 'services/auth';

const useStripeContacts = () => {
  const addresses = useSelector(getAccountAddresses);
  const isAccountAddressesLoading = useSelector(getAccountAddressesLoading);
  const primaryToken = useSelector(getPrimaryToken);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (primaryToken) {
      dispatch(fetchAccountAddresses(primaryToken));
    }
  }, [primaryToken]);

  const contacts: ContactOption[] = React.useMemo(() => {
    const newContacts: ContactOption[] = addresses.map(
      (address: IAccountAddress) => {
        const { address: { firstName, lastName } } = address;
        const stripeAddress = {
          line1: address.address.address1,
          line2: address.address.address2,
          city: address.address.city,
          state: address.address.state,
          postal_code: address.address.postalCode,
          country: address.address.countryCode,
        };

        return {
          address: stripeAddress,
          label: stripeAddress,
          phone: address.address.phoneNumber || '',
          name: `${firstName} ${lastName}` || '',
          primary: address.primary,
        };
    });
    return newContacts;
  }, [addresses]);

  return {
    contacts,
    loading: primaryToken && isAccountAddressesLoading,
  };
};

export default useStripeContacts;
