import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { createObjectId } from 'utils';
import { CARD_TYPES, CardType, CARD_V2_CATALOG } from 'components/objects/PanelV2/constants';
import { setPreviewCardRenderer, setRegionRendererDraft, writeToCollectionLegacy, editCollectionItem } from 'services/admin/actions';
import { ID_SIDEBAR } from 'services/renderer';
import { IObject } from 'models';
import useLegacyCollection from 'hooks/use-legacy-collection';
import { ICatalogListProps } from 'components/objects/PanelV2/CatalogList';
import { getRenderer } from 'services/renderer/selectors';
import IState from 'services/state';
import { getCardId, getSiteId } from 'services/app/selectors';
import { isEqual } from 'lodash';
import useRealtimeDocuments from 'hooks/use-realtime-documents';
import { getCardIdDraft, getRefreshKey } from 'services/admin/selectors';
import createDefaultCard from './default-cards';
import { useAdminTranslation } from 'hooks/use-translation';
import { isUserAdmin } from 'services/auth';

type CardObject = IObject & { renderer?: { cardType: CardType } };

const CARD_ICON_MAP = {
  image: 'pictures',
  message: 'chat',
  tweet: 'twitter',
  poll: 'check',
  person: 'adminbarUserProfiles',
} as const;

const CARD_TYPE_KEY_MAP = {
  image: 'ADMIN_CARD_IMAGE_NAME',
  message: 'ADMIN_CARD_MESSAGE_NAME',
  tweet: 'ADMIN_CARD_TWEET_NAME',
  poll: 'ADMIN_CARD_POLL_NAME',
  person: 'ADMIN_CARD_PERSON_NAME',
} as const;

export default function useSidebarCardPicker() {
  const dispatch = useDispatch();
  const sidebarRenderer = useSelector((state: IState) => getRenderer<{}>(state, ID_SIDEBAR));
  const [catalogCards, setCatalogCards] = useState<ICatalogListProps>({
    items: Object.values(CARD_V2_CATALOG),
  });
  const refreshKey = useSelector(getRefreshKey);
  const isAdmin = useSelector(isUserAdmin);
  const [siteCards] = useLegacyCollection<CardObject>({ collection: 'cards', refreshKey, blockRequest: !isAdmin });
  const siteId = useSelector(getSiteId);
  const activeCardId = useSelector(getCardId);
  const activeCardIdDraft = useSelector(getCardIdDraft);
  const [editorCard, setEditorCard] = useState<CardObject | null>(null);
  const newDraftCardId = useRef<string | null>(null);
  const { t } = useAdminTranslation();

  const [rtCards] = useRealtimeDocuments<CardObject>(CARD_TYPES.map(cardType => ({
    collection: 'objects',
    id: siteCards.find((card) => card.renderer?.cardType === cardType)?._id!,
  })));
  const existingCards = useMemo(() => {
    return rtCards.reduce((cards, card) => {
      if (card?.renderer?.cardType) {
        cards[card.renderer.cardType] = card;
      }
      return cards;
    }, {} as Record<CardType, CardObject | undefined>);
  }, [rtCards]);

  useEffect(() => {
    const { current: cardId } = newDraftCardId;
    if (cardId) {
      dispatch(setRegionRendererDraft(ID_SIDEBAR, { ...sidebarRenderer, cardId }));
      newDraftCardId.current = null;
    }
  }, [refreshKey]);

  useEffect(() => {
    if (activeCardIdDraft === null) {
      setCatalogCards(cards => ({
        items: cards.items.map(card => ({ ...card, isActive: false })),
        testId: 'cardsList',
      }));
      return;
    }
    const activeCard = Object.values(existingCards).find(card => card?._id === (activeCardIdDraft || activeCardId))
      ?.renderer
      ?.cardType;
    setCatalogCards(cards => ({
      items: cards.items.map(card => ({ ...card, isActive: card.kindId === activeCard })),
      testId: 'cardsList',
    }));
  }, [existingCards, activeCardIdDraft, activeCardId]);

  const createNewCard = useCallback((cardType: CardType) => {
    newDraftCardId.current = createObjectId();
    for (const doc of createDefaultCard({ cardType, id: newDraftCardId.current, siteId, t })) {
      dispatch(writeToCollectionLegacy(doc));
    }
  }, []);

  const enableCard = useCallback((cardType: CardType) => {
    const card = existingCards[cardType];
    if (
      card?._id && (
        (card.renderer?.cardType !== 'person' && card.renderer?.cardType !== 'poll') ||
        'personId' in card.renderer || 'pollId' in card.renderer
      )
    ) {
      dispatch(setRegionRendererDraft(ID_SIDEBAR, { ...sidebarRenderer, cardId: card._id }));
    } else {
      createNewCard(cardType);
    }
  }, [existingCards, createNewCard, hash(sidebarRenderer)]);

  const disableCard = useCallback(() => {
    dispatch(setRegionRendererDraft(ID_SIDEBAR, { ...sidebarRenderer, cardId: null }));
  }, [hash(sidebarRenderer)]);

  const toggleCard = useCallback((cardType: string, checked: boolean) => {
    if (checked) {
      enableCard(cardType as CardType);
    } else {
      disableCard();
    }
  }, [enableCard, disableCard]);

  const openCardEditor = useCallback((cardType: string) => {
    const card = existingCards[cardType as CardType];
    if (card) {
      setEditorCard(card);
      dispatch(setPreviewCardRenderer(card.renderer));
    }
  }, [existingCards]);

  const closeCardEditor = useCallback(() => {
    dispatch(setPreviewCardRenderer(null));
    setEditorCard(null);
  }, []);

  const editCard = useCallback((renderer: CardObject['renderer']) => {
    setEditorCard(card => ({ ...card, renderer }) as IObject);
    dispatch(setPreviewCardRenderer(renderer));
  }, []);

  const saveCard = useCallback(() => {
    dispatch(editCollectionItem('card', editorCard!._id, editorCard!.renderer, undefined, true, false));
    closeCardEditor();
  }, [editorCard, closeCardEditor]);

  const cardHasChanges = Boolean(
    editorCard &&
    !isEqual(editorCard.renderer, existingCards[editorCard.renderer?.cardType!]?.renderer),
  );

  return {
    editorCard,
    editorCardTitleKey: editorCard && CARD_TYPE_KEY_MAP[editorCard.renderer?.cardType!],
    editorCardIcon: editorCard && CARD_ICON_MAP[editorCard.renderer?.cardType!],
    cardHasChanges,
    catalogCards,
    toggleCard,
    openCardEditor,
    closeCardEditor,
    editCard,
    saveCard,
  };
}
