import { useState, useRef, useCallback, useEffect } from 'react';
import useClipboard from './use-clipboard';
import type { TranslationKey } from './use-translation';

interface IUseClipboardWithMessageOptions {
  copiedMsg?: string;
  copyMsg?: string;
  useStringKeys?: boolean;
}

export default function useClipboardWithMessage(clipboard = '', {
  useStringKeys = false,
  ...options
} = {} as IUseClipboardWithMessageOptions) {
  const {
    copiedMsg = useStringKeys ? 'COPIED' : 'Copied',
    copyMsg = useStringKeys ? 'LABEL_COPY' : 'Copy',
  } = options;

  const { copyToClipboard } = useClipboard(clipboard);
  const [isCopied, setIsCopied] = useState(false);
  const timeoutIdRef = useRef(-1 as unknown as NodeJS.Timeout);

  const hideCopiedMessage = () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 3e3);
  };

  const copy = useCallback(() => {
    copyToClipboard();
    setIsCopied(true);
    hideCopiedMessage();
  }, [copyToClipboard]);

  useEffect(() => () => {
    clearTimeout(timeoutIdRef.current);
  }, []);

  return [copy, (isCopied ? copiedMsg : copyMsg) as TranslationKey] as const;
}
