import React from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

interface IUseDragDropParams {
  canReorder: (current: HTMLDivElement, monitor: DropTargetMonitor<DragObject, any>, dragIndex: number, hoverIndex: number) => boolean;
  id: string;
  index: number;
  onReorder: (dragIndex: number, hoverIndex: number) => void;
  /**
   * The kinds of dragItems this dropTarget accepts
   */
  type: string;
}

type DragObject = {
  index: number;
};

type DragResult = {
  handlerId: any;
};

const useDragDrop = ({ id, index, type, canReorder, onReorder }: IUseDragDropParams) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragObject, any, DragResult>({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (!canReorder(ref.current, monitor, dragIndex, hoverIndex)) {
        return;
      }

      // Time to actually perform the action
      onReorder(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    item: () => ({ id, index }),
    type,
  });
  drag(drop(ref));

  return { ref, isDragging, handlerId };
};

export const moveItem = (data: any[], dragIndex: number, hoverIndex: number) => {
  const newData = [...data];
  const prevCards = newData.splice(dragIndex, 1);
  newData.splice(hoverIndex, 0, prevCards[0]);
  return newData;
};

export default useDragDrop;
