import { get, isEqual } from 'lodash';
import IQuest from 'models/IQuest';
import IQuestAction from 'models/IQuestAction';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedIn } from 'services/auth';
import { addQuest, getProgress, removeQuest } from 'services/quest';
import { IQuestProgress } from 'services/quest/selectors';
import { msIn } from 'shared/datetime-utils';

const useQuestStatus = (quest: IQuest) => {
  const questsProgress = useSelector(getProgress);
  const [available, setAvailable] = React.useState(true);
  const [expired, setExpired] = React.useState(false);
  const isLogged = useSelector(isLoggedIn);
  const [previousActions, setPreviousActions] = React.useState<IQuestAction[]>(quest.actions || []);
  const [refreshKey, setRefreshKey] = React.useState(Date.now());
  const expirationTimeout = React.useRef<NodeJS.Timeout>(-1 as unknown as NodeJS.Timeout);
  const dispatch = useDispatch();

  const questProgress: IQuestProgress[string] = isLogged && quest._id ? (questsProgress[quest._id] || { actions: {} }) : { actions: {} };

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const actions = quest.actions || [];
    if (isEqual(actions, previousActions)) {
      if (timeout) {
        clearTimeout(timeout);
      }
      return;
    }

    // wait 5 seconds because the cache on the dataflow processor
    // for quests is 5 seconds. this only affects insta-win quests
    // that previously had an impossible action
    timeout = setTimeout(() => {
      setRefreshKey(Date.now());
      setPreviousActions(actions);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [quest.actions, previousActions]);

  const updateExpiration = () => {
    if (expirationTimeout.current) {
      clearTimeout(expirationTimeout.current);
    }

    if (quest.timeMinimum && quest.timeMaximum) {
      const now = Date.now();
      const start = new Date(quest.timeMinimum).getTime();
      const end = new Date(quest.timeMaximum).getTime();

      if (now <= end) {
        const duration = (now < start ? start : end) - now;
        expirationTimeout.current = setTimeout(() => {
          updateExpiration();
        }, Math.min(duration, msIn.day));
      }

      const newAvailable = now >= start;
      if (newAvailable !== available) {
        setAvailable(newAvailable);
      }

      const newExpired = now >= end;
      if (newExpired !== expired) {
        setExpired(newExpired);
      }
    }
  };

  React.useEffect(() => {
    dispatch(addQuest(quest._id));
    return () => {
      dispatch(removeQuest(quest._id));
    };
  }, [quest._id]);

  React.useEffect(() => {
    updateExpiration();
  }, [quest.timeMaximum, quest.timeMinimum]);

  const [userQuestActions, tasksCompleted] = React.useMemo(() => {
    const questActions = quest.actions || [];
    let newTasksCompleted = 0;

    const newUserQuestActions = questActions.map((questAction) => {
      const completed: boolean = get(questProgress, ['actions', questAction.arrayId, 'completed'], false);
      const progress: number = get(questProgress, ['actions', questAction.arrayId, 'progress'], 0);
      const failed: boolean = get(questProgress, ['actions', questAction.arrayId, 'failed'], false);

      if (completed) {
        newTasksCompleted++;
      }

      return {
        data: questAction,
        failed,
        progress,
      };
    });

    return [newUserQuestActions, newTasksCompleted];
  }, [quest.actions, questProgress]);

  return {
    available,
    expired,
    refreshKey,
    claimed: Boolean(questProgress.claimed),
    completed: Boolean(questProgress.completed) || (userQuestActions.length === 0 && available),
    failed: expired || Boolean(questProgress.failed),
    inProgress: tasksCompleted > 0,
    userQuestActions,
  };
};

export default useQuestStatus;
