import { useCallback } from 'react';

export default function useClipboard(textToCopy: string = '') {
  return {
    copyToClipboard: useCallback((text = textToCopy) => {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    }, []),
  };
}
