import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITrackAdminNavigationData, getCurrentSubMenuKey } from 'services/admin';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics/actions';

export type Callback = (data: ITrackAdminNavigationData) => void;

const useTrackAdminNavigation = (): Callback => {
  const dispatch = useDispatch();
  const currentSubMenuKey = useSelector(getCurrentSubMenuKey);

  return React.useCallback((data: ITrackAdminNavigationData) => {
    dispatch(
      trackEvent({
        event: AdminActionEvents.NAVBAR,
        properties: {
          element: data.element,
          menu: data.menu || currentSubMenuKey,
        },
      }),
    );
  }, [dispatch, currentSubMenuKey]);
};

export default useTrackAdminNavigation;
