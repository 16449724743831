import React, {
  createContext,
  useState,
  useCallback,
  FC,
  useContext,
  ReactNode,
} from 'react';
import {
  useFullscreenHandler,
  isFullscreenCurrently,
} from 'components/Video/video-controls/fullScreenUtils';

const useFullScreenDetectorState = () => {
  const [
    isDocumentInFullScreen,
    setIsDocumentInFullScreen,
  ] = useState(isFullscreenCurrently());

  const handleFullScreenChangeEvent = useCallback(
    () => setIsDocumentInFullScreen(
      isFullscreenCurrently(),
    ),
    [],
  );

  useFullscreenHandler(handleFullScreenChangeEvent);

  return {
    isDocumentInFullScreen,
  };
};

const FullScreenDetectorContext = createContext<ReturnType<typeof useFullScreenDetectorState>>({
  isDocumentInFullScreen: isFullscreenCurrently(),
});

export const FullScreenDetectorProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <FullScreenDetectorContext.Provider value={useFullScreenDetectorState()}>
    {children}
  </FullScreenDetectorContext.Provider>
);

export const useFullScreenDetector = () =>
  useContext(FullScreenDetectorContext);
