import { useMemo } from 'react';
import { IPoll } from 'services/polls/models';
import useRealtimeDocument from './use-realtime-document';

interface IUsePollResult {
  loaded: boolean;
  poll: IPoll | null;
}

export default function UsePoll(pollId: string | null): IUsePollResult {
  const [poll, loaded] = useRealtimeDocument<IPoll>('poll', pollId);

  // remove modified just incase
  const output: IUsePollResult = useMemo(
    () => ({ poll, loaded }),
    [poll, loaded],
  );

  return output;
}
