import hash from 'json-stable-stringify';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IObject, IEPGResponse } from 'models';
import { getEPGMap } from 'services/video';
import useRealtimeDocument from './use-realtime-document';

export type RealtimeChannelResult = {
  channel: IObject | null;
  epg: IEPGResponse | null;
  loaded: true;
} | {
  channel: null;
  epg: null;
  loaded: false;
};

export default function useChannel(id: string): RealtimeChannelResult {
  const [channel, loaded] = useRealtimeDocument<IObject>('objects', id);
  const siteEPGMap = useSelector(getEPGMap);
  const epg = siteEPGMap[id]; // empty epg

  const output: RealtimeChannelResult = useMemo(
    () => loaded ?
      { loaded: true, channel, epg } :
      { loaded: false, channel: null, epg: null },
    [hash(channel), hash(epg), loaded],
  );

  return output;
}
