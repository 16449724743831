import { useEndUserTranslation } from './endUser';
import { DynamicTranslationType, TTranslationParams } from './types';
import { translateRichTextHtml } from '.';

export const useEndUserInlineTextEditorTranslation = (isEditing: boolean) => {
  const { endUserT } = useEndUserTranslation();

  /**
   * Replace the text in the raw data with the localized version. Used in text editors. Only replace the text if is not editing.
   * @param type - to be used on translation.
   * @param rawData - Raw data to be translated.
   * @param args - Arguments to be used on translation (check dynamicKeyMap in client/src/hooks/use-translation/endUser.tsx)
   */
  const inlineTextEditorEndUserT = <T extends DynamicTranslationType>(
    type: T,
    rawData: string,
    ...args: (
      TTranslationParams<T> extends undefined
        ? []
        : [TTranslationParams<T>]
    )
  ) => {
    // We show original data when admin is editing
    if (isEditing) {
      return rawData;
    }

    const localizedStr = endUserT([type, ...args]);

    if (!localizedStr) {
      return rawData;
    }

    return translateRichTextHtml(rawData, localizedStr);
  };

  return { inlineTextEditorEndUserT };
};
