import { useState, useRef, useCallback } from 'react';

/**
 * This is not reactive to window resizing. The perfect use case for this
 * is dynamically calculating the width of a table column. ie: you render
 * a component once, calculate its size, then forget about it.
 */
export const useCalculateElementWidth = () => {
  const [width, setWidth] = useState<number>();
  const columnContainerRef = useRef<HTMLElement>();

  const captureElementRef = useCallback(
    (element: HTMLElement | null) => {
      if (columnContainerRef.current || !element)
        return;

      columnContainerRef.current = element;
      setWidth(
        element.getBoundingClientRect().width,
      );
    },
    [],
  );

  return [
    width,
    captureElementRef,
  ] as const;
};
